import React from 'react';
import _get from 'lodash/get';

import IconZero from 'cccisd-icons/seven-segment-0';
import IconOne from 'cccisd-icons/seven-segment-1';
import IconTwo from 'cccisd-icons/seven-segment-2';
import IconThree from 'cccisd-icons/seven-segment-3';
import IconFour from 'cccisd-icons/seven-segment-4';
import IconFive from 'cccisd-icons/seven-segment-5';
import IconSix from 'cccisd-icons/seven-segment-6';
import IconSeven from 'cccisd-icons/seven-segment-7';
import IconEight from 'cccisd-icons/seven-segment-8';
import IconNine from 'cccisd-icons/seven-segment-9';

import style from './style.css';

export default settings => {
    return ({ row }) => {
        const completed = _get(row, `childRoles.metricspawn.${settings.id}.completed`);

        const renderSessionNumber = () => {
            switch (settings.session) {
                case '1':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconOne />
                        </div>
                    );
                case '2':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconTwo />
                        </div>
                    );
                case '3':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconThree />
                        </div>
                    );
                case '4':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconFour />
                        </div>
                    );
                case '5':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconFive />
                        </div>
                    );
                case '6':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconSix />
                        </div>
                    );
                case '7':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconSeven />
                        </div>
                    );
                case '8':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconEight />
                        </div>
                    );
                case '9':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconNine />
                        </div>
                    );
                case '10':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconOne />
                            <IconZero />
                        </div>
                    );
                case '11':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconOne />
                            <IconOne />
                        </div>
                    );
                case '12':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconOne />
                            <IconTwo />
                        </div>
                    );
                case '13':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconOne />
                            <IconThree />
                        </div>
                    );
                case '14':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconOne />
                            <IconFour />
                        </div>
                    );
                case '15':
                    return (
                        <div className={completed && completed ? style.complete : style.incomplete}>
                            <IconOne />
                            <IconFive />
                        </div>
                    );
                default:
                    return null;
            }
        };

        return (
            <div style={{ fontSize: '24px', whiteSpace: 'nowrap' }}>{renderSessionNumber()}</div>
        );
    };
};
