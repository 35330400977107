import React from 'react';
import PropTypes from 'prop-types';
import { Html } from 'cccisd-wysiwyg';
import { Report, Page } from 'cccisd-laravel-report';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';

import widgets from '../widgets.js';
import filterFields from '../filterFields.js';
import ReportHeader from '../../../components/ReportHeader';
import style from './style.css';

const Fortress = window.cccisd && window.cccisd.fortress;
const userRole = Fortress.user.acting.role.handle;

const Component = props => {
    const {
        attendanceCallout,
        reachSummaryTable,
        footnotes,
        referralChart,
        logComments,
        fidelityCallout,
    } = props.widgets;

    const {
        body: { heading },
    } = props.settings;

    return (
        <Report>
            <div className={style.wrapper}>
                <Page>
                    <ReportHeader
                        settings={props.settings}
                        filters={props.filters}
                        isPreview={props.isPreview}
                        data={props.data}
                    />
                    {attendanceCallout}
                    {reachSummaryTable}
                </Page>
                <Page>{referralChart}</Page>
                <Page>
                    <div className={style.reportBodyText}>
                        <Html content={heading} />
                    </div>
                    {fidelityCallout}
                    {logComments}
                    {footnotes}
                </Page>
            </div>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

export default reportTemplatePlayer({
    widgets,
    userType: userRole,
    reportHandle: 'siteCoorSession',
    getFilterFields: props =>
        filterFields.map(field =>
            field.name === 'groupIds'
                ? {
                      ...field,
                  }
                : field
        ),
})(Component);
