import React, { useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { differenceInYears } from 'date-fns';
import axios from 'cccisd-axios';

import Table from 'cccisd-graphql-table';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import CccisdToggle from 'cccisd-toggle';
import notification from 'cccisd-notification';

import participantsQuery from './participants.graphql';
import providerParticipantsQuery from './providerParticipants.graphql';
import ParticipantForm from '../ParticipantForm';
import style from '../style.css';

import IconPlus from 'cccisd-icons/plus-circle2';
import IconPencil from 'cccisd-icons/pencil';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const ParticipantTable = ({ familyGroupId, familyName, isProvider, providerPawnId }) => {
    const tableRef = useRef();

    const getInitialValues = row => ({
        role: 'sfp_participant',
        pawnHash: row['pawn.pawnHash'],
        firstName: row['fields.firstName'],
        lastName: row['fields.lastName'],
        group: familyGroupId,
        DOB: row['fields.DOB'],
        activeInFamily: row['fields.activeInFamily'],
        race: row['fields.race'],
        ethnicity: row['fields.ethnicity'],
        gender: row['fields.gender'],
        genderOther: row['fields.genderOther'],
    });

    const onAddParticipant = async values => {
        const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
            role: 'sfp_participant',
            pawnHash: values.pawnHash,
            activeInFamily: true,
            firstName: values.firstName,
            lastName: values.lastName,
            group: familyGroupId,
            DOB: values.DOB,
            race: values.race,
            ethnicity: values.ethnicity,
            gender: values.gender,
            genderOther: values.genderOther,
        });

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }

        notification('Youth added.');
    };

    const onEditParticipant = async (pawnId, values) => {
        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', {
                pawn: pawnId,
            }),
            {
                role: 'sfp_participant',
                pawnHash: values.pawnHash,
                firstName: values.firstName,
                lastName: values.lastName,
                group: familyGroupId,
                DOB: values.DOB,
                race: values.race,
                ethnicity: values.ethnicity,
                gender: values.gender,
                genderOther: values.genderOther,
            }
        );

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        tableRef.current.loadData();
        notification('Youth edited.');
    };

    const updateStatus = async row => {
        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', {
                pawn: row['pawn.pawnId'],
            }),
            {
                activeInFamily: !row['fields.activeInFamily'],
                pawnHash: row['pawn.pawnHash'],
            }
        );

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        tableRef.current.loadData();
        notification('Youth edited.');
    };

    const columns = [
        {
            name: 'fields.activeInFamily',
            label: 'Participating',
            hideInCsv: true,
            sort: true,
            setStyle: () => {
                return { width: '13%' };
            },
            render: ({ row }) => {
                return (
                    <div className={style.actionButton}>
                        <Tooltip title="By turning ‘on’ the Participating toggle button, the associated Caregiver or Youth is considered an active participant in the SFP program and will be reflected in the Session Data tab, the Session Planning Dashboards, and in reports that reflect participation in SFP programs">
                            <CccisdToggle
                                value={row['fields.activeInFamily']}
                                onChange={() => updateStatus(row)}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            name: 'edit',
            label: 'Edit',
            hideInCsv: true,
            setStyle: () => {
                return { width: '4%' };
            },
            render: ({ row }) => {
                return (
                    <div className={style.actionButton}>
                        <Tooltip title="Edit">
                            <Modal
                                trigger={
                                    <button type="button" className="btn-xs btn-success">
                                        <IconPencil />
                                    </button>
                                }
                                title={`Edit ${row['fields.firstName']} ${row['fields.lastName']}`}
                                headerStyle={{
                                    backgroundColor: '#00ADDC',
                                    color: '#fff',
                                }}
                            >
                                <ParticipantForm
                                    initialValues={getInitialValues(row)}
                                    onSubmit={onEditParticipant.bind(this, row['pawn.pawnId'])}
                                />
                            </Modal>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            name: 'fields.activeInFamily',
            label: 'Participating in Family',
            hideInTable: true,
        },
        { name: 'fields.firstName', label: 'First Name', filter: true, sort: true },
        { name: 'fields.lastName', label: 'Last Name', filter: true, sort: true },
        {
            name: 'fields.DOB',
            label: 'DOB',
            hideInTable: true,
        },
        {
            name: 'age',
            label: 'Age',
            className: 'text-center',
            setStyle: () => {
                return { width: '15%' };
            },
            render: ({ row }) => {
                const age = differenceInYears(new Date(), row['fields.DOB']);
                return age;
            },
        },
        {
            name: 'fields.race',
            label: 'Race',
            hideInTable: true,
        },
        {
            name: 'fields.ethnicity',
            label: 'Ethnicity',
            hideInTable: true,
        },
        {
            name: 'fields.gender',
            label: 'Gender',
            hideInTable: true,
        },
        {
            name: 'fields.genderOther',
            label: 'Other Gender Specified',
            hideInTable: true,
        },
    ];

    return (
        <>
            <div style={{ marginTop: '1em', marginBottom: '1em' }}>
                <Tooltip title={`Add Youth to ${familyName} Family`}>
                    <Modal
                        trigger={
                            <button
                                type="button"
                                className={classnames(style.button, `btn btn-primary`)}
                            >
                                <IconPlus spaceRight />
                                Add Youth
                            </button>
                        }
                        title="Add Youth"
                        headerStyle={{
                            backgroundColor: '#00ADDC',
                            color: '#fff',
                        }}
                        formAutoFocus
                        afterClose={() => tableRef.current.loadData()}
                    >
                        <ParticipantForm onSubmit={onAddParticipant} />
                    </Modal>
                </Tooltip>
            </div>
            <Table
                columns={columns}
                query={isProvider ? providerParticipantsQuery : participantsQuery}
                rowKey="pawn.pawnId"
                graphqlVariables={{
                    groupId: familyGroupId,
                    pawnId: providerPawnId,
                }}
                orderBy="fields.activeInFamily"
                isAscentOrder={false}
                ref={tableRef}
                csvFilename={`Youths in ${familyName} Family ID-${familyGroupId}.csv`}
                noRecordsMessage="No Youths are rostered to this family yet."
            />
        </>
    );
};

ParticipantTable.propTypes = {
    familyGroupId: PropTypes.number,
    familyName: PropTypes.string,
    isProvider: PropTypes.bool,
    providerPawnId: PropTypes.number,
};

export default ParticipantTable;
