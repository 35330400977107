import React from 'react';
import Tooltip from 'cccisd-tooltip';

import IconWarning from 'cccisd-icons/warning2';
import IconLock from 'cccisd-icons/lock2';
import IconPower from 'cccisd-icons/power-cord';
import IconUser from 'cccisd-icons/user2';
import IconCancel from 'cccisd-icons/user-cancel2';
import IconUserLock from 'cccisd-icons/user-lock2';
import IconUserCheck from 'cccisd-icons/user-check2';
import IconFolder from 'cccisd-icons/folder-check';

const fields = window.cccisd && window.cccisd.appDefs.pawn.fields;
const enumFields = fields.filter(field => field.data_type === 'enum');
const enumValues = {};
for (const field of enumFields) {
    enumValues[field.handle] = field.values.map(item => ({
        label: item.name,
        value: item.value,
    }));
}

export const childrensTrust = "Children's Trust";

export const counties = enumValues.county;

export const notTrainedBanner = (
    <div className="alert alert-danger" style={{ margin: '22px' }}>
        <IconWarning spaceRight /> System records indicate that you have not completed the training
        needed to deliver this level or conduct data entry. Please contact your Backbone
        Organization Administrator to have your records updated.
    </div>
);

export const notOffered = (
    <Tooltip
        title="This Service Delivery Organization does not currently support
                    delivery at this level."
    >
        <IconLock />
    </Tooltip>
);

export const notOfferedBanner = (
    <div className="alert alert-info" style={{ margin: '22px' }}>
        <IconLock spaceRight /> This Service Delivery Organization does not currently support
        delivery at this level.
    </div>
);

export const addSitesBanner = (
    <div className="alert alert-warning" style={{ margin: '22px' }}>
        <IconPower spaceRight /> Please use the Manage tab to add Service Delivery Organizations to
        begin Data Entry.
    </div>
);

export const ctidCheck = {
    message: "This parent's CTID matches another parent's CTID. Please check with your supervisor.",
    inModal: true,
    showModalCloseButton: true,
    modalCloseButton: (
        <button type="button" className="btn btn-primary">
            Got it!
        </button>
    ),
    modalProps: {
        title: 'Matching CTID data',
        headerStyle: { backgroundColor: '#ff0000', color: '#fff' },
    },
};

export const renderFidComplete = (row, deploymentHandle) => {
    let fids = [];

    fids.push(
        row.fidelitySurveys.filter(
            survey =>
                survey.deployment.deploymentHandle === deploymentHandle && survey.completed === true
        )
    );

    return fids[0].length > 0;
};

export const servicesCompleteFlag = serviceDelivered => (
    <Tooltip
        title={`The completion of ${serviceDelivered} required content sessions counts as 1 service delivered.`}
    >
        <span style={{ color: '#5eb85c' }}>
            <IconFolder spaceRight />
            Required Content
        </span>
    </Tooltip>
);

export const styleParentStatus = status => {
    switch (status) {
        case 'Active':
            return (
                <span style={{ color: '#00addc' }}>
                    <IconUser spaceRight />
                    Active
                </span>
            );
        case 'Inactive':
            return (
                <span style={{ color: '#00addc' }}>
                    <IconUserLock spaceRight />
                    Inactive
                </span>
            );
        case 'Early Exit':
            return (
                <span style={{ color: '#00addc' }}>
                    <IconCancel spaceRight />
                    Early Exit
                </span>
            );
        case 'Complete':
            return (
                <span style={{ color: '#00addc' }}>
                    <IconUserCheck spaceRight />
                    Complete
                </span>
            );

        default:
            return <div />;
    }
};
