import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Table from 'cccisd-table';
import Loader from 'cccisd-loader';

import summaryTableQuery from './reachSummary.graphql';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const grpL2assignmentId = Appdefs.app.tripleP.grpL2assignmentId;
const grpL3assignmentId = Appdefs.app.tripleP.grpL3assignmentId;

const reachSummaryTable = props => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const { title, description, show, colors } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? groupIds.map(Number) : previewData.groupIds;
    const assignmentId = level === 'Level 2 Seminar' ? grpL2assignmentId : grpL3assignmentId;
    const sessionDate =
        level === 'Level 2 Seminar' ? 'devTags.L2SeminarDate' : 'devTags.L3DiscussionDate';

    useEffect(() => {
        (async () => {
            setLoading(true);
            getLevelData(selectedIds, assignmentId, reportingFrom, reportingTo, sessionDate).then(
                async response => {
                    const results = response.data.flows.completedFids;
                    setData(results);
                }
            );
            setLoading(false);
        })();
    }, [selectedIds, assignmentId, reportingFrom, reportingTo]);

    const getLevelData = ids => {
        return apollo.query({
            query: summaryTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: ids,
                assignmentId,
                reportingFrom,
                reportingTo,
                sessionDate,
            },
        });
    };
    const columns = [
        { name: 'topic', label: 'Session Topic' },
        {
            name: 'sessions',
            label: 'Number of Sessions/Times Provided',
            class: 'text-center',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
        },
        {
            name: 'parents',
            label: 'Number of Services Delivered (total)',
            class: 'text-center',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
        },
        {
            name: 'children',
            label: 'Total Children Impacted (total)',
            class: 'text-center',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
        },
        {
            name: 'children0to5',
            label: 'Children Aged 0-5 Impacted',
            class: 'text-center',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
        },
    ];

    const rowsRendered =
        data &&
        data.frequency.map((topic, index) => {
            return {
                id: index + 1,
                topic: topic.value,
                sessions: topic.valueCount,
                parents: topic.totalParents === null ? 'No Data' : topic.totalParents,
                children: topic.totalChildren === null ? 'No Data' : topic.totalChildren,
                children0to5: topic.children0to5 === null ? 'No Data' : topic.children0to5,
            };
        });

    const initialData = [
        {
            topic: 'Raising Confident, Competent Children',
            id: 5,
            children0to5: 142,
            parents: 146,
            children: 145,
        },
    ];

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <strong
                        style={
                            colors
                                ? {
                                      color: colors.primary,
                                      fontSize: '1.5em',
                                      marginBottom: '1em',
                                  }
                                : { fontSize: '1.5em', marginBottom: '1em' }
                        }
                    >
                        {title}
                    </strong>
                    <Loader loading={loading}>
                        <Table
                            columns={columns}
                            data={data ? rowsRendered : initialData}
                            noRecordsMessage="No data collected at selected filters."
                            showPerPageOptions={false}
                            hideShowingRowsSummary
                        />
                    </Loader>
                    <div className={style.description}>{description}</div>
                </div>
            )}
        </>
    );
};

reachSummaryTable.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

reachSummaryTable.defaultProps = {
    filters: {},
};

export default reachSummaryTable;
