import React from 'react';
import PropTypes from 'prop-types';

import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import Modal from 'cccisd-modal';

import ProviderForm from '../../../../components/triplep/Manage/ProviderForm';

import IconPlus from 'cccisd-icons/plus-circle2';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default class AddProviderForm extends React.Component {
    static propTypes = {
        loadData: PropTypes.func,
        settings: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    onSubmit = async values => {
        const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
            role: 'triplep_provider',
            // if user already exists, use that username
            username: values.existingUser ? null : values.username,
            user: values.existingUser ? values.username : null,
            availableSameGroup: values.availableSameGroup,
            first_name: values.firstName,
            middleName: values.middleName,
            last_name: values.lastName,
            email: values.username,
            phone: values.phone,
            gender: values.gender,
            pawnHash: values.pawnHash,
            triplepRace: values.triplepRace,
            raceOther: values.raceOther,
            group: values.group,
            sector: values.sector,
            sectorOther: values.sectorOther,
            professionalTitle: values.professionalTitle,
            accredIndL2: values.accredIndL2,
            accredIndL3: values.accredIndL3,
            accredIndL4: values.accredIndL4,
            accredGrpL2: values.accredGrpL2,
            accredGrpL3: values.accredGrpL3,
            accredGrpL4: values.accredGrpL4,
            accredDateIndL2: values.accredDateIndL2,
            accredDateIndL3: values.accredDateIndL3,
            accredDateIndL4: values.accredDateIndL4,
            accredDateGrpL2: values.accredDateGrpL2,
            accredDateGrpL3: values.accredDateGrpL3,
            accredDateGrpL4: values.accredDateGrpL4,
            trainIndL2: values.trainIndL2,
            trainIndL3: values.trainIndL3,
            trainIndL4: values.trainIndL4,
            trainGrpL2: values.trainGrpL2,
            trainGrpL3: values.trainGrpL3,
            trainGrpL4: values.trainGrpL4,
            trainDateIndL2: values.trainDateIndL2,
            trainDateIndL3: values.trainDateIndL3,
            trainDateIndL4: values.trainDateIndL4,
            trainDateGrpL2: values.trainDateGrpL2,
            trainDateGrpL3: values.trainDateGrpL3,
            trainDateGrpL4: values.trainDateGrpL4,
        });

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        await this.props.loadData();
        notification('Practitioner added.');
    };

    render() {
        const { settings } = this.props;

        return (
            <Modal
                ref={this.modal}
                trigger={
                    <button type="button" className="btn btn-primary">
                        <span>
                            <IconPlus spaceRight />
                            Add Practitioner
                        </span>
                    </button>
                }
                title="Add New Practitioner"
            >
                <ProviderForm settings={settings} operation="create" onSubmit={this.onSubmit} />
            </Modal>
        );
    }
}
