import React from 'react';
import { BulkUploader } from 'cccisd-laravel-nexus';
import IconUsers from 'cccisd-icons/users';
import IconUsers4 from 'cccisd-icons/users4';
import IconUsers5 from 'cccisd-icons/users5';
import IconOne from 'cccisd-icons/seven-segment-1';
import IconTwo from 'cccisd-icons/seven-segment-2';
import IconThree from 'cccisd-icons/seven-segment-3';
import IconFour from 'cccisd-icons/seven-segment-4';
import IconSix from 'cccisd-icons/seven-segment-6';
import IconEight from 'cccisd-icons/seven-segment-8';
import IconOffice from 'cccisd-icons/office';
import IconGoTo from 'cccisd-icons/circle-right4';
import IconSmile from 'cccisd-icons/smile';
import deploymentQuery from './deploymentQuery.graphql';
import _get from 'lodash/get';
import { client as apollo } from 'cccisd-apollo';
import classnames from 'classnames';
import style from './style.css';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default class ExportData extends React.Component {
    static propTypes = {};

    state = {};

    componentDidMount() {
        this.getDeployments();
    }

    getDeployments = async () => {
        const caregiver1 = 'Time1_ProgEnroll_Caregiver';
        const caregiver2 = 'Time2_3Month_Caregiver';
        const caregiver3 = 'Time3_6Month_Caregiver';
        const caregiver4 = 'Time4_12Months_Caregiver';
        const caregiver5 = 'Time5_24Months_Caregiver';
        const caregiver6 = 'Time6_36Months_Caregiver';
        const caregiver7 = 'Time7_48Months_Caregiver';

        const child1 = 'Time1_ProgEnroll_Child';
        const child2 = 'Time2_3Month_Child';
        const child3 = 'Time3_6Month_Child';
        const child4 = 'Time4_12Months_Child';
        const child5 = 'Time5_18Months_Child';
        const child6 = 'Time6_24Months_Child';
        const child7 = 'Time7_36Months_Child';
        const child8 = 'Time8_48Months_Child';

        const response = await apollo.query({
            query: deploymentQuery,
            fetchPolicy: 'network-only',
            variables: {
                caregiver1,
                caregiver2,
                caregiver3,
                caregiver4,
                caregiver5,
                caregiver6,
                caregiver7,
                child1,
                child2,
                child3,
                child4,
                child5,
                child6,
                child7,
                child8,
            },
        });

        await this.setState({
            caregiver1: _get(response, 'data.flows.caregiver1.deploymentId', {}),
            caregiver2: _get(response, 'data.flows.caregiver2.deploymentId', {}),
            caregiver3: _get(response, 'data.flows.caregiver3.deploymentId', {}),
            caregiver4: _get(response, 'data.flows.caregiver4.deploymentId', {}),
            caregiver5: _get(response, 'data.flows.caregiver5.deploymentId', {}),
            caregiver6: _get(response, 'data.flows.caregiver6.deploymentId', {}),
            caregiver7: _get(response, 'data.flows.caregiver7.deploymentId', {}),
            child1: _get(response, 'data.flows.child1.deploymentId', {}),
            child2: _get(response, 'data.flows.child2.deploymentId', {}),
            child3: _get(response, 'data.flows.child3.deploymentId', {}),
            child4: _get(response, 'data.flows.child4.deploymentId', {}),
            child5: _get(response, 'data.flows.child5.deploymentId', {}),
            child6: _get(response, 'data.flows.child6.deploymentId', {}),
            child7: _get(response, 'data.flows.child7.deploymentId', {}),
            child8: _get(response, 'data.flows.child8.deploymentId', {}),
        });
    };

    render() {
        const {
            caregiver1,
            caregiver2,
            caregiver3,
            caregiver4,
            caregiver5,
            caregiver6,
            caregiver7,
            child1,
            child2,
            child3,
            child4,
            child5,
            child6,
            child7,
            child8,
        } = this.state;

        return (
            <div>
                <div className={style.header}>
                    <h1>Import Historical Data for MIECHV</h1>
                    <div className={style.colorBar} />
                    <h3>
                        <strong style={{ color: 'green' }}>
                            Welcome Researchers!! <IconSmile spaceRight />{' '}
                        </strong>
                    </h3>
                    <h4>
                        Here are the steps to import historical data for MIECHV caregivers and their
                        child(ren).
                    </h4>

                    <h3>
                        <u>Rostering</u>
                    </h3>
                    <div className={style.step}>
                        <h4 className={style.row}> Step 1: Bulk upload/roster the sites</h4>

                        <div className={style.button}>
                            <BulkUploader
                                handle="bulkSites"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconOffice spaceRight /> Roster Sites
                                    </button>
                                }
                            />
                        </div>
                    </div>

                    <div className={style.step}>
                        <h4 className={style.row}> Step 2: Bulk upload/roster the home visitors</h4>

                        <div className={style.button}>
                            <BulkUploader
                                handle="bulkHomeVisitors"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconUsers4 spaceRight /> Roster Home Visitors
                                    </button>
                                }
                            />
                        </div>
                    </div>

                    <div className={style.step}>
                        <h4 className={style.row}> Step 3: Bulk upload/roster the caregivers</h4>
                        <p>
                            Caregivers must be rostered as pawns as well as rostered into each
                            timepoint deployment for metrics. The caregivers will be linked to a
                            home visitor if included. Both date of birth and enrollment date must be
                            in <strong style={{ color: 'red' }}>YYYY-MM-DD </strong>
                            format for caregivers.
                        </p>

                        <div className={style.button}>
                            <BulkUploader
                                handle="bulkHistoricalCaregivers"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconUsers spaceRight /> Roster Caregivers with Enrollment
                                        Data
                                    </button>
                                }
                                deploymentId={caregiver1}
                            />
                        </div>

                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkHistoricalCaregivers"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconThree spaceRight /> Add 3 month data for Caregivers
                                    </button>
                                }
                                deploymentId={caregiver2}
                            />
                        </div>

                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkHistoricalCaregivers"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconSix spaceRight /> Add 6 month data for Caregivers
                                    </button>
                                }
                                deploymentId={caregiver3}
                            />
                        </div>

                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkHistoricalCaregivers"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconOne /> <IconTwo spaceRight />
                                        Add 12 month data for Caregivers
                                    </button>
                                }
                                deploymentId={caregiver4}
                            />
                        </div>

                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkHistoricalCaregivers"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconTwo />
                                        <IconFour spaceRight /> Add 24 month data for Caregivers
                                    </button>
                                }
                                deploymentId={caregiver5}
                            />
                        </div>

                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkHistoricalCaregivers"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconThree />
                                        <IconSix spaceRight /> Add 36 month data for Caregivers
                                    </button>
                                }
                                deploymentId={caregiver6}
                            />
                        </div>

                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkHistoricalCaregivers"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconFour />
                                        <IconEight spaceRight /> Add 48 month data for Caregivers
                                    </button>
                                }
                                deploymentId={caregiver7}
                            />
                        </div>
                    </div>

                    <div className={style.step}>
                        <h4 className={style.row}> Step 4: Bulk upload/roster the children</h4>
                        <p>
                            They will be linked to their caregiver. Remember dates must be in{' '}
                            <strong style={{ color: 'red' }}>YYYY-MM-DD </strong>
                        </p>

                        <div className={style.button}>
                            <BulkUploader
                                handle="bulkChildren"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconUsers5 spaceRight /> Roster Children with Enrollment
                                        Data
                                    </button>
                                }
                                deploymentId={child1}
                            />
                        </div>
                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkChildren"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconThree spaceRight /> Add 3 month data for Children
                                    </button>
                                }
                                deploymentId={child2}
                            />
                        </div>
                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkChildren"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconSix spaceRight /> Add 6 month data for Children
                                    </button>
                                }
                                deploymentId={child3}
                            />
                        </div>
                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkChildren"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconOne />
                                        <IconTwo spaceRight /> Add 12 month data for Children
                                    </button>
                                }
                                deploymentId={child4}
                            />
                        </div>
                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkChildren"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconOne />
                                        <IconEight spaceRight /> Add 18 month data for Children
                                    </button>
                                }
                                deploymentId={child5}
                            />
                        </div>
                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkChildren"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconTwo />
                                        <IconFour spaceRight /> Add 24 month data for Children
                                    </button>
                                }
                                deploymentId={child6}
                            />
                        </div>
                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkChildren"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconThree />
                                        <IconSix spaceRight /> Add 36 month data for Children
                                    </button>
                                }
                                deploymentId={child7}
                            />
                        </div>
                        <div className={classnames(style.button, style.newButton)}>
                            <BulkUploader
                                handle="bulkChildren"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        <IconFour />
                                        <IconEight spaceRight /> Add 48 month data for Children
                                    </button>
                                }
                                deploymentId={child8}
                            />
                        </div>
                    </div>
                </div>

                <hr className={style.line} />

                <div>
                    <h3>
                        <u>Metrics</u>
                    </h3>
                    <div className={style.step}>
                        <h4 className={style.row}>
                            {' '}
                            Step 5: Bulk upload metrics for the caregivers for each timepoint
                            deployment.
                        </h4>
                        <ol>
                            <li>
                                Upload metrics for each <i>caregiver</i> deployment on the Deploy
                                tab of Quest
                            </li>
                            <li>
                                Expand the <i>Advanced Settings</i> at the bottom of the modal
                            </li>
                            <li>
                                Turn on <i>Closed Roster</i>
                            </li>
                            <li>
                                Click the <i>Bulk Add Respondent Metrics</i> button{' '}
                            </li>
                            <li>
                                You can use the same CSV that was used for rostering - verify report
                                headers have been added and update the deploymentId.
                            </li>
                            <li>
                                Ask developer prime to set the started date for the assignment
                                progress so the button shows <strong>completed</strong> on the card.
                            </li>
                        </ol>
                        <div className={style.button}>
                            <a
                                type="button"
                                className="btn btn-primary"
                                href={Boilerplate.url('quest/79/deploy')}
                            >
                                <IconGoTo spaceRight />
                                Go to MIECHV Deployments
                            </a>
                        </div>
                        <h4 className={style.row}>
                            {' '}
                            Step 6: Bulk upload metrics for the children for each timepoint
                            deployment.
                        </h4>
                        <ol>
                            <li>
                                Upload metrics for each <i>child</i> deployment on the Deploy tab of
                                Quest
                            </li>
                            <li>
                                Expand the <i>Advanced Settings</i> at the bottom of the modal
                            </li>
                            <li>
                                Turn on <i>Closed Roster</i>
                            </li>
                            <li>
                                Click the <i>Bulk Add Respondent Metrics</i> button{' '}
                            </li>
                            <li>
                                You can use the same CSV that was used for rostering - verify report
                                headers have been added
                            </li>
                            <li>
                                Ask developer prime to set the started date for the assignment
                                progress so the button shows <strong>completed</strong> on the card.
                            </li>
                        </ol>
                        <div className={style.button}>
                            <a
                                type="button"
                                className="btn btn-primary"
                                href={Boilerplate.url('quest/79/deploy')}
                            >
                                <IconGoTo spaceRight />
                                Go to MIECHV Deployments
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
