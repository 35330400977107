export const useradminData = [
    {
        id: 100,
        name: 'Org 100',
        groupingUnits: [
            {
                id: 90,
                name: 'Group 90',
                sites: [
                    {
                        id: 11,
                        name: 'Site 11',
                        groups: [
                            {
                                id: 13,
                                name: 'Group 13',
                            },
                            {
                                id: 14,
                                name: 'Group 14',
                            },
                        ],
                    },
                    {
                        id: 12,
                        name: 'Site 12',
                        groups: [
                            {
                                id: 16,
                                name: 'Group 16',
                            },
                            {
                                id: 17,
                                name: 'Group 17',
                            },
                        ],
                    },
                ],
            },
            {
                id: 80,
                name: 'Group 80',
                sites: [
                    {
                        id: 50,
                        name: 'Site 50',
                        groups: [
                            {
                                id: 18,
                                name: 'Group 18',
                            },
                            {
                                id: 19,
                                name: 'Group 19',
                            },
                        ],
                    },
                    {
                        id: 40,
                        name: 'Site 40',
                        groups: [
                            {
                                id: 2,
                                name: 'Group 2',
                            },
                            {
                                id: 3,
                                name: 'Group 3',
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
