import React from 'react';
import PropTypes from 'prop-types';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';

import style from './style.css';
import Footer from '../../components/Footer';

var AppDefs = window.cccisd.appDefs;
var Fortress = window.cccisd.fortress;
export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    render() {
        const { className } = this.props;

        if (
            Fortress.user.acting.role.handle === 'provider' &&
            !Fortress.user.acting.role_data.hybridRole
        ) {
            // remove the Manage and Site Forms page routes for providers if they do not have hybrid role
            AppDefs.routes = AppDefs.routes.filter(
                route =>
                    route.handle !== 'miechv_admin_manage' &&
                    route.handle !== 'miechv_forms' &&
                    route.handle !== 'miechv_hybrid_dashboard'
            );
            AppDefs.navs[1].nav = AppDefs.navs[1].nav.filter(
                nav =>
                    nav.handle !== 'miechv_admin_manage' &&
                    nav.handle !== 'miechv_forms' &&
                    nav.handle !== 'miechv_hybrid_dashboard'
            );
        }

        if (
            Fortress.user.acting.role.handle === 'provider' &&
            Fortress.user.acting.role_data.hybridRole
        ) {
            // hybrid Home Visitors have the same dashboard as Site Admins
            AppDefs.routes = AppDefs.routes.filter(route => route.handle !== 'miechv_dashboard');
            AppDefs.navs[1].nav = AppDefs.navs[1].nav.filter(
                nav => nav.handle !== 'miechv_dashboard'
            );
        }

        if (
            Fortress.user.acting.role.handle === 'sfp_groupingUnitAdmin' &&
            Fortress.user.acting.role_data.groupingUnitAdminRoleSFP !== 'CTSC Coach'
        ) {
            // remove the Quest page & Cycle Planning route for SFP Program Managers if they do not have coach role
            AppDefs.routes = AppDefs.routes.filter(
                route => route.handle !== 'quest' && route.handle !== 'cyclePlanning'
            );
            AppDefs.navs[1].nav = AppDefs.navs[1].nav.filter(
                nav => nav.handle !== 'quest' && nav.handle !== 'cyclePlanning'
            );
        }

        return (
            <div className={style.wrapper}>
                <header className={style.header}>
                    {Fortress.auth() && Fortress.user.acting.role.handle !== 'student' && (
                        <div className={style.secondaryNav}>
                            <SecondaryNav
                                className={className}
                                navs={AppDefs.navs}
                                routes={AppDefs.routes}
                                isInverse
                            />
                        </div>
                    )}
                </header>
                <div className={style.body}>
                    <div className={className}>{this.props.children}</div>
                </div>
                <footer className={style.footer}>
                    <Footer />
                </footer>
            </div>
        );
    }
}
