import React from 'react';
import PropTypes from 'prop-types';

import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';

import ProviderForm from '../../../../components/triplep/Manage/ProviderForm';

import IconPencil from 'cccisd-icons/pencil';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default class EditProviderForm extends React.Component {
    static propTypes = {
        loadData: PropTypes.func,
        settings: PropTypes.object,
        row: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    onSubmit = async values => {
        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', {
                pawn: this.props.row['pawn.pawnId'],
            }),
            {
                user: values.username,
                first_name: values.firstName,
                middleName: values.middleName,
                last_name: values.lastName,
                email: values.username,
                phone: values.phone,
                gender: values.gender,
                pawnHash: values.pawnHash,
                triplepRace: values.triplepRace,
                raceOther: values.raceOther,
                group: values.group,
                sector: values.sector,
                sectorOther: values.sectorOther,
                professionalTitle: values.professionalTitle,
                accredIndL2: values.accredIndL2,
                accredIndL3: values.accredIndL3,
                accredIndL4: values.accredIndL4,
                accredGrpL2: values.accredGrpL2,
                accredGrpL3: values.accredGrpL3,
                accredGrpL4: values.accredGrpL4,
                accredDateIndL2: values.accredDateIndL2,
                accredDateIndL3: values.accredDateIndL3,
                accredDateIndL4: values.accredDateIndL4,
                accredDateGrpL2: values.accredDateGrpL2,
                accredDateGrpL3: values.accredDateGrpL3,
                accredDateGrpL4: values.accredDateGrpL4,
                trainIndL2: values.trainIndL2,
                trainIndL3: values.trainIndL3,
                trainIndL4: values.trainIndL4,
                trainGrpL2: values.trainGrpL2,
                trainGrpL3: values.trainGrpL3,
                trainGrpL4: values.trainGrpL4,
                trainDateIndL2: values.trainDateIndL2,
                trainDateIndL3: values.trainDateIndL3,
                trainDateIndL4: values.trainDateIndL4,
                trainDateGrpL2: values.trainDateGrpL2,
                trainDateGrpL3: values.trainDateGrpL3,
                trainDateGrpL4: values.trainDateGrpL4,
            }
        );

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        await this.props.loadData();
        notification('Practitioner edited.');
    };

    getInitialValues = () => {
        const { row, settings } = this.props;

        return {
            username: row['user.username'],
            pawnHash: row['pawn.pawnHash'],
            firstName: row['user.firstName'],
            middleName: row['fields.middleName'],
            lastName: row['user.lastName'],
            group: row[settings.groupPath],
            email: row['user.email'],
            phone: row['user.phone'],
            triplepRace: row['fields.triplepRace'],
            raceOther: row['fields.raceOther'],
            gender: row['fields.gender'],
            sector: row['fields.sector'],
            sectorOther: row['fields.sectorOther'],
            professionalTitle: row['fields.professionalTitle'],
            accredIndL2: row['fields.accredIndL2'],
            accredIndL3: row['fields.accredIndL3'],
            accredIndL4: row['fields.accredIndL4'],
            accredGrpL2: row['fields.accredGrpL2'],
            accredGrpL3: row['fields.accredGrpL3'],
            accredGrpL4: row['fields.accredGrpL4'],
            accredDateIndL2: row['fields.accredDateIndL2'],
            accredDateIndL3: row['fields.accredDateIndL3'],
            accredDateIndL4: row['fields.accredDateIndL4'],
            accredDateGrpL2: row['fields.accredDateGrpL2'],
            accredDateGrpL3: row['fields.accredDateGrpL3'],
            accredDateGrpL4: row['fields.accredDateGrpL4'],
            trainIndL2: row['fields.trainIndL2'],
            trainIndL3: row['fields.trainIndL3'],
            trainIndL4: row['fields.trainIndL4'],
            trainGrpL2: row['fields.trainGrpL2'],
            trainGrpL3: row['fields.trainGrpL3'],
            trainGrpL4: row['fields.trainGrpL4'],
            trainDateIndL2: row['fields.trainDateIndL2'],
            trainDateIndL3: row['fields.trainDateIndL3'],
            trainDateIndL4: row['fields.trainDateIndL4'],
            trainDateGrpL2: row['fields.trainDateGrpL2'],
            trainDateGrpL3: row['fields.trainDateGrpL3'],
            trainDateGrpL4: row['fields.trainDateGrpL4'],
        };
    };

    render() {
        return (
            <Modal
                ref={this.modal}
                trigger={
                    <Tooltip title="Edit">
                        <button type="button" className="btn btn-xs btn-success">
                            <IconPencil />
                        </button>
                    </Tooltip>
                }
                title="Edit Practitioner"
            >
                <ProviderForm
                    operation="edit"
                    onSubmit={this.onSubmit}
                    initialValues={this.getInitialValues(this.props.row)}
                    {...this.props}
                />
            </Modal>
        );
    }
}
