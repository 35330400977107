import React from 'react';
import { Field, CccisdToggle, CccisdInput } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    title: '',
    description: '',
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Table?" />
            <Field name="title" component={CccisdInput} label="Title" />
            <Field name="description" component={CccisdInput} label="Description" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
