import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import parentTableQuery from './parentTable.graphql';
import levelsQuery from '../../../levels.graphql';

import TableAndChart from '../..';
import style from '../../style.css';
import {
    getCaregiverTotal,
    renderPercentages,
    initialLoadMessage,
} from '../../../../../../reportHelpers.js';

const Appdefs = window.cccisd.appDefs;
const grpL4 = Appdefs.app.tripleP.grpL4;

const Parents = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [rowList, setRowList] = useState([]);

    const { description, show, colors, title } = props.settings;

    const { groupIds, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? Number(groupIds) : previewData.groupIds;

    useEffect(() => {
        setIsLoading(true);

        getSiteANDgrpL4().then(async response => {
            const site = await response.data.groups.triplep_site;
            const grpL4Fids = await response.data.flows.assignmentProgressList;

            const filtered2s = grpL4Fids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_2')
            );
            const sess2deploymentIds = filtered2s.map(session => {
                return [session.deployment.deploymentId];
            });
            const grpL4session2s = sess2deploymentIds.flat();

            const filtered8s = grpL4Fids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_8')
            );
            const sess8deploymentIds = filtered8s.map(session => {
                return [session.deployment.deploymentId];
            });
            const grpL4session8s = sess8deploymentIds.flat();

            const allIds = grpL4Fids.map(card => {
                return [card.deployment.deploymentId];
            });
            const allSessions = allIds.flat();

            getTable({
                grpL4session2s: grpL4session2s.length > 0 ? grpL4session2s : 0,
                grpL4session8s: grpL4session8s.length > 0 ? grpL4session8s : 0,
                allSessions: allSessions.length > 0 ? allSessions : 0,
            }).then(async res => {
                const grpL2 = res.data.flows.grpL2;
                const grpL2Total = getCaregiverTotal(grpL2);

                const grpL3 = res.data.flows.grpL3;
                const grpL3Total = getCaregiverTotal(grpL3);

                const cardLevels = res.data.groups.triplep_site.childRoles;

                await getRows({ site, grpL2Total, grpL3Total, cardLevels });
            });
            setIsLoading(false);
        });
    }, [selectedIds, reportingFrom, reportingTo]);

    const getSiteANDgrpL4 = async () => {
        const response = await apollo.query({
            query: levelsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                reportingFrom,
                reportingTo,
                grpL4assignmentId: grpL4.assignmentId,
            },
        });
        return response;
    };

    const getTable = async ({ grpL4session2s, grpL4session8s, allSessions }) => {
        const response = await apollo.query({
            query: parentTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                reportingFrom,
                reportingTo,
                allSessions,
                grpL4session2s,
                grpL4session8s,
                grpL4assignmentId: grpL4.assignmentId,
            },
        });
        return response;
    };

    const columns = [
        {
            name: 'label',
            label: 'Services Delivered',
        },
        {
            name: 'value',
            label: 'Total',
            class: 'text-center',
            setStyle: () => {
                return { width: '15%' };
            },
        },
        {
            name: 'required',
            label: 'Required Content',
            class: 'text-center',
            setStyle: () => {
                return { width: '15%' };
            },
        },
        {
            name: 'completed',
            label: 'Full Series',
            class: 'text-center',
            setStyle: () => {
                return { width: '15%' };
            },
        },
    ];

    const getRows = ({ site, grpL2Total, grpL3Total, cardLevels }) => {
        if (site.length < 1 || site.group.groupId === null) {
            return null;
        }

        const siteServices = {
            grpL2: site.fields.grpL2,
            grpL3: site.fields.grpL3,
            indL3: site.fields.indL3,
            indL4: site.fields.indL4,
            grpL4: site.fields.grpL4,
        };

        const rows = [
            {
                id: 'grpL2',
                label: 'Level 2 Seminar',
                value: grpL2Total,
                required: grpL2Total > 0 ? '100%' : '0%',
                completed: grpL2Total > 0 ? '100%' : '0%',
            },
            {
                id: 'grpL3',
                label: 'Level 3 Discussion',
                value: grpL3Total,
                required: grpL3Total > 0 ? '100%' : '0%',
                completed: grpL3Total > 0 ? '100%' : '0%',
            },
            {
                id: 'indL3',
                label: 'Level 3 Primary Care',
                value: cardLevels.indL3,
                required: `${renderPercentages(cardLevels.indL3required, cardLevels.indL3)}%`,
                completed: `${renderPercentages(cardLevels.indL3complete, cardLevels.indL3)}%`,
            },
            {
                id: 'indL4',
                label: 'Level 4 Standard',
                value: cardLevels.indL4,
                required: `${renderPercentages(cardLevels.indL4required, cardLevels.indL4)}%`,
                completed: `${renderPercentages(cardLevels.indL4complete, cardLevels.indL4)}%`,
            },
            {
                id: 'grpL4',
                label: 'Level 4 Group',
                value: cardLevels.grpL4,
                required: `${renderPercentages(cardLevels.grpL4required, cardLevels.grpL4)}%`,
                completed: `${renderPercentages(cardLevels.grpL4complete, cardLevels.grpL4)}%`,
            },
        ].filter(row => {
            if (siteServices[row.id] === true) {
                return true;
            }
            return false;
        });
        setRowList(rows);
    };

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <strong
                        style={{
                            borderBottom: colors ? `1px solid ${colors.primary}` : null,
                            color: colors ? colors.primary : null,
                        }}
                    >
                        {title}
                    </strong>
                    <div className={style.description}>{description}</div>
                    {selectedIds === 0 ? (
                        initialLoadMessage
                    ) : (
                        <Loader loading={isLoading} removeChildren>
                            <TableAndChart
                                columns={columns}
                                rows={rowList}
                                tableName="Parents Served"
                                chartData={rowList}
                            />
                        </Loader>
                    )}
                </div>
            )}
        </>
    );
};

Parents.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Parents.defaultProps = {
    filters: {},
};

export default Parents;
