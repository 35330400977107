import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Table from 'cccisd-table';
import Loader from 'cccisd-loader';

import feedbackIdsQuery from '../../feedbackIds.graphql';
import satisfactionTableQuery from '../../satisfactionTable.graphql';
import cardLevelSatisfactionTableQuery from '../../cardLevelSatisfactionTable.graphql';
import siteNamesQuery from '../../siteNames.graphql';
import grpL4FeedbackIdsQuery from '../../grpL4feedbackIds.graphql';
import grpL4satisfactionTableQuery from '../../grpL4satisfactionTable.graphql';

import style from './style.css';
import { renderPercentages } from '../../../../../reportHelpers.js';

const Appdefs = window.cccisd.appDefs;
const grpL2assignmentId = Appdefs.app.tripleP.grpL2assignmentId;
const grpL3assignmentId = Appdefs.app.tripleP.grpL3assignmentId;
const grpL4 = Appdefs.app.tripleP.grpL4;

const ParentSatisfactionTable = props => {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { title, description, show, colors } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? groupIds.map(Number) : previewData.groupIds;

    useEffect(() => {
        setIsLoading(true);

        if (level === 'Level 2 Seminar' || level === 'Level 3 Discussion') {
            getFeedbackIds({
                assignmentId: getAssignmentId(level),
                sessionDate: getLevelString(level),
            }).then(async response => {
                const allSeminars = response.data.flows.assignmentProgressList;
                let feedbackId = allSeminars.map(sem => {
                    return [sem.additionalInfo.feedbackDeploymentId];
                });

                getTable({ feedbackIds: feedbackId.length > 0 ? feedbackId.flat() : 0 }).then(
                    async res => {
                        const results = res.data.flows;
                        setData(results);
                    }
                );
            });
            setIsLoading(false);
        }

        if (level === 'Level 3 Primary Care' || level === 'Level 4 Standard') {
            getSiteNames().then(async response => {
                const indL3 = response.data.flows.indL3;
                const indL4 = response.data.flows.indL4;
                const siteList = response.data.groups.triplep_siteList;
                const sites = siteList.map(site => {
                    return [site.group.label];
                });

                await getCardLevelTable({
                    feedbackIds:
                        level === 'Level 3 Primary Care'
                            ? indL3.assignment.deployment.deploymentId
                            : indL4.assignment.deployment.deploymentId,
                    assignmentId:
                        level === 'Level 3 Primary Care'
                            ? indL3.assignment.assignmentId
                            : indL4.assignment.assignmentId,
                    siteNames: sites.flat(),
                    siteLevelString: getLevelString(level),
                }).then(async res => {
                    const results = res.data.flows;
                    setData(results);
                });
            });
            setIsLoading(false);
        }

        if (level === 'Level 4 Group') {
            grpL4FeedbackIds({
                assignmentId: getAssignmentId(level),
            }).then(async response => {
                const allSeminars = response.data.flows.assignmentProgressList;
                let feedbackId = allSeminars.map(sem => {
                    return [sem.additionalInfo.feedbackDeploymentId];
                });

                grpL4Table({
                    feedbackIds: feedbackId.length > 0 ? feedbackId.flat() : 0,
                    assignmentId: getAssignmentId(level),
                }).then(async res => {
                    const results = res.data.flows;
                    setData(results);
                });
            });
            setIsLoading(false);
        }
    }, [groupIds, level, reportingFrom, reportingTo]);

    if (!data) {
        return <Loader loading={isLoading} removeChildren />;
    }

    const getAssignmentId = levelFilter => {
        switch (levelFilter) {
            case 'Level 2 Seminar':
                return grpL2assignmentId;
            case 'Level 3 Discussion':
                return grpL3assignmentId;
            case 'Level 4 Group':
                return grpL4.parentFeedbackAssignmentId;
            default:
                return grpL2assignmentId;
        }
    };

    const getLevelString = levelFilter => {
        switch (levelFilter) {
            case 'Level 2 Seminar':
                return 'devTags.L2SeminarDate';
            case 'Level 3 Discussion':
                return 'devTags.L3DiscussionDate';
            case 'Level 3 Primary Care':
                return 'variables.114_app_variable_sdo';
            case 'Level 4 Standard':
                return 'variables.126_app_variable_sdo';
            default:
                return 'devTags.L2SeminarDate';
        }
    };

    const getFeedbackIds = ({ assignmentId, sessionDate }) => {
        const response = apollo.query({
            query: feedbackIdsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                assignmentId,
                reportingFrom,
                reportingTo,
                sessionDate,
            },
        });

        return response;
    };

    const getSiteNames = () => {
        const response = apollo.query({
            query: siteNamesQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
            },
        });

        return response;
    };

    const grpL4FeedbackIds = ({ assignmentId }) => {
        const response = apollo.query({
            query: grpL4FeedbackIdsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                assignmentId,
            },
        });

        return response;
    };

    const getTable = async ({ feedbackIds, assignmentId }) => {
        const response = await apollo.query({
            query: satisfactionTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                feedbackIds,
                assignmentId,
            },
        });
        return response;
    };

    const getCardLevelTable = async ({ feedbackIds, assignmentId, siteLevelString, siteNames }) => {
        const response = await apollo.query({
            query: cardLevelSatisfactionTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                feedbackIds,
                assignmentId,
                reportingFrom,
                reportingTo,
                siteLevelString,
                siteNames,
            },
        });
        return response;
    };

    const grpL4Table = async ({ feedbackIds, assignmentId }) => {
        const response = await apollo.query({
            query: grpL4satisfactionTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                feedbackIds,
                assignmentId,
                reportingFrom,
                reportingTo,
            },
        });
        return response;
    };

    const columns = [
        {
            name: 'feedbackDomain',
            label: 'Feedback Domain',
        },
        {
            name: 'stronglyDisagree',
            label: 'Strongly Disagree',
            class: 'text-center',
            setStyle: () => {
                return { width: '11%' };
            },
        },
        {
            name: 'disagree',
            label: 'Disagree',
            class: 'text-center',
            setStyle: () => {
                return { width: '11%' };
            },
        },
        {
            name: 'agree',
            label: 'Agree',
            class: 'text-center',
            setStyle: () => {
                return { width: '11%' };
            },
        },
        {
            name: 'stronglyAgree',
            label: 'Strongly Agree',
            class: 'text-center',
            setStyle: () => {
                return { width: '11%' };
            },
        },
    ];

    const renderRows = () => {
        let stronglyDisagrees =
            data.knowledgeableStronglyDisagree +
            data.engagingStronglyDisagree +
            data.adviceStronglyDisagree +
            data.servicesStronglyDisagree +
            data.comfortStronglyDisagree +
            data.recommendStronglyDisagree +
            data.recommendCommunityStronglyDisagree +
            data.needsStronglyDisagree;

        let disagrees =
            data.knowledgeableDisagree +
            data.engagingDisagree +
            data.adviceDisagree +
            data.servicesDisagree +
            data.comfortDisagree +
            data.recommendDisagree +
            data.recommendCommunityDisagree +
            data.needsDisagree;

        let agrees =
            data.knowledgeableAgree +
            data.engagingAgree +
            data.adviceAgree +
            data.servicesAgree +
            data.comfortAgree +
            data.recommendAgree +
            data.recommendCommunityAgree +
            data.needsAgree;

        let stronglyAgrees =
            data.knowledgeableStronglyAgree +
            data.engagingStronglyAgree +
            data.adviceStronglyAgree +
            data.servicesStronglyAgree +
            data.comfortStronglyAgree +
            data.recommendStronglyAgree +
            data.recommendCommunityStronglyAgree +
            data.needsStronglyAgree;

        let grandTotal = stronglyDisagrees + disagrees + agrees + stronglyAgrees;

        return [
            {
                id: 'knowledgeable',
                feedbackDomain: 'The facilitator was knowledgeable.',
                stronglyDisagree: data.knowledgeableStronglyDisagree,
                disagree: data.knowledgeableDisagree,
                agree: data.knowledgeableAgree,
                stronglyAgree: data.knowledgeableStronglyAgree,
            },
            {
                id: 'engaging',
                feedbackDomain: 'The facilitator was engaging.',
                stronglyDisagree: data.engagingStronglyDisagree,
                disagree: data.engagingDisagree,
                agree: data.engagingAgree,
                stronglyAgree: data.engagingStronglyAgree,
            },
            {
                id: 'advice',
                feedbackDomain: 'I plan on using the advice received.',
                stronglyDisagree: data.adviceStronglyDisagree,
                disagree: data.adviceDisagree,
                agree: data.adviceAgree,
                stronglyAgree: data.adviceStronglyAgree,
            },
            {
                id: 'services',
                feedbackDomain: 'I would like to receive additional TP services.',
                stronglyDisagree: data.servicesStronglyDisagree,
                disagree: data.servicesDisagree,
                agree: data.servicesAgree,
                stronglyAgree: data.servicesStronglyAgree,
            },
            {
                id: 'comfort',
                feedbackDomain: 'I feel comfortable asking for parenting help.',
                stronglyDisagree: data.comfortStronglyDisagree,
                disagree: data.comfortDisagree,
                agree: data.comfortAgree,
                stronglyAgree: data.comfortStronglyAgree,
            },
            {
                id: 'recommend',
                feedbackDomain: 'I would recommend this seminar to other parents.',
                stronglyDisagree: data.recommendStronglyDisagree,
                disagree: data.recommendDisagree,
                agree: data.recommendAgree,
                stronglyAgree: data.recommendStronglyAgree,
            },
            {
                id: 'recommendCommunity',
                feedbackDomain: 'I would recommend Triple P to other parents in my community.',
                stronglyDisagree: data.recommendCommunityStronglyDisagree,
                disagree: data.recommendCommunityDisagree,
                agree: data.recommendCommunityAgree,
                stronglyAgree: data.recommendCommunityStronglyAgree,
            },
            {
                id: 'needs',
                feedbackDomain: 'I feel that the services received met my needs.',
                stronglyDisagree: data.needsStronglyDisagree,
                disagree: data.needsDisagree,
                agree: data.needsAgree,
                stronglyAgree: data.needsStronglyAgree,
            },
            {
                id: 'total',
                feedbackDomain: 'Total Responses (%)',
                stronglyDisagree: `${stronglyDisagrees} (${renderPercentages(
                    stronglyDisagrees,
                    grandTotal
                )}%)`,
                disagree: `${disagrees} (${renderPercentages(disagrees, grandTotal)}%)`,
                agree: `${agrees} (${renderPercentages(agrees, grandTotal)}%)`,
                stronglyAgree: `${stronglyAgrees} (${renderPercentages(
                    stronglyAgrees,
                    grandTotal
                )}%)`,
            },
        ];
    };

    return (
        <Loader loading={isLoading} removeChildren>
            {show && (
                <div className={style.wrapper}>
                    <strong
                        style={
                            colors
                                ? {
                                      color: colors.primary,
                                      fontSize: '1.5em',
                                      marginBottom: '1em',
                                  }
                                : { fontSize: '1.5em', marginBottom: '1em' }
                        }
                    >
                        {title}
                    </strong>
                    <div className={style.description}>{description}</div>
                    <Table
                        columns={columns}
                        query={satisfactionTableQuery}
                        data={renderRows()}
                        noRecordsMessage="No data collected at selected filters."
                        showPerPageOptions={false}
                        hideShowingRowsSummary
                        setRowClass={row => row.id === 'total' && 'info'}
                    />
                </div>
            )}
        </Loader>
    );
};

ParentSatisfactionTable.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

ParentSatisfactionTable.defaultProps = {
    filters: {},
};

export default ParentSatisfactionTable;
