import React from 'react';
import Modal from 'cccisd-modal';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';

import FamilyForm from '../../../../components/sfp/Manage/FamilyForm';

import IconPencil from 'cccisd-icons/pencil';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default class AddFamilyForm extends React.Component {
    static propTypes = {
        loadData: PropTypes.func,
        settings: PropTypes.object,
        row: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    onSubmit = async values => {
        const response = await axios.put(
            Boilerplate.route('api.nexus.group.update', {
                group: this.props.row['group.groupId'],
            }),
            {
                label: values.label,
                enrollmentDate: values.enrollmentDate,
                zipCode: values.zipCode,
                county: values.county,
                referralSource: values.referralSource,
                referralName: values.referralName,
                referralCounty: values.referralCounty,
                familyStatus: values.familyStatus,
                parent: values.group,
                statusChangeDate: values.statusChangeDate,
            }
        );
        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        await this.props.loadData();
        notification('Family edited.');
    };

    getInitialValues = () => {
        const { row, settings } = this.props;

        return {
            label: row['group.label'],
            enrollmentDate: row['fields.enrollmentDate'],
            zipCode: row['fields.zipCode'],
            county: row['fields.county'],
            referralSource: row['fields.referralSource'],
            referralName: row['fields.referralName'],
            referralCounty: row['fields.referralCounty'],
            familyStatus: row['fields.familyStatus'],
            familyGroupId: row['group.groupId'],
            group: row[settings.groupPath],
            statusChangeDate: row['fields.statusChangeDate'],
        };
    };

    render() {
        return (
            <Modal
                ref={this.modal}
                trigger={
                    <Tooltip title="Edit">
                        <button type="button" className="btn btn-xs btn-success">
                            <IconPencil />
                        </button>
                    </Tooltip>
                }
                title={`Edit ${this.props.row['group.label']}`}
                size="large"
            >
                <FamilyForm
                    operation="edit"
                    onSubmit={this.onSubmit}
                    initialValues={this.getInitialValues(this.props.row)}
                    {...this.props}
                />
            </Modal>
        );
    }
}
