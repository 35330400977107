import React from 'react';
import NavBar from 'js/components/NavBar';
import styles from './style.css';

const AppHeader = () => {
    return (
        <header className={styles.header}>
            <NavBar showMenu={false} allowSwitchRoles={false} noLogoLink />
        </header>
    );
};

export default AppHeader;
