import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const MyResponsiveBar = props => {
    return (
        <ResponsiveBar
            data={props.data}
            groupMode="grouped"
            layout="horizontal"
            keys={[
                'Number of Clients Finishing Required Content',
                'Number of Clients Completing Service',
            ]}
            indexBy="sdo"
            margin={{ top: 20, right: 10, bottom: 50, left: 200 }}
            colors={['#00ADDC', '#f37021']}
            axisBottom={{
                tickSize: 0,
                tickPadding: 3,
                tickRotation: 0,
            }}
            label={d => `${d.value}`}
            axisLeft={{
                tickSize: 0,
                tickPadding: 3,
                tickRotation: -25,
            }}
            labelTextColor="#ffffff"
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 50,
                    itemsSpacing: 130,
                    itemWidth: 190,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    );
};

export default MyResponsiveBar;
