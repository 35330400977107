import React from 'react';
import PropTypes from 'prop-types';
import { Field, CccisdInput, CccisdWysiwyg, CccisdColorPicker } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import filterFields from '../filterFields.js';
import widgets from '../widgets.js';

export const initialValues = {
    filterFields: filterFields.map(item => item.name),
    otherFilterOptions: [],
    colors: { primary: '#00ADDC' },
    header: {
        title: 'Site Coordinator Session Data',
        createdDateText: 'Report Created On:',
        description:
            'This report provides Site Coordinator Session Data for the selected Site and/or Cycle Number(s) during the selected time period.',
        siteText: 'Site',
        reportingPeriodText: 'From:',
        levelText: '',
    },
    body: {
        heading: '',
    },
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const Component = props => {
    return (
        <div>
            <BuilderBlock title="Header">
                <Field name="header.title" component={CccisdInput} label="Title" />
                <Field name="header.description" component={CccisdInput} label="Description" />
                <Field
                    name="header.reportingPeriodText"
                    component={CccisdInput}
                    label="Reporting Period Text"
                />
                <Field
                    name="header.createdDateText"
                    component={CccisdInput}
                    label="Created Date Text"
                />
            </BuilderBlock>
            <BuilderBlock title="Sub Header Bar">
                <Field name="header.siteText" component={CccisdInput} label="Group Type" />
                <Field name="header.levelText" component={CccisdInput} label="Level Text" />
            </BuilderBlock>
            <BuilderBlock title="Color">
                {/* Margin added to show picker even with "overflow:hidden" */}
                <div style={{ marginBottom: '230px' }}>
                    <Field
                        name="colors.primary"
                        component={CccisdColorPicker}
                        label="Color Scheme"
                    />
                </div>
            </BuilderBlock>
            <BuilderBlock title="Page 2 Heading">
                <Field name="body.heading" component={CccisdWysiwyg} label="Page Heading" />
            </BuilderBlock>
        </div>
    );
};

Component.propTypes = {
    onValidChange: PropTypes.func,
    settings: PropTypes.object,
};

export default reportTemplateBuilder({ filterFields, widgets })(Component);
