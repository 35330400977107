import React from 'react';
import _get from 'lodash/get';
import { sumArgs } from '../../components/miechv/MIECHVReports/Form1/form1helpers';

export default settings => {
    return ({ row }) => {
        const byModel = _get(row, 'childRoles.clientSummary.byModel');
        const showTotals = () => {
            if (byModel.length > 0) {
                const grandTotals = byModel.map(model => {
                    if (
                        settings.dropdown === 'alabamaParenting' &&
                        byModel[0].positiveParenting_before !== null
                    ) {
                        const totalAnswered = sumArgs(
                            model.positiveParentingAnswered_before,
                            model.positiveParentingAnswered_now
                        );

                        return <div key={model.value}>{totalAnswered}</div>;
                    }

                    if (
                        byModel.length > 0 &&
                        settings.dropdown === 'alabamaParenting' &&
                        byModel[0].positiveParenting_before === null
                    ) {
                        return null;
                    }

                    const totalAnswered = sumArgs(
                        model.familyFunctioningAnswered_before,
                        model.familyFunctioningAnswered_now,
                        model.nurturingAnswered_before,
                        model.nurturingAnswered_now,
                        model.supportAnswered_before,
                        model.supportAnswered_now
                    );

                    return <div key={model.value}>{totalAnswered}</div>;
                });

                return grandTotals;
            }
            return null;
        };

        return <div style={{ textAlign: 'center' }}>{showTotals()}</div>;
    };
};
