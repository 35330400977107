import React from 'react';
import { ResponsivePie } from 'cccisd-nivo/pie';
import PropTypes from 'prop-types';

const Pie = ({ data, colors, title, description }) => {
    const tooltip = input => {
        return (
            <div
                style={{
                    border: '1px solid #efeded',
                    backgroundColor: 'white',
                    padding: '0.5em',
                }}
            >{`${input.datum.data.label}: ${input.datum.data.value}`}</div>
        );
    };

    return (
        <div style={{ width: '100%', height: '220px' }}>
            <strong
                style={
                    colors
                        ? {
                              color: colors.primary,
                          }
                        : null
                }
            >
                {title}
            </strong>
            {description ? <div>{description}</div> : null}
            <ResponsivePie
                data={data}
                colors={['#D7DF23', '#F37021', '#00ADDC', '#C40075']}
                tooltip={tooltip}
                arcLinkLabel={d => `${d.label}`}
                legends={[]}
            />
        </div>
    );
};

Pie.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    colors: PropTypes.object,
    description: PropTypes.string,
};

export default Pie;
