import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    totalRespondents: {
        show: true,
        title: 'Total Feedback Respondents',
        description: '',
    },
    groupLevels: {
        show: true,
        title: 'Response Rate',
        description: '',
    },
    cardLevels: {
        show: true,
        title: 'Avg # of Sessions Completed',
        description: '',
    },
};

const Component = props => {
    return (
        <>
            <div>
                <Field
                    name="totalRespondents.show"
                    component={CccisdToggle}
                    label="Show Feedback Respondents?"
                />
                <Field name="totalRespondents.title" component={CccisdInput} label="Title" />
                <Field
                    name="totalRespondents.description"
                    component={CccisdInput}
                    label="Description"
                />
            </div>
            <div>
                <Field
                    name="groupLevels.show"
                    component={CccisdToggle}
                    label="Show Response Rate?"
                />
                <Field name="groupLevels.title" component={CccisdInput} label="Title" />
                <Field name="groupLevels.description" component={CccisdInput} label="Description" />
            </div>
            <div>
                <Field
                    name="cardLevels.show"
                    component={CccisdToggle}
                    label="Show Sessions Completed?"
                />
                <Field name="cardLevels.title" component={CccisdInput} label="Title" />
                <Field name="cardLevels.description" component={CccisdInput} label="Description" />
            </div>
        </>
    );
};

export default widgetBuilder({ initialValues })(Component);
