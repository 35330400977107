import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import style from './style.css';
import cardQuery from '../../cardQuery.graphql';
import calloutQuery from './callout.graphql';
import Callout from './Callout';
import _flatten from 'lodash/flatten';

const Appdefs = window.cccisd.appDefs;
const grpL4 = Appdefs.app.tripleP.grpL4;

const Component = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [session2s, setSession2s] = useState([]);
    const [caregivers, setCaregivers] = useState(null);
    const [children, setChildren] = useState(null);
    const [children0to5, setChildren0to5] = useState(null);

    const { show } = props.settings;

    const fakeData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;

    const { groupIds, reportingFrom, reportingTo } = props.filters;
    const selectedIds = groupIds ? groupIds.map(Number) : fakeData.groupIds;
    const assignmentId = grpL4.assignmentId;

    useEffect(() => {
        setIsLoading(true);
        getCards(selectedIds)
            .then(response => {
                const allFids = response.data.flows.assignmentProgressList;

                const filtered2s = allFids.filter(fid =>
                    fid.deployment.label.includes('Level 4_Group_Session_2')
                );
                const sess2deploymentIds = filtered2s.map(session => {
                    return [session.deployment.deploymentId];
                });
                setSession2s(sess2deploymentIds.flat());
            })
            .then(
                renderCallouts().then(res => {
                    let finishedRequired = res.data.flows.required.eachParent;

                    const calloutsData = props.convertDataStructure(selectedIds, finishedRequired);
                    setCaregivers(calloutsData.caregivers);
                    setChildren(calloutsData.children);
                    setChildren0to5(calloutsData.children0to5);
                })
            );
        setIsLoading(false);
    }, [groupIds, reportingFrom, reportingTo]);

    const getCards = ids => {
        return apollo.query({
            query: cardQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: ids,
                assignmentId,
                reportingFrom,
                reportingTo,
            },
        });
    };

    const renderCallouts = () => {
        return apollo.query({
            query: calloutQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                assignmentId,
                session2s: session2s.length > 0 ? session2s : 0,
            },
        });
    };

    return (
        <>
            {show && (
                <div className={style.callouts}>
                    <>
                        <Loader loading={isLoading}>
                            <Callout
                                handle="caregivers"
                                settings={{
                                    ...props.settings.caregivers,
                                    colors: props.settings.colors,
                                }}
                                data={caregivers === null ? 'No Data' : caregivers}
                            />
                        </Loader>
                        <Callout
                            handle="children"
                            settings={{
                                ...props.settings.totalChildren,
                                colors: props.settings.colors,
                            }}
                            data={children === null ? 'No Data' : children}
                        />
                        <Callout
                            handle="children0to5"
                            settings={{
                                ...props.settings.children0to5,
                                colors: props.settings.colors,
                            }}
                            data={children0to5 === null ? 'No Data' : children0to5}
                        />
                    </>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: (groupId, results) => {
        if (!groupId || !results || results === null) {
            return {
                caregivers: 'No Data',
                children: 'No Data',
                children0to5: 'No Data',
            };
        }

        const children = results.map(parent => {
            return [Number(parent.children[0].value)];
        });
        const flatten = _flatten(children);
        const totalChildren = flatten.reduce((a, b) => a + b, 0);

        const children0to5 = results.map(parent => {
            return [Number(parent.children0to5[0].value)];
        });
        const flatten0to5 = _flatten(children0to5);
        const totalChildren0to5 = flatten0to5.reduce((a, b) => a + b, 0);

        return {
            caregivers: results.length,
            children: totalChildren,
            children0to5: totalChildren0to5,
        };
    },
};

export default Component;
