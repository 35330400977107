import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
// import Loader from 'cccisd-loader';
// import moment from 'moment';
import IconComment from 'cccisd-icons/bubble-dots3';
import IconPlus from 'cccisd-icons/plus2';
import IconMinus from 'cccisd-icons/minus2';
import style from './style.css';
// import query from '../../../class.graphql';
// import useQuery from '../../../../../useQuery';

// const Comment = props => {
//     const { sessionDate, sessionComments } = props;

//     return (
//         <div className={style.commentWrapper}>
//             <div className={style.info}>
//                 {(sessionDate && moment(sessionDate).format('MMM Do YYYY')) || 'No date given'}
//             </div>
//             <div className={style.commentBlocks}>
//                 <div className={style.commentBlock}>
//                     <div className={style.comment}>{sessionComments}</div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// const Session = props => {
//     const { session, colors } = props;

//     return (
//         <div className={style.sessionWrapper}>
//             <div className={style.head} style={colors ? { backgroundColor: colors.primary } : {}}>
//                 Session {session.deployment.timepoint}
//             </div>
//             <div className={style.comments}>
//                 <Comment
//                     sessionDate={session.devTags.SessionDate}
//                     sessionComments={session.devTags.SessionComments}
//                     colors={colors}
//                 />
//             </div>
//         </div>
//     );
// };

const Component = props => {
    const [boxOpen, setBoxOpen] = useState(true);

    const { title } = props.settings;
    const { colors } = props;

    // const [data, loading] = useQuery({
    //     query: props.query || query,
    //     variables: props.filters,
    // });

    // if (loading) {
    //     return <Loader loading />;
    // }

    // const _renderCommentBox = sessions => {
    //     return sessions.map((session, i) => {
    //         return <Session key={i} session={session} colors={colors} />;
    //     });
    // };

    return (
        <div>
            <div className={style.wrapper} style={colors ? { borderColor: colors.primary } : {}}>
                <div
                    className={style.header}
                    style={colors ? { backgroundColor: colors.primary } : {}}
                >
                    <div>
                        <IconComment spaceRight />
                        {title}
                    </div>
                    <button
                        type="button"
                        className={`btn btn-sm ${style.menuButton}`}
                        onClick={() => setBoxOpen(!boxOpen)}
                        style={colors ? { backgroundColor: Color(colors.primary).darken(0.2) } : {}}
                    >
                        {boxOpen ? <IconMinus /> : <IconPlus />}
                    </button>
                </div>
                {boxOpen ? 'comments go here' : null}
            </div>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    data: PropTypes.array,
    isPreview: PropTypes.bool,
    query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    colors: PropTypes.object,
    convertDataStructure: PropTypes.func,
    filters: PropTypes.object,
};

Component.defaultProps = {
    convertDataStructure: data => {
        const groups = (data && data.groups.classList) || [];
        const group = (groups.length > 0 && groups[0].childRoles.metricspawn) || {};
        const sessions = (group && group.assignmentProgressList) || [];

        return sessions.sort((a, b) => a.deployment.timepoint - b.deployment.timepoint);
    },
};

export default Component;
