import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import Chart from './Chart';
import cardQuery from '../../cardQuery.graphql';
import summaryChartQuery from './summaryChart.graphql';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const grpL4 = Appdefs.app.tripleP.grpL4;

const SummaryChart = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    const { title, description, show, colors } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;

    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;

    const selectedIds = groupIds ? groupIds.map(Number) : previewData.groupIds;

    const assignmentId = grpL4.assignmentId;

    useEffect(() => {
        setIsLoading(true);
        getCards().then(async response => {
            const allFids = response.data.flows.assignmentProgressList;
            const filtered2s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_2')
            );
            const sess2deploymentIds = filtered2s.map(session => {
                return [session.deployment.deploymentId];
            });

            const filtered8s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_8')
            );
            const sess8deploymentIds = filtered8s.map(session => {
                return [session.deployment.deploymentId];
            });

            getLevelData({
                session2s: sess2deploymentIds.length > 0 ? sess2deploymentIds.flat() : 0,
                session8s: sess8deploymentIds.length > 0 ? sess8deploymentIds.flat() : 0,
            }).then(async res => {
                const results = res.data.groups;
                const rowsRendered = results.triplep_siteList.map(site => {
                    return {
                        id: site.group.groupId,
                        sdo: site.label,
                        'Number of Clients Finishing Required Content':
                            site.childRoles.required.toString(),
                        'Number of Clients Completing Service':
                            site.childRoles.completed.toString(),
                    };
                });

                setData(rowsRendered);
                setIsLoading(false);
            });
        });
    }, [groupIds, reportingFrom, reportingTo]);

    const getCards = async () => {
        const response = await apollo.query({
            query: cardQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                assignmentId,
                reportingFrom,
                reportingTo,
            },
        });
        return response;
    };

    const getLevelData = async ({ session2s, session8s }) => {
        const response = await apollo.query({
            query: summaryChartQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                assignmentId,
                session2s,
                session8s,
            },
        });
        return response;
    };

    return (
        <Loader loading={isLoading} removeChildren>
            {show && (
                <div className={style.wrapper}>
                    <div className={style.title}>
                        <strong
                            style={
                                colors
                                    ? {
                                          color: colors.primary,
                                          fontSize: '1.5em',
                                      }
                                    : { fontSize: '1.5em' }
                            }
                        >
                            {level ? level : 'Level 4 Group'} {title}
                        </strong>
                        <div>{description}</div>
                    </div>

                    <div style={{ height: '500px', width: '100%' }}>
                        <Chart data={data} />
                    </div>
                </div>
            )}
        </Loader>
    );
};

SummaryChart.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

SummaryChart.defaultProps = {
    filters: {},
};

export default SummaryChart;
