import GrpL2ProgramReach from './triplep/GrpL2ProgramReach';
import GrpL3ProgramReach from './triplep/GrpL3ProgramReach';
import IndL3ProgramReach from './triplep/IndL3ProgramReach';
import IndL4ProgramReach from './triplep/IndL4ProgramReach';
import GrpL4ProgramReach from './triplep/GrpL4ProgramReach';
import ParentSatisfaction from './triplep/ParentSatisfaction';
import ParentDemographics from './triplep/ParentDemographics';
import SiteCoorSessionData from './sfp/SiteCoorSessionData';
import SiteCycleSelect from './sfp/SFPCycleSelect';

export default [
    GrpL2ProgramReach,
    GrpL3ProgramReach,
    IndL3ProgramReach,
    IndL4ProgramReach,
    GrpL4ProgramReach,
    ParentSatisfaction,
    ParentDemographics,
    SiteCoorSessionData,
    SiteCycleSelect,
];
