import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'grpL2ProgramReach',
    label: 'Level 2 Seminar Program Reach',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
