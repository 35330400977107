import React from 'react';
import Loader from 'cccisd-loader';
import useQuery from '../../../useQuery.js';
import getSitesQuery from './getSites.graphql';
import Player from './player.js';

const Component = props => {
    const [data, loading] = useQuery({ query: getSitesQuery });
    return loading ? <Loader loading /> : <Player {...props} data={data} />;
};

export default Component;
