import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import Pie from './Pie';
import style from './style.css';

import feedbackIdsQuery from '../../feedbackIds.graphql';
import satisfactionTableQuery from '../../satisfactionTable.graphql';
import siteNamesQuery from '../../siteNames.graphql';
import cardLevelSatisfactionTableQuery from '../../cardLevelSatisfactionTable.graphql';
import grpL4FeedbackIdsQuery from '../../grpL4feedbackIds.graphql';
import grpL4satisfactionTableQuery from '../../grpL4satisfactionTable.graphql';

import IconCircle from 'cccisd-icons/circle2';

const Appdefs = window.cccisd.appDefs;
const grpL2assignmentId = Appdefs.app.tripleP.grpL2assignmentId;
const grpL3assignmentId = Appdefs.app.tripleP.grpL3assignmentId;
const grpL4 = Appdefs.app.tripleP.grpL4;

const ParentSatisfactionChart = props => {
    const [knowledgeData, setKnowledgeData] = useState([]);
    const [engagingData, setEngagingData] = useState([]);
    const [adviceData, setAdviceData] = useState([]);
    const [servicesData, setServicesData] = useState([]);
    const [comfortData, setComfortData] = useState([]);
    const [recommendData, setRecommendData] = useState([]);
    const [recommendCommunityData, setRecommendCommunityData] = useState([]);
    const [needsData, setNeedsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const {
        colors,
        knowledgeShow,
        knowledgeTitle,
        knowledgeDescription,
        engagementShow,
        engagementTitle,
        engagementDescription,
        adviceShow,
        adviceTitle,
        adviceDescription,
        servicesShow,
        servicesTitle,
        servicesDescription,
        comfortShow,
        comfortTitle,
        comfortDescription,
        recommendShow,
        recommendTitle,
        recommendDescription,
        recommendCommunityShow,
        recommendCommunityTitle,
        recommendCommunityDescription,
        needsShow,
        needsTitle,
        needsDescription,
    } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? groupIds.map(Number) : previewData.groupIds;

    useEffect(() => {
        setIsLoading(true);

        if (level === 'Level 2 Seminar' || level === 'Level 3 Discussion') {
            getFeedbackIds({
                assignmentId: getAssignmentId(level),
                sessionDate: getLevelString(level),
            }).then(async response => {
                const allSeminars = response.data.flows.assignmentProgressList;
                const feedbackId = allSeminars.map(sem => {
                    return [sem.additionalInfo.feedbackDeploymentId];
                });

                getChart({ feedbackIds: feedbackId.length > 0 ? feedbackId.flat() : 0 }).then(
                    async res => {
                        const results = res.data.flows;
                        await renderCharts(results);
                        await setIsLoading(false);
                    }
                );
            });
        }

        if (level === 'Level 3 Primary Care' || level === 'Level 4 Standard') {
            getSiteNames().then(async response => {
                const indL3 = response.data.flows.indL3;
                const indL4 = response.data.flows.indL4;
                const siteList = response.data.groups.triplep_siteList;
                const sites = siteList.map(site => {
                    return [site.group.label];
                });

                getCardLevelTable({
                    feedbackIds:
                        level === 'Level 3 Primary Care'
                            ? indL3.assignment.deployment.deploymentId
                            : indL4.assignment.deployment.deploymentId,
                    assignmentId:
                        level === 'Level 3 Primary Care'
                            ? indL3.assignment.assignmentId
                            : indL4.assignment.assignmentId,
                    siteNames: sites.flat(),
                    siteLevelString: getLevelString(level),
                }).then(async res => {
                    const results = res.data.flows;
                    await renderCharts(results);
                    await setIsLoading(false);
                });
            });
        }

        if (level === 'Level 4 Group') {
            grpL4FeedbackIds({
                assignmentId: getAssignmentId(level),
            }).then(async response => {
                const allSeminars = response.data.flows.assignmentProgressList;
                let feedbackId = allSeminars.map(sem => {
                    return [sem.additionalInfo.feedbackDeploymentId];
                });

                grpL4Table({
                    feedbackIds: feedbackId.length > 0 ? feedbackId.flat() : 0,
                    assignmentId: getAssignmentId(level),
                }).then(async res => {
                    const results = res.data.flows;
                    await renderCharts(results);
                    await setIsLoading(false);
                });
            });
        }
    }, [groupIds, level, reportingFrom, reportingTo]);

    if (!knowledgeData) {
        return <Loader loading={isLoading} removeChildren />;
    }

    const formatData = (stronglyDisagree, disagree, agree, stronglyAgree) => {
        return [
            {
                id: 0,
                label: 'Strongly Disagree',
                value: stronglyDisagree,
            },
            {
                id: 1,
                label: 'Disagree',
                value: disagree,
            },
            {
                id: 2,
                label: 'Agree',
                value: agree,
            },
            {
                id: 3,
                label: 'Strongly Agree',
                value: stronglyAgree,
            },
        ];
    };

    const renderCharts = data => {
        const knowledgeable = formatData(
            data.knowledgeableStronglyDisagree,
            data.knowledgeableDisagree,
            data.knowledgeableAgree,
            data.knowledgeableStronglyAgree
        );
        setKnowledgeData(knowledgeable);

        const engaging = formatData(
            data.engagingStronglyDisagree,
            data.engagingDisagree,
            data.engagingAgree,
            data.engagingStronglyAgree
        );
        setEngagingData(engaging);

        const useAdvice = formatData(
            data.adviceStronglyDisagree,
            data.adviceDisagree,
            data.adviceAgree,
            data.adviceStronglyAgree
        );
        setAdviceData(useAdvice);

        const service = formatData(
            data.servicesStronglyDisagree,
            data.servicesDisagree,
            data.servicesAgree,
            data.servicesStronglyAgree
        );
        setServicesData(service);

        const comfort = formatData(
            data.comfortStronglyDisagree,
            data.comfortDisagree,
            data.comfortAgree,
            data.comfortStronglyAgree
        );
        setComfortData(comfort);

        const recommend = formatData(
            data.recommendStronglyDisagree,
            data.recommendDisagree,
            data.recommendAgree,
            data.recommendStronglyAgree
        );
        setRecommendData(recommend);

        const recommendCommunity = formatData(
            data.recommendCommunityStronglyDisagree,
            data.recommendCommunityDisagree,
            data.recommendCommunityAgree,
            data.recommendCommunityStronglyAgree
        );
        setRecommendCommunityData(recommendCommunity);

        const needs = formatData(
            data.needsStronglyDisagree,
            data.needsDisagree,
            data.needsAgree,
            data.needsStronglyAgree
        );
        setNeedsData(needs);
    };

    const getAssignmentId = levelFilter => {
        switch (levelFilter) {
            case 'Level 2 Seminar':
                return grpL2assignmentId;
            case 'Level 3 Discussion':
                return grpL3assignmentId;
            case 'Level 4 Group':
                return grpL4.parentFeedbackAssignmentId;
            default:
                return grpL2assignmentId;
        }
    };

    const getLevelString = levelFilter => {
        switch (levelFilter) {
            case 'Level 2 Seminar':
                return 'devTags.L2SeminarDate';
            case 'Level 3 Discussion':
                return 'devTags.L3DiscussionDate';
            case 'Level 3 Primary Care':
                return 'variables.114_app_variable_sdo';
            case 'Level 4 Standard':
                return 'variables.126_app_variable_sdo';
            default:
                return 'devTags.L2SeminarDate';
        }
    };

    const getFeedbackIds = ({ assignmentId, sessionDate }) => {
        const response = apollo.query({
            query: feedbackIdsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                assignmentId,
                reportingFrom,
                reportingTo,
                sessionDate,
            },
        });

        return response;
    };

    const grpL4FeedbackIds = ({ assignmentId }) => {
        const response = apollo.query({
            query: grpL4FeedbackIdsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                assignmentId,
            },
        });

        return response;
    };

    const getSiteNames = () => {
        const response = apollo.query({
            query: siteNamesQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
            },
        });

        return response;
    };

    const getChart = async ({ feedbackIds, assignmentId }) => {
        const response = await apollo.query({
            query: satisfactionTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                feedbackIds,
                assignmentId,
            },
        });
        return response;
    };

    const getCardLevelTable = async ({ feedbackIds, assignmentId, siteLevelString, siteNames }) => {
        const response = await apollo.query({
            query: cardLevelSatisfactionTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                feedbackIds,
                assignmentId,
                reportingFrom,
                reportingTo,
                siteLevelString,
                siteNames,
            },
        });
        return response;
    };

    const grpL4Table = async ({ feedbackIds, assignmentId }) => {
        const response = await apollo.query({
            query: grpL4satisfactionTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                feedbackIds,
                assignmentId,
                reportingFrom,
                reportingTo,
            },
        });
        return response;
    };

    return (
        <Loader loading={isLoading} removeChildren>
            <div>
                <div className={style.wrapper}>
                    <div className={style.row}>
                        {knowledgeShow && (
                            <div className={style.chart}>
                                <Pie
                                    data={knowledgeData}
                                    title={knowledgeTitle}
                                    colors={colors}
                                    description={knowledgeDescription}
                                />
                            </div>
                        )}
                        {engagementShow && (
                            <div className={style.chart}>
                                <Pie
                                    data={engagingData}
                                    title={engagementTitle}
                                    colors={colors}
                                    description={engagementDescription}
                                />
                            </div>
                        )}
                    </div>
                    <div className={style.row}>
                        {adviceShow && (
                            <div className={style.chart}>
                                <Pie
                                    data={adviceData}
                                    title={adviceTitle}
                                    colors={colors}
                                    description={adviceDescription}
                                />
                            </div>
                        )}

                        {servicesShow && (
                            <div className={style.chart}>
                                <Pie
                                    data={servicesData}
                                    title={servicesTitle}
                                    colors={colors}
                                    description={servicesDescription}
                                />
                            </div>
                        )}
                    </div>
                    <div className={style.row}>
                        {comfortShow && (
                            <div className={style.chart}>
                                <Pie
                                    data={comfortData}
                                    title={comfortTitle}
                                    colors={colors}
                                    description={comfortDescription}
                                />
                            </div>
                        )}
                        {recommendShow && (
                            <div className={style.chart}>
                                <Pie
                                    data={recommendData}
                                    title={recommendTitle}
                                    colors={colors}
                                    description={recommendDescription}
                                />
                            </div>
                        )}
                    </div>
                    <div className={style.row}>
                        {recommendCommunityShow && (
                            <div className={style.chart}>
                                <Pie
                                    data={recommendCommunityData}
                                    title={recommendCommunityTitle}
                                    colors={colors}
                                    description={recommendCommunityDescription}
                                />
                            </div>
                        )}
                        {needsShow && (
                            <div className={style.chart}>
                                <Pie
                                    data={needsData}
                                    title={needsTitle}
                                    colors={colors}
                                    description={needsDescription}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={style.legend}>
                    <ul
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            listStyleType: 'none',
                        }}
                    >
                        <li className={style.listItem}>
                            <span style={{ color: '#D7DF23' }}>
                                <IconCircle spaceRight />
                            </span>
                            Strongly Disagree
                        </li>
                        <li className={style.listItem}>
                            <span style={{ color: '#F37021' }}>
                                <IconCircle spaceRight />
                            </span>
                            Disagree
                        </li>
                        <li className={style.listItem}>
                            <span style={{ color: '#00ADDC' }}>
                                <IconCircle spaceRight />
                            </span>
                            Agree
                        </li>
                        <li>
                            <span style={{ color: '#C40075' }}>
                                <IconCircle spaceRight />
                            </span>
                            Strongly Agree
                        </li>
                    </ul>
                </div>
            </div>
        </Loader>
    );
};

ParentSatisfactionChart.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

ParentSatisfactionChart.defaultProps = {
    filters: {},
};

export default ParentSatisfactionChart;
