import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    caregivers: {
        show: true,
        title: 'Total Caregivers',
        description: '',
    },
    totalChildren: {
        show: true,
        title: 'Total Children',
        description: '',
    },
    children0to5: {
        show: true,
        title: 'Children Ages 0-5',
        description: '',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Callouts?" />
            <h4>Callout 1</h4>
            <Field name="caregivers.show" component={CccisdToggle} label="Show Callout 1?" />
            <Field name="caregivers.title" component={CccisdInput} label="Title" />
            <Field name="caregivers.description" component={CccisdInput} label="Description" />
            <h4>Callout 2</h4>
            <Field name="children0to5.show" component={CccisdToggle} label="Show Callout 2?" />
            <Field name="children0to5.title" component={CccisdInput} label="Title" />
            <Field name="children0to5.description" component={CccisdInput} label="Description" />
            <h4>Callout 3</h4>
            <Field name="totalChildren.show" component={CccisdToggle} label="Show Callout 3?" />
            <Field name="totalChildren.title" component={CccisdInput} label="Title" />
            <Field name="totalChildren.description" component={CccisdInput} label="Description" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
