import React from 'react';
import { Field, CccisdDatepicker, CccisdFieldWrapper, CccisdSelect } from 'cccisd-formik';
import SelectGroups from '../../components/triplep/SelectGroups';
import format from 'date-fns/format';

let today = new Date();
let oneYearAgo = today.setFullYear(today.getFullYear() - 1);

const views = [
    {
        value: 'Level 2 Seminar',
        label: 'Level 2 Seminar',
    },
];

export default [
    {
        name: 'groupIds',
        label: 'Select Groups',
        initialValue: [],
        component: props => {
            return (
                <Field name="groupIds">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <SelectGroups
                                field={field}
                                form={form}
                                settings={props.settings}
                                reportHandle={props.reportHandle}
                                userType={props.userType}
                                title="Select SDO"
                                modalHeader={{ backgroundColor: '#f37021', color: '#fff' }}
                                level="fields.grpL2"
                            />
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: true,
    },
    {
        name: 'level',
        label: 'Level',
        initialValue: 'Level 2 Seminar',
        component: () => (
            <Field name="level" component={CccisdSelect} label="Select Level" options={views} />
        ),
    },
    {
        name: 'reportingFrom',
        label: 'Reporting From',
        initialValue: format(oneYearAgo, 'YYYY-MM-DD'),
        component: () => (
            <Field name="reportingFrom" component={CccisdDatepicker} label="Reporting From" />
        ),
    },
    {
        name: 'reportingTo',
        label: 'Reporting To',
        initialValue: format(new Date(), 'YYYY-MM-DD'),
        component: () => (
            <Field name="reportingTo" component={CccisdDatepicker} label="Reporting To" />
        ),
    },
];
