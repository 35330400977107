import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'grpL3ProgramReach',
    label: 'Level 3 Discussion Program Reach',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
