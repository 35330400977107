export const siteData = [
    {
        group: {
            groupId: 0,
            label: 'Site 1',
        },
    },
    {
        group: {
            groupId: 1,
            label: 'Site 2',
        },
    },
    {
        group: {
            groupId: 2,
            label: 'Site 3',
        },
    },
];

export const groupingUnitData = [
    {
        id: 0,
        name: 'Group 1',
        sites: siteData,
    },
    {
        id: 1,
        name: 'Group 2',
        sites: siteData,
    },
    {
        id: 2,
        name: 'Group 3',
        sites: siteData,
    },
    {
        id: 3,
        name: 'Group 4',
        sites: siteData,
    },
    {
        id: 4,
        name: 'Group 5',
        sites: siteData,
    },
    {
        id: 5,
        name: 'Group 6',
        sites: siteData,
    },
    {
        id: 6,
        name: 'Group 7',
        sites: siteData,
    },
];

export const useradminData = [
    {
        id: 100,
        name: 'Org 1',
        groupingUnits: groupingUnitData,
    },
    {
        id: 110,
        name: 'Org 2',
        groupingUnits: [
            {
                id: 10,
                name: 'Group 10',
            },
            {
                id: 11,
                name: 'Group 11',
            },
        ],
    },
];
