import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'summaryChart',
    label: 'Summary Chart',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
