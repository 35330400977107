import React, { useState } from 'react';
import { Formik, Field, CccisdInput, CccisdDatepicker } from 'cccisd-formik';
import { UserCheck } from 'cccisd-laravel-nexus';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import Loader from 'cccisd-loader';

import rosterParentQuery from './rosterParent.graphql';

import RequiredFieldLabel from '../../../../RequiredFieldLabel';
import style from '../style.css';
import ExistingClient from './ExistingClient';
import AddClientForm from './AddClientForm';

import IconNotification from 'cccisd-icons/notification';
import IconQuestion from 'cccisd-icons/question3';
import IconSearch from 'cccisd-icons/search';

const Boilerplate = window.cccisd.boilerplate;
const childrensTrust = "Children's Trust";

const CTCheck = ({ familyGroupId, closeModal, familyName }) => {
    const [ctStatus, setCTStatus] = useState(null);
    const [existingUsername, setExistingUsername] = useState(null);
    const [banner, setBanner] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const userCheck = async values => {
        const checkUserDelay = 1000;

        if (!familyGroupId || !values.username) {
            return;
        }

        let checkUserData = {
            username: values.username,
            group: familyGroupId,
            role: 'sfp_client',
        };

        let userCheckResponse = await UserCheck(checkUserData, checkUserDelay);

        if (userCheckResponse.available === true) {
            values.available = true;
            setBanner(availableUser);

            if (userCheckResponse.user) {
                values.available = false;
                values.exisitingUser = true;
                setBanner(existingUser);

                values.firstName = userCheckResponse.user.first_name;
                values.lastName = userCheckResponse.user.last_name;
                setExistingUsername(userCheckResponse.user.username);
            }
        } else {
            values.available = false;

            values.exisitingUserSameFamily = true;
            setBanner(exisitingUserSameFamily);
        }
    };

    const ctCheck = async values => {
        setIsLoading(true);
        const firstName = values.firstName.trim();
        const lastName = values.lastName.trim();

        const result = await apollo.query({
            query: rosterParentQuery,
            fetchPolicy: 'network-only',
            variables: {
                first: firstName.substring(0, 2),
                last: lastName.slice(-2),
                DOB: values.DOB ? values.DOB : null,
            },
        });

        const sfpParentList = await result.data.roles.sfp_clientList;
        const triplepParentList = await result.data.roles.triplep_parentList;
        const mcvParentList = await result.data.roles.clientList;

        const newToCT = () => {
            return (
                <>
                    {existingUsername === null ? newCaregiverInCT : null}
                    <AddClientForm
                        onSubmit={onAddClient}
                        initialValues={{
                            username: values.username,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            DOB: values.DOB,
                            existingUser: existingUsername,
                        }}
                    />
                </>
            );
        };

        const existingCT = () => {
            return (
                <ExistingClient
                    mcv={mcvParentList}
                    tripleP={triplepParentList}
                    sfp={sfpParentList}
                    formValues={values}
                    familyGroupId={familyGroupId}
                    closeModal={closeModal}
                />
            );
        };

        if (
            sfpParentList.length === 0 &&
            triplepParentList.length === 0 &&
            mcvParentList.length === 0
        ) {
            setCTStatus(newToCT);
            setIsLoading(false);
        }

        if (sfpParentList.length > 0 || mcvParentList.length > 0 || triplepParentList.length > 0) {
            setCTStatus(existingCT);
            setIsLoading(false);
        }
    };

    const onAddClient = async (values, parent) => {
        const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
            role: 'sfp_client',
            activeInFamily: true,

            // if user already exists, use that username
            username: values.existingUser ? null : values.username,
            user: values.existingUser ? values.username : null,

            first_name: values.firstName,
            last_name: values.lastName,
            group: familyGroupId,
            DOB: values.DOB,
            lastFour: values.lastFour,
            phone: values.phone,
            email: values.username,
            relationshipToChildren: values.relationshipToChildren,
            otherRelationship: values.otherRelationship,
            pawnId: parent && parent ? parent.pawn.pawnId : null,
            pawnHash: parent && parent ? parent.pawn.pawnHash : null,
            sameCTID: values.sameCTID,
            familyName,
            race: values.race,
            ethnicity: values.ethnicity,
            gender: values.gender,
            genderOther: values.genderOther,
        });

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }

        closeModal();

        notification('Caregiver added.');
    };

    const newCaregiverInCT = (
        <div className="alert alert-info" role="alert" style={{ marginTop: '1em' }}>
            This parent is new to {childrensTrust}.
        </div>
    );

    const availableUser = (
        <div className={`alert alert-success ${style.usernameValid}`} role="alert">
            Username available.
        </div>
    );

    const existingUser = (
        <div className="alert alert-warning" role="alert">
            <IconQuestion spaceRight />
            User already exists in a different SFP family - filling in known values.
        </div>
    );

    const exisitingUserSameFamily = (
        <div className={`alert alert-danger ${style.usernameValid}`} role="alert">
            <IconNotification spaceRight />
            User already exists in this Family.
        </div>
    );

    const validate = values => {
        const errors = {};

        if (values.exisitingUserSameFamily === true) {
            errors.username = 'User already exists in this Family.';
        }
        if (!values.username) {
            errors.username = 'Username is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
            errors.username = 'Invalid email address';
        }
        if (!values.firstName) {
            errors.firstName = 'First Name is required.';
        }
        if (!values.lastName) {
            errors.lastName = 'Last Name is required.';
        }
        if (!values.DOB) {
            errors.DOB = 'Date of Birth is required.';
        }

        return errors;
    };

    return (
        <Formik onSubmit={ctCheck} validate={validate}>
            {({ values, errors, handleSubmit }) => (
                <>
                    {ctStatus === null ? (
                        <>
                            <Field
                                name="username"
                                component={CccisdInput}
                                label={
                                    <RequiredFieldLabel isRequired>Username:</RequiredFieldLabel>
                                }
                                validateOnBlur={userCheck(values, errors)}
                            />
                            {banner}
                            <div className="row">
                                <div className="col-xs-4">
                                    <Field
                                        name="firstName"
                                        component={CccisdInput}
                                        label={
                                            <RequiredFieldLabel isRequired>
                                                First Name:
                                            </RequiredFieldLabel>
                                        }
                                        disabled={existingUsername !== null}
                                    />
                                </div>
                                <div className="col-xs-4">
                                    <Field
                                        name="lastName"
                                        component={CccisdInput}
                                        label={
                                            <RequiredFieldLabel isRequired>
                                                Last Name:
                                            </RequiredFieldLabel>
                                        }
                                        disabled={existingUsername !== null}
                                    />
                                </div>
                                <div className="col-xs-4">
                                    <Field
                                        name="DOB"
                                        component={CccisdDatepicker}
                                        label={
                                            <RequiredFieldLabel isRequired>
                                                Date of Birth:
                                            </RequiredFieldLabel>
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button
                                    type="button"
                                    className={`${
                                        values.exisitingUserSameFamily === true
                                            ? `btn btn-primary disabled`
                                            : `btn btn-primary`
                                    }`}
                                    onClick={handleSubmit}
                                >
                                    <Loader type="inline" loading={isLoading}>
                                        <IconSearch spaceRight />
                                        Search for Caregiver
                                    </Loader>
                                </button>
                            </div>
                        </>
                    ) : null}
                    {ctStatus}
                </>
            )}
        </Formik>
    );
};

CTCheck.propTypes = {
    familyGroupId: PropTypes.number,
    closeModal: PropTypes.func,
    familyName: PropTypes.string,
};

export default CTCheck;
