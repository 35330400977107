import React from 'react';
import Tooltip from 'cccisd-tooltip';

export default settings => {
    const renderRates = (role, rowPath) => {
        if (rowPath && rowPath === 'N/A') {
            return (
                <div>
                    <Tooltip title="There is not enough homework or attendance data submitted in Session Data to calculate percentages">
                        <i>{role}</i> Incomplete
                    </Tooltip>
                </div>
            );
        }
        if (rowPath && rowPath !== 'N/A') {
            return (
                <div>
                    <i>{role} </i>
                    {`${Math.round(rowPath).toFixed(0)}%`}
                </div>
            );
        }
    };

    return ({ row }) => {
        return (
            <>
                {settings.hw === true ? (
                    <>
                        {renderRates('Caregivers', row['fields.caregiverGroupHomeworkRate'])}
                        {renderRates('Youth', row['fields.youthGroupHomeworkRate'])}
                    </>
                ) : (
                    <>
                        {renderRates('Caregivers', row['fields.caregiverGroupAttendanceRate'])}
                        {renderRates('Youth', row['fields.youthGroupAttendanceRate'])}
                    </>
                )}
            </>
        );
    };
};
