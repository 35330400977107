import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';

import Callout from './Callout';
import style from './style.css';
import calloutQuery from './callout.graphql';
import _flatten from 'lodash/flatten';

const Appdefs = window.cccisd.appDefs;
const indL3 = Appdefs.app.tripleP.indL3;

const Component = props => {
    const [caregivers, setCaregivers] = useState(null);
    const [children, setChildren] = useState(null);
    const [children0to5, setChildren0to5] = useState(null);

    const { show } = props.settings;

    const fakeData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;

    const { groupIds, reportingFrom, reportingTo } = props.filters;
    const selectedIds = groupIds ? groupIds.map(Number) : fakeData.groupIds;
    const assignmentId = indL3.assignmentId;
    const sessionDate = 'devTags.L3PrimaryCareSessionDate';

    useEffect(() => {
        getLevelData(selectedIds, assignmentId, reportingFrom, reportingTo, sessionDate).then(
            async response => {
                let finishedRequired = response.data.flows.required.eachParent;

                const calloutsData = props.convertDataStructure(selectedIds, finishedRequired);
                setCaregivers(calloutsData.caregivers);
                setChildren(calloutsData.children);
                setChildren0to5(calloutsData.children0to5);
            }
        );
    }, [selectedIds, assignmentId, reportingFrom, reportingTo]);

    const getLevelData = ids => {
        return apollo.query({
            query: calloutQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: ids,
                assignmentId,
                reportingFrom,
                reportingTo,
                sessionDate,
            },
        });
    };
    return (
        <>
            {show && (
                <div className={style.callouts}>
                    <>
                        <Callout
                            handle="caregivers"
                            settings={{
                                ...props.settings.caregivers,
                                colors: props.settings.colors,
                            }}
                            data={caregivers}
                        />
                        <Callout
                            handle="children"
                            settings={{
                                ...props.settings.totalChildren,
                                colors: props.settings.colors,
                            }}
                            data={children}
                        />
                        <Callout
                            handle="children0to5"
                            settings={{
                                ...props.settings.children0to5,
                                colors: props.settings.colors,
                            }}
                            data={children0to5 === null ? 'No Data' : children0to5}
                        />
                    </>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: (groupId, results) => {
        if (!groupId || !results || results === null) {
            return {
                caregivers: 'No Data',
                children: 'No Data',
                children0to5: 'No Data',
            };
        }

        const children = results.map(parent => {
            return [Number(parent.children[0].value)];
        });
        const flatten = _flatten(children);
        const totalChildren = flatten.reduce((a, b) => a + b, 0);

        const children0to5 = results.map(parent => {
            return [Number(parent.children0to5[0].value)];
        });
        const flatten0to5 = _flatten(children0to5);
        const totalChildren0to5 = flatten0to5.reduce((a, b) => a + b, 0);

        return {
            caregivers: results.length,
            children: totalChildren,
            children0to5: totalChildren0to5,
        };
    },
};

export default Component;
