import React from 'react';
import { Link } from 'react-router-dom';
import IconArrow from 'cccisd-icons/undo2';

export default class NewClientForm extends React.Component {
    render() {
        return (
            <Link to="/clientcard">
                <button type="button" className="btn btn-primary">
                    <span>
                        <IconArrow spaceRight />
                        Go to Client Dashboard
                    </span>
                </button>
            </Link>
        );
    }
}
