import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import _map from 'lodash/map';

import languageTableQuery from './languageTable.graphql';

import style from '../../style.css';
import { initialLoadMessage } from '../../../../../../reportHelpers.js';
import TableAndChart from '../..';

const Language = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [chartData, setChartData] = useState([]);

    const { description, show, colors, title } = props.settings;

    const { groupIds, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? Number(groupIds) : previewData.groupIds;

    useEffect(() => {
        setIsLoading(true);

        getLanguageTable().then(async response => {
            const results = response.data.groups.triplep_site.childRoles;
            setTableData(results);

            const rowsRendered = _map(results, (value, key) => {
                return {
                    id: key,
                    label: getLabel(key),
                    value,
                };
            });
            const cleanUpQuery = rowsRendered.filter(row => row.id !== '__typename');
            setChartData(cleanUpQuery);

            setIsLoading(false);
        });
    }, [selectedIds, reportingFrom, reportingTo]);

    const getLanguageTable = async () => {
        const response = await apollo.query({
            query: languageTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                reportingFrom,
                reportingTo,
                deploymentId: 90,
            },
        });
        return response;
    };

    const columns = [
        {
            name: 'language',
            label: 'Caregiver Primary Language',
        },
        {
            name: 'total',
            label: 'Total',
            class: 'text-center',
            setStyle: () => {
                return { width: '25%' };
            },
        },
    ];

    const rows = [
        {
            id: 0,
            language: 'English',
            total: tableData.English,
        },
        {
            id: 1,
            language: 'Spanish',
            total: tableData.Spanish,
        },
        {
            id: 2,
            language: 'Not Listed/Other',
            total: tableData.Other,
        },
    ];

    const getLabel = language => {
        if (language === 'Other') {
            return 'Not Listed/Other';
        }
    };

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <strong
                        style={{
                            borderBottom: colors ? `1px solid ${colors.primary}` : null,
                            color: colors ? colors.primary : null,
                        }}
                    >
                        {title}
                    </strong>
                    <div className={style.description}>{description}</div>
                    {selectedIds === 0 ? (
                        initialLoadMessage
                    ) : (
                        <Loader loading={isLoading} removeChildren>
                            <TableAndChart
                                columns={columns}
                                rows={rows}
                                tableName="Primary Language"
                                chartData={chartData}
                            />
                        </Loader>
                    )}
                </div>
            )}
        </>
    );
};

Language.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Language.defaultProps = {
    filters: {},
};

export default Language;
