import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'sfpCycleSelect',
    label: 'SFP Cycle Select',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
