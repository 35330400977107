import React from 'react';
import Modal from 'cccisd-modal';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import PropTypes from 'prop-types';

import FamilyForm from '../../../../components/sfp/Manage/FamilyForm';

import IconPlus from 'cccisd-icons/plus-circle2';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default class AddFamilyForm extends React.Component {
    static propTypes = {
        loadData: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.modal = React.createRef();
        this.state = {
            familyGroupId: null,
        };
    }

    onSubmit = async values => {
        const response = await axios.post(Boilerplate.route('api.nexus.group.store'), {
            type: 'sfp_family',
            familyStatus: 'Active',
            label: values.label,
            enrollmentDate: values.enrollmentDate,
            zipCode: values.zipCode,
            county: values.county,
            referralSource: values.referralSource,
            referralName: values.referralName,
            referralCounty: values.referralCounty,
            parent: values.group,
            statusChangeDate: values.statusChangeDate,
        });

        this.setState({ familyGroupId: response.data.data.id });

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }

        await this.props.loadData();

        notification('Family added.');
    };

    render() {
        return (
            <Modal
                ref={this.modal}
                trigger={
                    <button type="button" className="btn btn-primary">
                        <span>
                            <IconPlus spaceRight />
                            Add Family
                        </span>
                    </button>
                }
                title="Add New Family"
                size="large"
            >
                <FamilyForm
                    operation="create"
                    onSubmit={this.onSubmit}
                    initialValues={{
                        familyStatus: 'Active',
                        familyGroupId: this.state.familyGroupId,
                    }}
                />
            </Modal>
        );
    }
}
