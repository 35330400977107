import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import Chart from './Chart';
import cardQuery from '../../cardQuery.graphql';
import detailChartQuery from './detailChart.graphql';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const grpL4 = Appdefs.app.tripleP.grpL4;

const DetailChart = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    const { title, description, show, colors } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;

    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;

    const selectedIds = groupIds ? groupIds.map(Number) : previewData.groupIds;

    const assignmentId = grpL4.assignmentId;

    useEffect(() => {
        setIsLoading(true);
        getCards().then(async response => {
            const allFids = response.data.flows.assignmentProgressList;
            const filtered1s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_1')
            );
            const sess1deploymentIds = filtered1s.map(session => {
                return [session.deployment.deploymentId];
            });

            const filtered2s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_2')
            );
            const sess2deploymentIds = filtered2s.map(session => {
                return [session.deployment.deploymentId];
            });

            const filtered3s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_3')
            );
            const sess3deploymentIds = filtered3s.map(session => {
                return [session.deployment.deploymentId];
            });

            const filtered4s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_4')
            );
            const sess4deploymentIds = filtered4s.map(session => {
                return [session.deployment.deploymentId];
            });

            const filtered5s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_5')
            );
            const sess5deploymentIds = filtered5s.map(session => {
                return [session.deployment.deploymentId];
            });

            const filtered6s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_6')
            );
            const sess6deploymentIds = filtered6s.map(session => {
                return [session.deployment.deploymentId];
            });

            const filtered7s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_7')
            );
            const sess7deploymentIds = filtered7s.map(session => {
                return [session.deployment.deploymentId];
            });

            const filtered8s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_8')
            );
            const sess8deploymentIds = filtered8s.map(session => {
                return [session.deployment.deploymentId];
            });

            getLevelData({
                session1s: sess1deploymentIds.length > 0 ? sess1deploymentIds.flat() : 0,
                session2s: sess2deploymentIds.length > 0 ? sess2deploymentIds.flat() : 0,
                session3s: sess3deploymentIds.length > 0 ? sess3deploymentIds.flat() : 0,
                session4s: sess4deploymentIds.length > 0 ? sess4deploymentIds.flat() : 0,
                session5s: sess5deploymentIds.length > 0 ? sess5deploymentIds.flat() : 0,
                session6s: sess6deploymentIds.length > 0 ? sess6deploymentIds.flat() : 0,
                session7s: sess7deploymentIds.length > 0 ? sess7deploymentIds.flat() : 0,
                session8s: sess8deploymentIds.length > 0 ? sess8deploymentIds.flat() : 0,
            }).then(async res => {
                const results = res.data.groups;
                const rowsRendered = results.triplep_siteList.map(site => {
                    return {
                        id: site.group.groupId,
                        sdo: site.label,
                        S1: site.childRoles.session1.toString(),
                        S2: site.childRoles.session2.toString(),
                        S3: site.childRoles.session3.toString(),
                        S4: site.childRoles.session4.toString(),
                        S5: site.childRoles.session5.toString(),
                        S6: site.childRoles.session6.toString(),
                        S7: site.childRoles.session7.toString(),
                        S8: site.childRoles.session8.toString(),
                    };
                });

                setData(rowsRendered);
                setIsLoading(false);
            });
        });
    }, [groupIds, reportingFrom, reportingTo]);

    const getCards = async () => {
        const response = await apollo.query({
            query: cardQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                assignmentId,
                reportingFrom,
                reportingTo,
            },
        });
        return response;
    };

    const getLevelData = async ({
        session1s,
        session2s,
        session3s,
        session4s,
        session5s,
        session6s,
        session7s,
        session8s,
    }) => {
        const response = await apollo.query({
            query: detailChartQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                assignmentId,
                session1s,
                session2s,
                session3s,
                session4s,
                session5s,
                session6s,
                session7s,
                session8s,
            },
        });
        return response;
    };

    return (
        <Loader loading={isLoading} removeChildren>
            {show && (
                <div className={style.wrapper}>
                    <div className={style.title}>
                        <strong
                            style={
                                colors
                                    ? {
                                          color: colors.primary,
                                          fontSize: '1.5em',
                                      }
                                    : { fontSize: '1.5em' }
                            }
                        >
                            {level ? level : 'Level 4 Group'} {title}
                        </strong>
                        <div>{description}</div>
                    </div>

                    <div style={{ height: '800px', width: '100%' }}>
                        <Chart data={data} />
                    </div>
                </div>
            )}
        </Loader>
    );
};

DetailChart.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

DetailChart.defaultProps = {
    filters: {},
};

export default DetailChart;
