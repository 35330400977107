/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
var Fortress = window.cccisd.fortress;

export default class Component extends React.Component {
    render() {
        return (
            <div className="row" style={{ paddingTop: '1.5em' }}>
                <div className="col-md-8">
                    <h2>Welcome to Children's Trust of South Carolina</h2>
                    <p>
                        Children's Trust of South Carolina is the only statewide organization committed to preventing
                        child abuse, neglect and injury. We provide funding, resources and training to help local
                        program partners build strong families and positive childhoods.
                    </p>
                    <p>
                        Children’s Trust is the South Carolina lead agency for the Maternal, Infant and Early Childhood
                        Home Visiting (MIECHV) program. Children’s Trust leads the state’s efforts in coordinating the
                        delivery of voluntary home visiting services through MIECHV funding, to improve the health,
                        development, early learning, child abuse and neglect prevention and family support services for
                        children and families through home visiting programs.
                    </p>
                    <p>
                        Sign in to get started! If you need technical assistance, please contact 3C Institute at{' '}
                        <a href="mailto:support@3cisd.com">support@3cisd.com</a>.
                    </p>
                </div>
                <div className="col-md-4">{!Fortress.auth() && <LoginForm />}</div>
            </div>
        );
    }
}
