import React from 'react';
import Tooltip from 'cccisd-tooltip';
import _get from 'lodash/get';

import IconBlocked from 'cccisd-icons/blocked';
import IconBubbleBlocked from 'cccisd-icons/bubble-blocked';

export default settings => {
    return ({ row }) => {
        const byModel = _get(row, 'childRoles.clientSummary.byModel');

        const showTotals = () => {
            if (
                byModel &&
                byModel.length > 0 &&
                settings.dropdown === 'alabamaParenting' &&
                byModel[0].positiveParenting_before !== null
            ) {
                const grandTotals = byModel.map(model => {
                    return (
                        <div key={model.value}>
                            <i>{model.value}</i> <strong>{model.valueCount}</strong>
                        </div>
                    );
                });

                return grandTotals;
            }

            if (
                byModel &&
                byModel.length > 0 &&
                settings.dropdown === 'alabamaParenting' &&
                byModel[0].positiveParenting_before === null
            ) {
                return (
                    <Tooltip title="No Alabama Parenting Questionnaires complete at this site, for any model.">
                        <IconBubbleBlocked />
                    </Tooltip>
                );
            }

            if (byModel && byModel.length > 0 && settings.dropdown === 'protectiveFactors') {
                const grandTotals = byModel.map(model => {
                    return (
                        <div key={model.value}>
                            <i>{model.value}</i> <strong>{model.valueCount}</strong>
                        </div>
                    );
                });

                return grandTotals;
            }
            return (
                <Tooltip title="No Outcomes Surveys are complete at this site, for any model.">
                    <IconBlocked />
                </Tooltip>
            );
        };

        return <div style={{ textAlign: 'center' }}>{showTotals()}</div>;
    };
};
