import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    services: {
        show: true,
        title: 'Total Services Provided',
        description: '',
    },
    caregivers: {
        show: true,
        title: 'Total Caregivers Served',
        description: '',
    },
    children: {
        show: true,
        title: 'Total Children Impacted',
        description: '',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Callouts?" />
            <Field name="services.show" component={CccisdToggle} label="Show Services Callout?" />
            <Field name="services.title" component={CccisdInput} label="Title" />
            <Field name="services.description" component={CccisdInput} label="Description" />
            <hr />
            <Field
                name="caregivers.show"
                component={CccisdToggle}
                label="Show Caregivers Callout?"
            />
            <Field name="caregivers.title" component={CccisdInput} label="Title" />
            <Field name="caregivers.description" component={CccisdInput} label="Description" />{' '}
            <hr />
            <Field name="children.show" component={CccisdToggle} label="Show Children Callout?" />
            <Field name="children.title" component={CccisdInput} label="Title" />
            <Field name="children.description" component={CccisdInput} label="Description" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
