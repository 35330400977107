import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Chart from './Chart';
import Loader from 'cccisd-loader';

import vizQuery from './reachViz.graphql';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const grpL2assignmentId = Appdefs.app.tripleP.grpL2assignmentId;
const grpL3assignmentId = Appdefs.app.tripleP.grpL3assignmentId;

const Component = props => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const { title, description, show, colors } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0, 1, 2, 3, 4],
              reportingFrom: '2020-01-01',
              reportingTo: '2021-12-31',
          }
        : null;
    const selectedIds = groupIds ? groupIds.map(Number) : previewData.groupIds;
    const assignmentId = level === 'Level 2 Seminar' ? grpL2assignmentId : grpL3assignmentId;
    const sessionDate =
        level === 'Level 2 Seminar' ? 'devTags.L2SeminarDate' : 'devTags.L3DiscussionDate';

    useEffect(() => {
        (async () => {
            setLoading(true);
            getLevelData(selectedIds, assignmentId, reportingFrom, reportingTo, sessionDate).then(
                async response => {
                    const results = response.data.flows.completedFids;
                    setData(results);
                }
            );
            setLoading(false);
        })();
    }, [selectedIds, assignmentId, reportingFrom, reportingTo]);

    const getLevelData = ids => {
        return apollo.query({
            query: vizQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: ids,
                assignmentId,
                reportingFrom,
                reportingTo,
                sessionDate,
            },
        });
    };

    const rowsRendered =
        data &&
        data.frequency.map(topic => {
            return {
                topic: topic.value,
                Parents: topic.totalParents,
                Children: topic.totalChildren,
                'Children 0 to 5': topic.children0to5,
            };
        });

    const nivoFakeData = [
        {
            topic: 'Raising Confident, Competent Children',
            children0to5: 142,
            children0to5Color: 'hsl(112, 70%, 50%)',
            parents: 146,
            parentsColor: 'hsl(329, 70%, 50%)',
            children: 145,
            childrenColor: 'hsl(45, 70%, 50%)',
        },
        {
            topic: 'Raising Resilient Children',
            children0to5: 38,
            children0to5Color: 'hsl(346, 70%, 50%)',
            parents: 188,
            parentsColor: 'hsl(229, 70%, 50%)',
            children: 51,
            childrenColor: 'hsl(36, 70%, 50%)',
        },
    ];

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <div className={style.title}>
                        <strong
                            style={
                                colors
                                    ? {
                                          color: colors.primary,
                                          fontSize: '1.5em',
                                      }
                                    : { fontSize: '1.5em' }
                            }
                        >
                            {level ? level : 'Level 2 Seminar'} {title}
                        </strong>
                        <div>{description}</div>
                    </div>
                    <Loader loading={loading}>
                        <div style={{ height: '500px', width: '100%' }}>
                            <Chart data={data ? rowsRendered : nivoFakeData} />
                        </div>
                    </Loader>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
