import React from 'react';
import PropTypes from 'prop-types';
import style from '../style.css';

const AccredAndTrain = props => {
    return <div className={style.accredTrain}>{props.children}</div>;
};

AccredAndTrain.propTpes = {
    variants: PropTypes.object,
};

export default AccredAndTrain;
