import React from 'react';
import PropTypes from 'prop-types';
import { Field, CccisdInput, CccisdWysiwyg, CccisdColorPicker } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import filterFields from '../filterFields.js';
import widgets from '../widgets.js';

export const initialValues = {
    filterFields: filterFields.map(item => item.name),
    otherFilterOptions: [],
    colors: { primary: '#F37021' },
    header: {
        title: 'Triple P of South Carolina',
        createdDateText: 'Report Created On:',
        reportType: 'Program Report',
        reportingPeriodText: 'From:',
    },
    body: {
        heading: '',
    },
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const Component = props => {
    return (
        <div>
            <BuilderBlock title="Header">
                <Field name="header.title" component={CccisdInput} label="Title" />
                <Field name="header.reportType" component={CccisdInput} label="Report Type" />
                <Field
                    name="header.reportingPeriodText"
                    component={CccisdInput}
                    label="Reporting Period Text"
                />
                <Field
                    name="header.createdDateText"
                    component={CccisdInput}
                    label="Created Date Text"
                />
            </BuilderBlock>
            <BuilderBlock title="Color">
                {/* Margin added to show picker even with "overflow:hidden" */}
                <div style={{ marginBottom: '230px' }}>
                    <Field
                        name="colors.primary"
                        component={CccisdColorPicker}
                        label="Color Scheme"
                    />
                </div>
            </BuilderBlock>
            <BuilderBlock title="Description">
                <Field
                    name="body.heading"
                    component={CccisdWysiwyg}
                    label="Description of how services are defined and counted."
                />
            </BuilderBlock>
        </div>
    );
};

Component.propTypes = {
    onValidChange: PropTypes.func,
    settings: PropTypes.object,
};

export default reportTemplateBuilder({ filterFields, widgets })(Component);
