import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import Chart from './Chart';

import detailChartIndL4Query from './detailChartIndL4.graphql';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const indL4 = Appdefs.app.tripleP.indL4;

const DetailChart = props => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const { title, description, show, colors } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;

    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;

    const selectedIds = groupIds ? groupIds.map(Number) : previewData.groupIds;
    const assignmentId = indL4.assignmentId;

    useEffect(() => {
        (async () => {
            setLoading(true);
            getIndL4Data(selectedIds).then(async response => {
                const results = response.data.groups;
                setData(results);
            });
            setLoading(false);
        })();
    }, [selectedIds, reportingFrom, reportingTo]);

    const getIndL4Data = ids => {
        return apollo.query({
            query: detailChartIndL4Query,
            fetchPolicy: 'network-only',
            variables: {
                groupId: ids,
                assignmentId,
                reportingFrom,
                reportingTo,
            },
        });
    };

    const indL4rows =
        data &&
        data.triplep_siteList.map(site => {
            return {
                id: site.group.groupId,
                sdo: site.label,
                S1: site.childRoles.session1.toString(),
                S2: site.childRoles.session2.toString(),
                S3: site.childRoles.session3.toString(),
                S4: site.childRoles.session4.toString(),
                S5: site.childRoles.session5.toString(),
                S6: site.childRoles.session6.toString(),
                S7: site.childRoles.session7.toString(),
                S8: site.childRoles.session8.toString(),
                S9: site.childRoles.session9.toString(),
                S10: site.childRoles.session10.toString(),
            };
        });

    const fakeData = [
        {
            id: 1,
            sdo: 'Black River United Way',
            S1: 5,
            S2: 10,
            S3: 5,
            S4: 10,
            S5: 5,
            S6: 10,
            S7: 5,
            S8: 10,
            S9: 5,
            S10: 10,
        },
    ];

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <div className={style.title}>
                        <strong
                            style={
                                colors
                                    ? {
                                          color: colors.primary,
                                          fontSize: '1.5em',
                                      }
                                    : { fontSize: '1.5em' }
                            }
                        >
                            {level ? level : 'Level 4 Standard'} {title}
                        </strong>
                        <div>{description}</div>
                    </div>
                    <Loader loading={loading}>
                        <div style={{ height: '800px', width: '100%' }}>
                            <Chart data={data ? indL4rows : fakeData} />
                        </div>
                    </Loader>
                </div>
            )}
        </>
    );
};

DetailChart.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

DetailChart.defaultProps = {
    filters: {},
};

export default DetailChart;
