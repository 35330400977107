import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, CccisdInput, CccisdSelect, CccisdDatepicker } from 'cccisd-formik';
import { client as apollo } from 'cccisd-apollo';
import ClickButton from 'cccisd-click-button';
import _xor from 'lodash/xor';
import { UserCheck } from 'cccisd-laravel-nexus';

import IconArrowLeft from 'cccisd-icons/arrow-left20';
import IconArrowDown from 'cccisd-icons/arrow-down20';
import IconExpand from 'cccisd-icons/enlarge7';
import IconCollapse from 'cccisd-icons/shrink7';

import AccredAndTrain from './AccredAndTrain';
import RequiredFieldLabel from '../../../RequiredFieldLabel';
import query from './sites.graphql';
import style from './style.css';

const fields = window.cccisd && window.cccisd.appDefs.pawn.fields;
const enumFields = fields.filter(field => field.data_type === 'enum');
const enumValues = {};
for (const field of enumFields) {
    enumValues[field.handle] = field.values.map(item => ({
        label: item.name,
        value: item.value,
    }));
}

const variants = [
    {
        id: '0',
        level: 'Level 2',
        label: 'Brief Primary Care',
        accred: 'accredIndL2',
        accredDate: 'accredDateIndL2',
        accredEnum: enumValues.accredIndL2,
        train: 'trainIndL2',
        trainDate: 'trainDateIndL2',
        trainEnum: enumValues.trainIndL2,
    },
    {
        id: '1',
        label: 'Seminar',
        accred: 'accredGrpL2',
        accredDate: 'accredDateGrpL2',
        accredEnum: enumValues.accredGrpL2,
        train: 'trainGrpL2',
        trainDate: 'trainDateGrpL2',
        trainEnum: enumValues.trainGrpL2,
    },
    {
        id: '2',
        level: 'Level 3',
        label: 'Primary Care',
        accred: 'accredIndL3',
        accredDate: 'accredDateIndL3',
        accredEnum: enumValues.accredIndL3,
        train: 'trainIndL3',
        trainDate: 'trainDateIndL3',
        trainEnum: enumValues.trainIndL3,
    },
    {
        id: '3',
        label: 'Discussion Group',
        accred: 'accredGrpL3',
        accredDate: 'accredDateGrpL3',
        accredEnum: enumValues.accredGrpL3,
        train: 'trainGrpL3',
        trainDate: 'trainDateGrpL3',
        trainEnum: enumValues.trainGrpL3,
    },
    {
        id: '4',
        level: 'Level 4',
        label: 'Standard Accredited',
        accred: 'accredIndL4',
        accredDate: 'accredDateIndL4',
        accredEnum: enumValues.accredIndL4,
        train: 'trainIndL4',
        trainDate: 'trainDateIndL4',
        trainEnum: enumValues.trainIndL4,
    },
    {
        id: '5',
        label: 'Group Accredited',
        accred: 'accredGrpL4',
        accredDate: 'accredDateGrpL4',
        accredEnum: enumValues.accredGrpL4,
        train: 'trainGrpL4',
        trainDate: 'trainDateGrpL4',
        trainEnum: enumValues.trainGrpL4,
    },
];

function ProviderForm({ onSubmit, closeModal, initialValues, operation }) {
    const [sites, setSites] = useState([]);
    const [expandedVariants, setExpandedVariants] = useState([]);

    async function onHandleSubmit(values) {
        values.existingUser;
        await onSubmit(values);
        closeModal();
    }

    const validate = values => {
        const errors = {};

        if (values.availableSameGroup) {
            errors.group = 'User already exists in this SDO. ';
        }
        if (!values.username) {
            errors.username = 'Username is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
            errors.username = 'Invalid email address';
        }
        if (!values.firstName) {
            errors.firstName = 'First Name is required.';
        }
        if (!values.lastName) {
            errors.lastName = 'Last Name is required.';
        }
        if (!values.gender) {
            errors.gender = 'Gender is required.';
        }
        if (!values.triplepRace) {
            errors.triplepRace = 'Race is required.';
        }
        if (!values.group) {
            errors.group = 'SDO is required.';
        }
        if (!values.sector) {
            errors.sector = 'Sector is required.';
        }

        return errors;
    };

    const initialTrainAccred = {
        accredIndL2: 'No',
        trainIndL2: 'No',
        accredGrpL2: 'No',
        trainGrpL2: 'No',
        accredIndL3: 'No',
        trainIndL3: 'No',
        accredGrpL3: 'No',
        trainGrpL3: 'No',
        accredIndL4: 'No',
        trainIndL4: 'No',
        accredGrpL4: 'No',
        trainGrpL4: 'No',
    };

    const combinedInitial = { ...initialTrainAccred, ...initialValues };

    useEffect(() => {
        apollo
            .query({
                query,
                fetchPolicy: 'network-only',
            })
            .then(async res => {
                const arr = res.data.groups.triplep_siteList;
                await setSites(arr);
            });
    }, []);

    const siteOptions = items => {
        return items.map(({ group: { groupId, label } }) => {
            return { value: groupId, label };
        });
    };

    const userCheck = async values => {
        const checkUserDelay = 1000;

        if (!values.group && !values.username) {
            return;
        }

        let checkUserData = {
            username: values.username,
            group: Number(values.group),
            role: 'triplep_provider',
        };

        let userCheckResponse = await UserCheck(checkUserData, checkUserDelay);

        if (userCheckResponse.available === true) {
            values.available = true;
            values.availableSameGroup = false;

            if (userCheckResponse.user) {
                values.available = false;
                values.existingUser = true;

                values.username = userCheckResponse.user.username;
                values.firstName = userCheckResponse.user.first_name;
                values.lastName = userCheckResponse.user.last_name;
                values.phone = userCheckResponse.user.phone;
            }
        } else {
            values.existingUser = false;

            if (Number(values.group) !== combinedInitial.group) {
                values.availableSameGroup = true;
            }
        }
    };

    const expandAll = () => {
        const allLvs = variants.map(lv => {
            return lv.id;
        });
        setExpandedVariants(allLvs);
    };

    return (
        <Formik
            onSubmit={e => onHandleSubmit(e)}
            initialValues={combinedInitial}
            validate={e => validate(e)}
        >
            {({ handleSubmit, values, isSubmitting, errors }) => (
                <Form>
                    {values.available ? (
                        <div className={`alert alert-success ${style.usernameValid}`} role="alert">
                            Username available.
                        </div>
                    ) : null}
                    {values.existingUser ? (
                        <div className={`alert alert-info ${style.usernameValid}`} role="alert">
                            User already exists in another SDO - filling in details.
                        </div>
                    ) : null}
                    <Field
                        name="username"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>Username:</RequiredFieldLabel>}
                        validateOnBlur={userCheck(values, errors)}
                        disabled={values.existingUser || operation === 'edit'}
                    />
                    <div className="row">
                        <div className="col-xs-4">
                            <Field
                                name="firstName"
                                component={CccisdInput}
                                label={
                                    <RequiredFieldLabel isRequired>First Name:</RequiredFieldLabel>
                                }
                                disabled={values.existingUser}
                            />
                        </div>
                        <div className="col-xs-4">
                            <Field name="middleName" component={CccisdInput} label="Middle Name:" />
                        </div>
                        <div className="col-xs-4">
                            <Field
                                name="lastName"
                                component={CccisdInput}
                                label={
                                    <RequiredFieldLabel isRequired>Last Name:</RequiredFieldLabel>
                                }
                                disabled={values.existingUser}
                            />
                        </div>
                    </div>
                    <Field
                        name="phone"
                        component={CccisdInput}
                        label="Phone:"
                        disabled={values.existingUser}
                    />
                    <Field
                        name="gender"
                        component={CccisdSelect}
                        label={<RequiredFieldLabel isRequired>Gender:</RequiredFieldLabel>}
                        options={[
                            { value: '', label: '-- Please select a Gender --' },
                            ...enumValues.gender,
                        ]}
                    />
                    <div className="row">
                        <div className="col-xs-6">
                            <Field
                                name="triplepRace"
                                component={CccisdSelect}
                                label={<RequiredFieldLabel isRequired>Race:</RequiredFieldLabel>}
                                options={[
                                    { value: '', label: '-- Please select a Race --' },
                                    ...enumValues.triplepRace,
                                ]}
                            />
                        </div>
                        {values.triplepRace && values.triplepRace === 'Not listed' && (
                            <div className="col-xs-6">
                                <Field
                                    name="raceOther"
                                    component={CccisdInput}
                                    label="Race (if not listed above):"
                                />
                            </div>
                        )}
                    </div>
                    <Field
                        name="group"
                        component={CccisdSelect}
                        label={
                            <RequiredFieldLabel isRequired>
                                Service Delivery Organization:
                            </RequiredFieldLabel>
                        }
                        options={[
                            {
                                value: '',
                                label: '-- Please select a SDO --',
                            },
                            ...siteOptions(sites),
                        ]}
                        validateOnBlur={userCheck(values, errors)}
                    />
                    <Field
                        name="professionalTitle"
                        component={CccisdInput}
                        label="Professional Title/Role:"
                    />
                    <div className="row">
                        <div className="col-xs-6">
                            <Field
                                name="sector"
                                component={CccisdSelect}
                                label={<RequiredFieldLabel isRequired>Sector:</RequiredFieldLabel>}
                                options={[
                                    {
                                        value: '',
                                        label: '-- Please select a Sector --',
                                    },
                                    ...enumValues.sector,
                                ]}
                            />
                        </div>
                        {values.sector && values.sector === 'Other' && (
                            <div className="col-xs-6">
                                <Field
                                    name="sectorOther"
                                    component={CccisdInput}
                                    label="Sector (if other):"
                                />
                            </div>
                        )}
                    </div>
                    <hr />
                    <strong>
                        Please indicate Training and Accreditation status for each level and
                        variant.
                    </strong>
                    <div
                        style={{ marginTop: '1em', display: 'flex', justifyContent: 'flex-start' }}
                    >
                        <button
                            type="button"
                            className={`btn btn-info ${style.rosteringButton}`}
                            onClick={() => expandAll()}
                            style={{ marginRight: '1em' }}
                        >
                            <IconExpand spaceRight />
                            Expand All
                        </button>
                        <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => setExpandedVariants([])}
                        >
                            <IconCollapse spaceRight />
                            Collapse All
                        </button>
                    </div>
                    {variants.map(lv => (
                        <div key={lv.id} className={style.lv}>
                            <h4>{lv.level}</h4>
                            <div className={style.accordion}>
                                <div
                                    className={style.cardHeader}
                                    onClick={() =>
                                        setExpandedVariants(_xor(expandedVariants, [lv.id]))
                                    }
                                >
                                    <div>{lv.label}</div>
                                    <div className={style.arrow}>
                                        {!expandedVariants.includes(lv.id) ? (
                                            <IconArrowLeft />
                                        ) : (
                                            <IconArrowDown />
                                        )}
                                    </div>
                                </div>
                                {expandedVariants.includes(lv.id) && (
                                    <AccredAndTrain variants={variants}>
                                        <div className={style.accredTrain}>
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <Field
                                                        name={lv.train}
                                                        component={CccisdSelect}
                                                        label=" Trained:"
                                                        options={[...lv.trainEnum]}
                                                    />
                                                </div>
                                                <div className="col-xs-6">
                                                    <Field
                                                        name={lv.trainDate}
                                                        component={CccisdDatepicker}
                                                        label="Date Training Complete"
                                                        placeholder="mm/dd/yyyy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <Field
                                                        name={lv.accred}
                                                        component={CccisdSelect}
                                                        label="Accredited:"
                                                        options={[...lv.accredEnum]}
                                                    />
                                                </div>
                                                <div className="col-xs-6">
                                                    <Field
                                                        name={lv.accredDate}
                                                        component={CccisdDatepicker}
                                                        label="Date of Accreditation"
                                                        placeholder="mm/dd/yyyy"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </AccredAndTrain>
                                )}
                            </div>
                        </div>
                    ))}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ClickButton
                            title="Submit"
                            className="btn btn-primary"
                            isLoading={isSubmitting}
                            onClick={() => handleSubmit()}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
}

ProviderForm.propTypes = {
    settings: PropTypes.object,
    operation: PropTypes.string,
    handleSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
};

export default ProviderForm;
