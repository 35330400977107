import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-graphql-table';
import _flatten from 'lodash/flatten';

import detailTableQuery from './detailTable.graphql';

const FilteredTable = props => {
    const {
        groupId,
        assignmentId,
        session1s,
        session2s,
        session3s,
        session4s,
        session5s,
        session6s,
        session7s,
        session8s,
    } = props;

    const oneArrayAllSessions = [
        ...session1s,
        session2s,
        session3s,
        session4s,
        session5s,
        session6s,
        session7s,
        session8s,
    ];

    const allSessions = _flatten(oneArrayAllSessions);

    const grpL4columns = [
        {
            name: 'ancestorGroups.triplep_groupingUnit.group.label',
            label: 'BBO',
            setStyle: () => {
                return { width: '25%' };
            },
            rowSpanBy: 'ancestorGroups.triplep_groupingUnit.group.label',
        },
        {
            name: 'group.label',
            label: 'SDO',
            setStyle: () => {
                return { width: '25%' };
            },
        },
        {
            topHeaderSpan: 8,
            topHeader: 'Sessions',
            name: 'childRoles.session1',
            label: 'S1',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
            totalPath: 'siteSession1.siteTotalSession1',
        },
        {
            name: 'childRoles.session2',
            label: 'S2',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
            totalPath: 'siteSession2.siteTotalSession2',
        },
        {
            name: 'childRoles.session3',
            label: 'S3',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
            totalPath: 'siteSession3.siteTotalSession3',
        },
        {
            name: 'childRoles.session4',
            label: 'S4',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
            totalPath: 'siteSession4.siteTotalSession4',
        },
        {
            name: 'childRoles.session5',
            label: 'S5',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
            totalPath: 'siteSession5.siteTotalSession5',
        },
        {
            name: 'childRoles.session6',
            label: 'S6',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
            totalPath: 'siteSession6.siteTotalSession6',
        },
        {
            name: 'childRoles.session7',
            label: 'S7',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
            totalPath: 'siteSession7.siteTotalSession7',
        },
        {
            name: 'childRoles.session8',
            label: 'S8',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
            totalPath: 'siteSession8.siteTotalSession8',
        },
        {
            name: 'childRoles.triplep_parentSummary.totalSessions',
            label: 'Total',
            className: 'text-center',
            setStyle: () => {
                return { verticalAlign: 'middle', fontWeight: 'bold' };
            },
            totalPath: 'siteTotal.allSessions',
        },
    ];
    return (
        <Table
            columns={grpL4columns}
            query={detailTableQuery}
            graphqlVariables={{
                groupId,
                assignmentId,
                session1s: session1s.length > 0 ? session1s : 0,
                session2s: session2s.length > 0 ? session2s : 0,
                session3s: session3s.length > 0 ? session3s : 0,
                session4s: session4s.length > 0 ? session4s : 0,
                session5s: session5s.length > 0 ? session5s : 0,
                session6s: session6s.length > 0 ? session6s : 0,
                session7s: session7s.length > 0 ? session7s : 0,
                session8s: session8s.length > 0 ? session8s : 0,
                allSessions: allSessions.length > 0 ? allSessions : 0,
            }}
            noRecordsMessage="No data collected at selected filters."
            rowKey="group.groupId"
            perPage={25}
            showTotal
        />
    );
};

FilteredTable.propTypes = {
    groupId: PropTypes.array,
    assignmentId: PropTypes.number,
    session1s: PropTypes.array,
    session2s: PropTypes.array,
    session3s: PropTypes.array,
    session4s: PropTypes.array,
    session5s: PropTypes.array,
    session6s: PropTypes.array,
    session7s: PropTypes.array,
    session8s: PropTypes.array,
};

export default FilteredTable;
