import React from 'react';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import { Dashboard as AppdefDashboard, Manage as AppdefManage, AdminRoutes } from 'cccisd-laravel-appdefs';
import { Quest } from 'cccisd-laravel-assignment';
import { ResourceCenter, ResourceManager } from 'cccisd-laravel-resources';
import ReportCenter from 'cccisd-report-center';

// Layouts
import MainLayout from './layouts/MainLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle
import QuestPreviewList from './pages/QuestPreviewList';
import MiechvPreviewList from './pages/MiechvPreviewList';
import ImportData from './pages/ImportData'; // Include it into main bundle
import RolePicker from './pages/RolePicker';
import OutcomesSurvey from 'bundle-loader?lazy!./pages/OutcomesSurvey';

// Components
import AuthRedirect from 'bundle-loader?lazy!./components/AuthRedirect';
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';
import UnderConstruction from 'bundle-loader?lazy!./components/UnderConstruction';

// MIECHV
import MIECHVClientTable from 'bundle-loader?lazy!./components/miechv/MIECHVClientTable';
import MIECHVReports from 'bundle-loader?lazy!./components/miechv/MIECHVReports';
import MIECHVForms from 'bundle-loader?lazy!./components/miechv/MIECHVForms';

// Triple P
import TRIPLEPDashboard from 'bundle-loader?lazy!./components/triplep/Dashboard';
import TRIPLEPDataEntry from 'bundle-loader?lazy!./components/triplep/Clients';
import ViewData from 'bundle-loader?lazy!./pages/ViewData';
import PPPResourceCenter from 'bundle-loader?lazy!./components/triplep/PPPResourceCenter';

// Coalitions
import ActivityTracker from 'bundle-loader?lazy!./components/coalitions/ActivityTracker';
import ActivityEvaluator from 'bundle-loader?lazy!./components/coalitions/ActivityTracker/ActivityEvaluator';
import CommunitySurveys from 'bundle-loader?lazy!./components/coalitions/CommunitySurveys';
import CommunitySurveyEvaluator from 'bundle-loader?lazy!./components/coalitions/CommunitySurveys/CommunitySurveyEvaluator';
import GeneralMembersOutcomesData from 'bundle-loader?lazy!./components/coalitions/GeneralMembersOutcomesData';
import CoreMembersOutcomesData from 'bundle-loader?lazy!./components/coalitions/CoreMembersOutcomesData';

// SFP
import SessionData from 'bundle-loader?lazy!./components/sfp/SessionData';
import FacilitatorRatings from 'bundle-loader?lazy!./components/sfp/FacilitatorRatings';
import FamilyDataEntry from 'bundle-loader?lazy!./components/sfp/FamilyDataEntry';
import CyclePlanning from 'bundle-loader?lazy!./components/sfp/CyclePlanning';
import AnnualBooster from 'bundle-loader?lazy!./components/sfp/AnnualBooster';

// Bind MainLayoutFluid by default
const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const AppRoute = defaultProps({ layout: MainLayoutFluid })(Route);

var Fortress = window.cccisd.fortress;
var AppDefinitions = window.cccisd.appDefs;

const match = {
    AppdefDashboard,
    AppdefManage,
    ResourceCenter,
    QuestPreviewList,
    MiechvPreviewList,
    MIECHVClientTable,
    MIECHVReports,
    MIECHVForms,
    TRIPLEPDashboard,
    TRIPLEPDataEntry,
    ActivityTracker,
    ActivityEvaluator,
    CommunitySurveys,
    CommunitySurveyEvaluator,
    GeneralMembersOutcomesData,
    CoreMembersOutcomesData,
    ViewData,
    PPPResourceCenter,
    ReportCenter,
    SessionData,
    FamilyDataEntry,
    FacilitatorRatings,
    CyclePlanning,
    UnderConstruction,
    AnnualBooster,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = match[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={'layout' in route ? layoutMatch[route.layout] : MainLayoutFluid}
                />
            );
        })}

        <AppRoute path="/" component={Welcome} exact layout={MainLayout} />

        {/* MIECHV */}
        <AppRoute
            path="/miechv/outcomes/:outcomesHash/:respondentHash"
            component={OutcomesSurvey}
            componentProps={{
                errorLayout: MainLayoutFluid,
                miechv: true,
            }}
            layout={MainLayoutFluid}
        />

        <AppRoute path="/miechvList" component={QuestPreviewList} layout={MainLayout} />

        {/* Triple P */}
        <AppRoute
            path="/triplep/parentProfile/:level/:deploymentHash?/:respondentHash?"
            component={NotFound}
            componentProps={{
                errorLayout: MainLayoutFluid,
            }}
            layout={MainLayoutFluid}
        />

        <AppRoute
            path="/triplep/parentFeedback/:level/:fidelityHash?/:siteGroupPawnId/:deploymentId/"
            component={NotFound}
            componentProps={{
                errorLayout: MainLayoutFluid,
            }}
            layout={MainLayoutFluid}
        />

        <AppRoute
            path="/triplep/outcomes/:outcomesHash/:respondentHash"
            component={NotFound}
            componentProps={{
                errorLayout: MainLayoutFluid,
            }}
            layout={MainLayoutFluid}
        />

        {Fortress.auth() && Fortress.hasRole('triplep_orgAdmin') && <AppRoute path="/ViewData" component={NotFound} />}

        {/* Coalitions */}
        <AppRoute path="/coalitions/activity/:respondentHash" component={NotFound} layout={MainLayoutFluid} />

        <AppRoute path="/coalitions/community/:respondentHash" component={NotFound} layout={MainLayoutFluid} />

        {/* Laravel packages routes */}
        {[
            'uberadmin',
            'questprojectadmin',
            'orgAdmin',
            'triplep_orgAdmin',
            'coalition_orgAdmin',
            'sfp_orgAdmin',
        ].includes(Fortress.user.acting.data_type) && AdminRoutes(MainLayoutFluid)}

        {Fortress.auth() && <AppRoute path="/rolePicker" component={RolePicker} layout={MainLayoutFluid} />}

        <AppRoute path="/previewList" component={QuestPreviewList} layout={MainLayoutFluid} />

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={MainLayoutFluid} />

        {(Fortress.hasRole('orgAdmin') ||
            Fortress.hasRole('coalition_orgAdmin') ||
            Fortress.hasRole('sfp_orgAdmin')) && (
            <AppRoute path="/resources/admin" component={ResourceManager} layout={MainLayoutFluid} />
        )}

        {Fortress.hasRole('uberadmin') && (
            <AppRoute path="/importData" component={ImportData} layout={MainLayoutFluid} />
        )}

        {Fortress.auth() && (
            <AppRoute path="/resource" component={ResourceCenter} handle="default" layout={MainLayoutFluid} />
        )}

        <AppRoute path="/account" component={Nexus} />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} layout={MainLayout} />
    </Switch>
);
