import React from 'react';
import PropTypes from 'prop-types';
import { NavBar } from 'cccisd-laravel-boilerplate';
import style from './style.css';
import _some from 'lodash/some';

const Fortress = window.cccisd && window.cccisd.fortress;
const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Appdefs = window.cccisd && window.cccisd.appDefs;

const userRoles = Fortress.user.pawns;
const workforceHomepage = Boilerplate.url('/quest');
// const miechvHomepage = Boilerplate.url('/dashboard');

const umbrellaApp = [
    {
        id: 1,
        title: 'Survey Builder',
        href: workforceHomepage,
        hasRoles: [
            { role: 'Uber Admin', handle: 'uberadmin' },
            { role: 'Org Admin', handle: 'wfd_orgAdmin' },
        ],
    },
    // {
    //     id: 2,
    //     title: 'MIECHV',
    //     href: miechvHomepage,
    //     hasRoles: [
    //         { role: 'Uber Admin', handle: 'uberadmin' },
    //         { role: 'Org Admin', handle: 'orgAdmin' },
    //         { role: 'Site Admin', handle: 'siteAdmin' },
    //         { role: 'Home Visitor', handle: 'provider' },
    //     ],
    // },
    // {
    //     id: 3,
    //     title: 'Triple P',
    //     href: '#',
    //     hasRoles: [
    //         { role: 'Uber Admin', handle: 'uberadmin' },
    //         { role: 'Org Admin', handle: 'triplep_orgAdmin' },
    //     ],
    // },
];

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    checkAccess = navItem => {
        if (!Fortress.auth()) {
            return false;
        }

        let umbrellaRoles = [
            { role: 'Uber Admin', handle: 'uberadmin' },
            { role: 'Org Admin', handle: 'wfd_orgAdmin' },
            { role: 'Org Admin', handle: 'orgAdmin' },
            { role: 'Site Admin', handle: 'siteAdmin' },
            { role: 'Home Visitor', handle: 'provider' },
        ];

        _some(userRoles, umbrellaRoles);

        return true;
    };

    // TEMP method
    getActiveNavItem = () => {
        const currentPath = window.location.pathname;

        const routeMatch = Appdefs.routes.find(route => {
            return currentPath.includes(route.url);
        });

        return routeMatch ? routeMatch.app : '';
    };

    render() {
        let showTheseProfileDataFields = {
            orgAdmin: ['agency'],
        };

        const currentApp = this.getActiveNavItem();

        return (
            <NavBar
                {...this.props}
                includeUserFields
                showTheseProfileDataFields={showTheseProfileDataFields}
                logo={<div className={style.logo} />}
            >
                {umbrellaApp.map(navItem => (
                    <li>
                        {(Fortress.isSuperUser() || this.checkAccess(navItem)) && (
                            <a
                                key={navItem.id}
                                href={`${navItem.href}`}
                                className={currentApp === navItem.title ? style.active : ''}
                            >
                                {navItem.title}{' '}
                            </a>
                        )}
                    </li>
                ))}
            </NavBar>
        );
    }
}
