import React from 'react';

export default () => {
    return ({ row }) => {
        const topics = [];
        const getTopics = () => {
            if (row['devTags.ChildSkillsGroupTopic'] === 'checked') {
                topics.push('Child Skills Group');
            }
            if (row['devTags.ParentSkillsGroupTopic'] === 'checked') {
                topics.push('Parent Skills Group');
            }
            if (row['devTags.FamilySkillsGroupTopic'] === 'checked') {
                topics.push('Family Skills Group');
            }
            if (row['devTags.ProgramFidelityTopic'] === 'checked') {
                topics.push('Program Fidelity');
            }
            if (row['devTags.SpecialPopulationsTopic'] === 'checked') {
                topics.push('Special Populations');
            }
            if (row['devTags.StaffingTopic'] === 'checked') {
                topics.push('Staffing');
            }
            if (row['devTags.RecruitmentTopic'] === 'checked') {
                topics.push('Recruitment');
            }
            if (row['devTags.RetentionTopic'] === 'checked') {
                topics.push('Retention');
            }
            if (row['devTags.SiteLocationTopic'] === 'checked') {
                topics.push('Site Location/Set-Up');
            }
            if (row['devTags.CurriculumTopic'] === 'checked') {
                topics.push('Curriculum');
            }
            if (row['devTags.MealTopic'] === 'checked') {
                topics.push('Meal');
            }
            if (row['devTags.DebriefingTopic'] === 'checked') {
                topics.push('Debriefing');
            }
            if (row['devTags.AGADataTopic'] === 'checked') {
                topics.push('AGA Data');
            }
            if (row['devTags.CTSCDataTopic'] === 'checked') {
                topics.push('CTSC Data');
            }
            if (row['devTags.CQITopic'] === 'checked') {
                topics.push('Continuous Quality Improvement (CQI)');
            }
            if (row['devTags.CommunityEngagementTopic'] === 'checked') {
                topics.push('Community Engagement');
            }
            if (row['devTags.PartnershipEngagementTopic'] === 'checked') {
                topics.push('Partnership Engagement');
            }
            if (row['devTags.VirtualServiceDeliveryTopic'] === 'checked') {
                topics.push('Virtual Service Delivery');
            }
        };

        getTopics(row);

        return (
            <div>
                {topics.map(topic => (
                    <li>{topic}</li>
                ))}
            </div>
        );
    };
};
