import React from 'react';
import { differenceInYears } from 'date-fns';

export default settings => {
    return ({ row }) => {
        const session1Date =
            row[
                'ancestorGroups.sfp_family.ancestorGroups.sfp_group.childRoles.metricspawn.session1Date.devTags.SFPMeetingDate'
            ];
        const sessionDataGradDate =
            row[
                'ancestorGroups.sfp_family.ancestorGroups.sfp_group.childRoles.metricspawn.graduationDate.devTags.SFPMeetingDate'
            ];

        const renderDate = () => {
            if (row['fields.DOB'] && settings.current === true) {
                return differenceInYears(new Date(), row['fields.DOB']);
            }
            if (
                row[
                    'ancestorGroups.sfp_family.ancestorGroups.sfp_group.childRoles.metricspawn.session1Date.devTags.SFPMeetingDate'
                ] &&
                settings.session === '1'
            ) {
                const session1Age = differenceInYears(session1Date, row['fields.DOB']);
                return session1Age;
            }
            if (
                row[
                    'ancestorGroups.sfp_family.ancestorGroups.sfp_group.childRoles.metricspawn.graduationDate.devTags.SFPMeetingDate'
                ] &&
                settings.session === 'graduation'
            ) {
                const gradAge = differenceInYears(sessionDataGradDate, row['fields.DOB']);

                return gradAge;
            }
        };

        return <div>{renderDate()}</div>;
    };
};
