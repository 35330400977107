import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-graphql-table';

import summaryTableQuery from './summaryTable.graphql';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const indL3 = Appdefs.app.tripleP.indL3;

const SummaryTable = props => {
    const { title, description, show, colors } = props.settings;

    const { groupIds, reportingFrom, reportingTo } = props.filters;

    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;

    const selectedIds = groupIds ? groupIds.map(Number) : previewData.groupIds;

    const assignmentId = indL3.assignmentId;

    const requiredContentHandle = indL3.requiredContentHandle;

    const completedContentHandle = indL3.completedContentHandle;

    const columns = [
        {
            name: 'ancestorGroups.triplep_groupingUnit.group.label',
            label: 'BBO',
            setStyle: () => {
                return { width: '25%' };
            },
            rowSpanBy: 'ancestorGroups.triplep_groupingUnit.group.label',
        },
        {
            name: 'group.label',
            label: 'SDO',
            setStyle: () => {
                return { width: '25%' };
            },
        },
        {
            name: 'childRoles.required',
            label: 'Number of Clients Finishing Required Content (total)',
            className: 'text-center',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
            totalPath: 'siteRequired.siteTotalSession1',
        },
        {
            name: 'childRoles.completed',
            label: 'Number of Clients Completing Service (total)',
            className: 'text-center',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
            totalPath: 'siteCompleted.siteTotalSession4',
        },
    ];

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <strong
                        style={
                            colors
                                ? {
                                      color: colors.primary,
                                      fontSize: '1.5em',
                                      marginBottom: '1em',
                                      textAlign: 'center',
                                  }
                                : { fontSize: '1.5em', marginBottom: '1em', textAlign: 'center' }
                        }
                    >
                        {title}
                    </strong>
                    <div className={style.description}>{description}</div>
                    <Table
                        columns={columns}
                        query={summaryTableQuery}
                        graphqlVariables={{
                            groupId: selectedIds,
                            assignmentId,
                            reportingFrom,
                            reportingTo,
                            requiredContentHandle,
                            completedContentHandle,
                        }}
                        noRecordsMessage="No data collected at selected filters."
                        rowKey="group.groupId"
                        hideShowingRowsSummary
                        perPage={25}
                        showTotal
                    />
                </div>
            )}
        </>
    );
};

SummaryTable.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

SummaryTable.defaultProps = {
    filters: {},
};

export default SummaryTable;
