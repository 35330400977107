import _flatten from 'lodash/flatten';

export const sumArgs = (...args) => args.reduce((total, num) => total + num, 0);

export const noDataCheck = value => {
    return Number.isNaN(value) ? 0 : value;
};

export const getModelFilters = options => {
    let trueFilters = options.filter(option => option.filter === true);

    let justLabels = trueFilters.map(m => {
        return [m.label];
    });
    const flattened = _flatten(justLabels);

    return flattened;
};

export const getHVmodels = ({ sites, site, HFAmodel, PATmodel, NFPmodel }) => {
    const filteredSite = sites.filter(s => s.value === Number(site));

    const sdoHVmodels = {
        hfa: filteredSite[0].HFA,
        pat: filteredSite[0].PAT,
        nfp: filteredSite[0].NFP,
    };

    const modelFilters = [
        {
            key: 'hfa',
            label: 'HFA',
            filter: HFAmodel,
        },
        {
            key: 'pat',
            label: 'PAT',
            filter: PATmodel,
        },
        {
            key: 'nfp',
            label: 'NFP',
            filter: NFPmodel,
        },
    ].filter(f => {
        if (sdoHVmodels[f.key]) {
            return true;
        }
        return false;
    });

    return modelFilters;
};

export const getPercentage = (value, total) => {
    let percentage = (value / total) * 100;

    if (Number.isNaN(percentage)) {
        return '0.00%';
    }

    return `${percentage.toFixed(2)}%`;
};
