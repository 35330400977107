import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import CheckboxTree from 'react-checkbox-tree';
import useQuery from '../../../useQuery.js';
import getGroupsQuery from './getSitesAndCycles.graphql';
import style from './style.css';
import { useradminData } from './fakeData.js';
import _flattenDeep from 'lodash/flattenDeep';
import Loader from 'cccisd-loader';

import IconSelect from 'cccisd-icons/stack-check';
import IconCheck from 'cccisd-icons/checkbox-checked';
import IconUncheck from 'cccisd-icons/checkbox-unchecked2';
import IconHalfcheck from 'cccisd-icons/checkbox-partial2';
import IconExpandClose from 'cccisd-icons/arrow-right';
import IconExpandOpen from 'cccisd-icons/arrow-down';
import IconParentClose from 'cccisd-icons/folder2';
import IconParentOpen from 'cccisd-icons/folder-open';
import IconLeaf from 'cccisd-icons/location';

const SelectSitesAndCycles = props => {
    const [expanded, setExpanded] = useState([]);
    const { title, modalHeader, form } = props;

    const [queryData, loading] = useQuery({
        query: getGroupsQuery,
        variables: { level: props.level },
    });

    useEffect(() => {
        _getNodes();
    }, [loading]);

    const _setCheckAll = () => {
        const allGroups = _flattenDeep(
            _getUserData().map(org => [
                org.id,
                ...org.groupingUnits.map(gu => [
                    gu.id,
                    ...gu.sites.map(site => [site.id, ...site.groups.map(group => group.id)]),
                ]),
            ])
        );

        form.setFieldValue('groupIds', allGroups);
    };

    const _setUncheckAll = () => {
        form.setFieldValue('groupIds', []);
    };

    const handleOnCheck = selectedGroups => {
        const groupIdNumbers = selectedGroups.map(id => Number(id));
        form.setFieldValue('groupIds', groupIdNumbers);
    };

    const convertDataStructure = myData => {
        let convertedData = [];
        if (loading === false) {
            convertedData = myData.groups.sfp_organizationList.map(org => {
                if (org.group.groupId) {
                    return {
                        id: org.group.groupId,
                        name: org.group.label,
                        groupingUnits: org.childGroups.sfp_groupingUnitList.map(gu => {
                            if (org.childGroups.sfp_groupingUnitList.length > 0) {
                                return {
                                    id: gu.group.groupId,
                                    name: gu.group.label,
                                    sites: gu.childGroups.sfp_siteList.map(site => {
                                        return {
                                            id: site.group.groupId,
                                            name: site.group.label,
                                            groups: site.childGroups.sfp_groupList.map(group => {
                                                return {
                                                    id: group.group.groupId,
                                                    name: group.group.label,
                                                };
                                            }),
                                        };
                                    }),
                                };
                            }
                            return null;
                        }),
                    };
                }
                return null;
            });
        } else {
            convertedData = useradminData;
        }
        return convertedData;
    };

    const _getUserData = () => {
        let data = convertDataStructure(queryData);
        return data;
    };

    const _renderSiteNode = site => {
        return (
            <span className={style.leaf}>
                <div className={style.groupName}>{site.name}</div>
            </span>
        );
    };

    const _getNodes = () => {
        let nodes;

        nodes = _getUserData().map(org => ({
            value: org.id,
            label: _renderSiteNode(org),
            className: style.node,
            children: org.groupingUnits.map(gu => ({
                value: gu.id,
                label: _renderSiteNode(gu),
                className: style.node,
                children: gu.sites.map(site => ({
                    value: site.id,
                    label: _renderSiteNode(site),
                    className: style.node,
                    children: site.groups.map(group => ({
                        value: group.id,
                        label: _renderSiteNode(group),
                        className: style.node,
                    })),
                })),
            })),
        }));

        return nodes;
    };

    return (
        <>
            {queryData.length < 1 ? (
                <Loader loading={loading} removeChildren>
                    Loading
                </Loader>
            ) : (
                <Modal
                    trigger={
                        <button type="button" className="btn btn-warning" onClick={() => {}}>
                            <IconSelect spaceRight />
                            {title}
                        </button>
                    }
                    title={title}
                    headerStyle={modalHeader}
                    render={({ closeModal }) => (
                        <>
                            <div className={style.flex}>
                                <CheckboxTree
                                    nodes={_getNodes()}
                                    checked={props.field.value}
                                    expanded={expanded}
                                    onCheck={checked => handleOnCheck(checked)}
                                    onExpand={expandedNodes => setExpanded(expandedNodes)}
                                    icons={{
                                        check: <IconCheck />,
                                        uncheck: <IconUncheck />,
                                        halfCheck: <IconHalfcheck />,
                                        expandClose: <IconExpandClose />,
                                        expandOpen: <IconExpandOpen />,
                                        expandAll: <IconExpandOpen />,
                                        collapseAll: <IconExpandClose />,
                                        parentClose: (
                                            <span style={{ color: '#c9c9c9' }}>
                                                <IconParentClose />
                                            </span>
                                        ),
                                        parentOpen: (
                                            <span style={{ color: '#f37021' }}>
                                                <IconParentOpen />
                                            </span>
                                        ),
                                        leaf: <IconLeaf />,
                                    }}
                                />
                                <div>
                                    <button
                                        type="button"
                                        className={`btn btn-warning ${style.button}`}
                                        onClick={() => _setCheckAll()}
                                    >
                                        <IconCheck spaceRight />
                                        Select All
                                    </button>
                                    <div style={{ marginTop: '1em' }}>
                                        <button
                                            type="button"
                                            className={`btn btn-info ${style.button}`}
                                            onClick={() => _setUncheckAll()}
                                        >
                                            <IconUncheck spaceRight />
                                            Unselect All
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '1em',
                                }}
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary ${style.button}`}
                                    onClick={closeModal}
                                >
                                    Next Filter
                                </button>
                            </div>
                        </>
                    )}
                />
            )}
        </>
    );
};

SelectSitesAndCycles.propTypes = {
    data: PropTypes.array,
    userType: PropTypes.string,
    field: PropTypes.object,
    form: PropTypes.object,
    filters: PropTypes.object,
    reportHandle: PropTypes.string,
    title: PropTypes.string,
    modalHeader: PropTypes.object,
    level: PropTypes.string,
};

SelectSitesAndCycles.defaultProps = {};

export default SelectSitesAndCycles;
