import React from 'react';
import _flatten from 'lodash/flatten';
import _isNan from 'lodash/isNaN';

export const initialLoadMessage = (
    <div style={{ color: 'gray', fontStyle: 'italic' }}>Please select SDO to generate report.</div>
);

export const getCaregiverTotal = queryBack => {
    const attendance = queryBack.map(seminar => {
        if (!seminar.additionalInfo.pawnIds) {
            return 0;
        }
        return [seminar.additionalInfo.pawnIds.length];
    });

    const flatten = _flatten(attendance);

    const totalAttendance = flatten.reduce((a, b) => a + b, 0);

    return totalAttendance;
};

export const getChildrenTotal = parents => {
    const children =
        parents &&
        parents.eachParent &&
        parents.eachParent.map(parent => {
            return [Number(parent.children[0].value)];
        });

    const flatten = _flatten(children);

    const totalChildren = flatten.reduce((a, b) => a + b, 0);

    return totalChildren;
};

export const renderPercentages = (session, total) => {
    const decimal = session / total;

    if (_isNan(decimal)) {
        return 0;
    }
    const roundedWhole = (decimal * 100).toFixed();

    return roundedWhole;
};
