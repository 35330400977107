import React from 'react';
import { Formik, Field, CccisdInput, CccisdDatepicker, CccisdSelect } from 'cccisd-formik';
import ClickButton from 'cccisd-click-button';
import PropTypes from 'prop-types';
import RequiredFieldLabel from '../../../../RequiredFieldLabel';

const fields = window.cccisd && window.cccisd.appDefs.pawn.fields;
const enumFields = fields.filter(field => field.data_type === 'enum');
const enumValues = {};
for (const field of enumFields) {
    enumValues[field.handle] = field.values.map(item => ({
        label: item.name,
        value: item.value,
    }));
}

const ParticipantForm = ({ closeModal, onSubmit, initialValues }) => {
    const onAddorEdit = async values => {
        onSubmit(values);
        closeModal();
    };

    const validate = values => {
        const errors = {};
        if (!values.firstName) {
            errors.firstName = 'First Name is required.';
        }
        if (!values.lastName) {
            errors.lastName = 'Last Name is required.';
        }
        if (!values.DOB) {
            errors.DOB = 'Date of Birth is required.';
        }
        if (!values.race) {
            errors.race = 'Race is required.';
        }
        if (!values.ethnicity) {
            errors.ethnicity = 'Ethnicity is required.';
        }
        if (!values.gender) {
            errors.gender = 'Gender is required.';
        }
        if (values.gender === 'Other' && !values.genderOther) {
            errors.genderOther = 'Please specify gender.';
        }

        return errors;
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onAddorEdit} validate={validate}>
                {({ isSubmitting, handleSubmit, values }) => (
                    <div>
                        <div className="row">
                            <div className="col-xs-4">
                                <Field
                                    name="firstName"
                                    component={CccisdInput}
                                    label={
                                        <RequiredFieldLabel isRequired>
                                            First Name:
                                        </RequiredFieldLabel>
                                    }
                                />
                            </div>
                            <div className="col-xs-4">
                                <Field
                                    name="lastName"
                                    component={CccisdInput}
                                    label={
                                        <RequiredFieldLabel isRequired>
                                            Last Name:
                                        </RequiredFieldLabel>
                                    }
                                />
                            </div>
                            <div className="col-xs-4">
                                <Field
                                    name="DOB"
                                    component={CccisdDatepicker}
                                    label={
                                        <RequiredFieldLabel isRequired>
                                            Date of Birth:
                                        </RequiredFieldLabel>
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6">
                                <Field
                                    name="race"
                                    component={CccisdSelect}
                                    label={
                                        <RequiredFieldLabel isRequired>Race:</RequiredFieldLabel>
                                    }
                                    options={[
                                        { value: '', label: '-- Please select a race --' },
                                        ...enumValues.race,
                                    ]}
                                />
                            </div>
                            <div className="col-xs-6">
                                <Field
                                    name="ethnicity"
                                    component={CccisdSelect}
                                    label={
                                        <RequiredFieldLabel isRequired>
                                            Ethnicity:
                                        </RequiredFieldLabel>
                                    }
                                    options={[
                                        { value: '', label: '-- Please select an ethnicity --' },
                                        ...enumValues.ethnicity,
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6">
                                <Field
                                    name="gender"
                                    component={CccisdSelect}
                                    label={
                                        <RequiredFieldLabel isRequired>Gender:</RequiredFieldLabel>
                                    }
                                    options={[
                                        { value: '', label: '-- Please select a gender --' },
                                        ...enumValues.gender,
                                    ]}
                                />
                            </div>
                            {values.gender === 'Other' ? (
                                <div className="col-xs-6">
                                    <Field
                                        name="genderOther"
                                        component={CccisdInput}
                                        label={
                                            <RequiredFieldLabel isRequired>
                                                Other Gender:
                                            </RequiredFieldLabel>
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ClickButton
                                title="Submit"
                                className="btn btn-primary"
                                isLoading={isSubmitting}
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
};

ParticipantForm.propTypes = {
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
};

export default ParticipantForm;
