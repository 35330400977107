import React from 'react';
import Color from 'color';
import { getColor } from 'cccisd-color-picker';
import IconChart from 'cccisd-icons/chart';
import format from 'date-fns/format';
import { Html } from 'cccisd-wysiwyg';

import style from './style.css';

const ReportHeader = props => {
    const fakeData = props.isPreview
        ? {
              groupIds: [0, 1, 2, 3, 4],
          }
        : null;

    const {
        header: {
            title,
            description,
            reportingPeriodText,
            createdDateText,
            siteText,
            levelText,
            reportType,
        },
        body,
        colors,
    } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;

    const { reportHandle } = props;

    if (colors && colors.primary) {
        colors.primary = getColor(colors.primary);
    }

    const getHeaderValues = () => {
        const { data } = props;

        const selectedIds = groupIds ? groupIds.map(Number) : fakeData.groupIds;

        const filteredGroups =
            groupIds.length > 0
                ? data.groups.triplep_siteList.filter(site =>
                      selectedIds.includes(site.group.groupId)
                  )
                : data.groups.triplep_siteList;

        return { filteredGroups };
    };

    const header = getHeaderValues();
    return (
        <>
            <div className={style.header}>
                <div className={style.headerTop}>
                    <div
                        className={style.topLeft}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        {reportHandle === 'parentDemographics' ? (
                            <div style={{ fontSize: '2em', fontWeight: 'bold', lineHeight: '1em' }}>
                                {title}
                            </div>
                        ) : (
                            <div className={style.headerTitle}>{title}</div>
                        )}
                    </div>
                    <div
                        className={style.topCenter}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div
                            className={style.topCenterHighlight}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).darken(0.1),
                                      }
                                    : {}
                            }
                        />
                    </div>
                    <div className={style.topRight} style={colors ? { color: colors.primary } : {}}>
                        <div>
                            <strong>{createdDateText} </strong>
                            {format(Date(), 'MMMM Do, YYYY')}
                        </div>
                    </div>
                </div>
                <div className={style.headerBot}>
                    <div className={style.botLeft}>
                        {reportHandle !== 'parentDemographics' ? (
                            <div
                                className={style.description}
                                style={colors ? { color: colors.primary } : {}}
                            >
                                {description}
                            </div>
                        ) : null}
                    </div>
                    <div
                        className={style.botCenter}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div
                            className={style.botCenterShadow}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).darken(0.1),
                                      }
                                    : {}
                            }
                        />
                        <div
                            className={style.botCenterHighlight}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).darken(0.1),
                                      }
                                    : {}
                            }
                        />
                    </div>
                    <div
                        className={style.botRight}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div>
                            {reportingFrom && reportingTo ? (
                                <>
                                    <strong>{reportingPeriodText} </strong>
                                    {format(reportingFrom, 'MM/DD/YYYY')} <strong>To: </strong>{' '}
                                    {format(reportingTo, 'MM/DD/YYYY')}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            {reportHandle !== 'parentDemographics' ? (
                <div
                    className={style.subheader}
                    style={
                        colors
                            ? {
                                  color: colors.primary,
                              }
                            : {}
                    }
                >
                    <div className={style.subheaderText}>
                        <div>
                            {header.filteredGroups && header.filteredGroups.length === 1 ? (
                                <>
                                    <span style={{ fontWeight: 'bold', fontSize: '1.75em' }}>
                                        {`${siteText} `}
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '1.75em',
                                        }}
                                    >
                                        {header.filteredGroups[0].group.label}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span style={{ fontWeight: 'bold', fontSize: '1.75em' }}>
                                        {`Report Includes ${header.filteredGroups.length} ${siteText}s`}
                                    </span>
                                </>
                            )}
                        </div>
                        <strong>
                            {levelText} {level}
                        </strong>
                    </div>
                    <div
                        className={style.subheaderIcon}
                        style={props.individuals ? { fontSize: '2em' } : {}}
                    >
                        <IconChart />
                    </div>
                </div>
            ) : (
                <div className={style.subheader}>
                    <div className={style.subheaderText}>
                        <div
                            style={{
                                color: colors.primary,
                                fontSize: '1.75em',
                            }}
                        >
                            <strong>
                                {groupIds.length > 0
                                    ? `${header.filteredGroups[0].group.label} ${reportType}`
                                    : `Please select SDO to generate report.`}
                            </strong>
                        </div>
                        <div style={{ color: '#000000' }}>
                            <Html content={body.heading} />
                        </div>
                    </div>

                    <div
                        className={style.subheaderIcon}
                        style={{ color: colors.primary, marginBottom: '0.25em' }}
                    >
                        <IconChart />
                    </div>
                </div>
            )}
        </>
    );
};

export default ReportHeader;
