import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';

import Callout from './Callout';
import style from './style.css';
import calloutQuery from './callout.graphql';

const Appdefs = window.cccisd.appDefs;
const grpL2assignmentId = Appdefs.app.tripleP.grpL2assignmentId;
const grpL3assignmentId = Appdefs.app.tripleP.grpL3assignmentId;

const Component = props => {
    const [caregivers, setCaregivers] = useState(null);
    const [children, setChildren] = useState(null);
    const [children0to5, setChildren0to5] = useState(null);

    const { show } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;
    const fakeData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? groupIds.map(Number) : fakeData.groupIds;
    const assignmentId = level === 'Level 2 Seminar' ? grpL2assignmentId : grpL3assignmentId;
    const sessionDate =
        level === 'Level 2 Seminar' ? 'devTags.L2SeminarDate' : 'devTags.L3DiscussionDate';

    useEffect(() => {
        getLevelData(selectedIds, assignmentId, reportingFrom, reportingTo, sessionDate).then(
            async response => {
                const results = response.data.flows.completedFids;
                const calloutsData = props.convertDataStructure(selectedIds, results);
                setCaregivers(calloutsData.caregivers);
                setChildren(calloutsData.children);
                setChildren0to5(calloutsData.children0to5);
            }
        );
    }, [selectedIds, assignmentId, reportingFrom, reportingTo]);

    const getLevelData = (ids, levelId, from, to, devTag) => {
        return apollo.query({
            query: calloutQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: ids,
                assignmentId: levelId,
                reportingFrom: from,
                reportingTo: to,
                sessionDate: devTag,
            },
        });
    };
    return (
        <>
            {show && (
                <div className={style.callouts}>
                    <>
                        <Callout
                            handle="caregivers"
                            settings={{
                                ...props.settings.caregivers,
                                colors: props.settings.colors,
                            }}
                            data={caregivers}
                        />
                        <Callout
                            handle="children"
                            settings={{
                                ...props.settings.totalChildren,
                                colors: props.settings.colors,
                            }}
                            data={children}
                        />
                        <Callout
                            handle="children0to5"
                            settings={{
                                ...props.settings.children0to5,
                                colors: props.settings.colors,
                            }}
                            data={children0to5 === null ? 'No Data' : children0to5}
                        />
                    </>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: (groupId, results) => {
        if (!groupId) {
            return {
                caregivers: results.totalParents,
                children: results.totalChildren,
                children0to5: results.children0to5,
            };
        }

        return {
            caregivers: results.totalParents,
            children: results.totalChildren,
            children0to5: results.children0to5,
        };
    },
};

export default Component;
