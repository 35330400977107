import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import axios from 'cccisd-axios';

import IconQuestion from 'cccisd-icons/question3';
import IconUserPlus from 'cccisd-icons/user-plus';
import IconPlus from 'cccisd-icons/plus-circle2';

import { styleCaregiverStatus } from '../../../../../miechv/MIECHVClientTable/clientCardHelpers.js';
import AddClientForm from '../AddClientForm/index.js';

const childrensTrust = "Children's Trust";

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const ExistingClient = ({ mcv, tripleP, closeModal, formValues, familyGroupId, sfp }) => {
    const getInitialValues = parent => ({
        role: 'sfp_client',
        firstName: parent.fields.firstName ? parent.fields.firstName : parent.user.firstName,
        lastName: parent.fields.lastName ? parent.fields.lastName : parent.user.lastName,
        group: familyGroupId,
        DOB: parent.fields.DOB,
        username: formValues.username,
        lastFour: parent.fields.lastFour,
        childrensTrustID: parent.fields.childrensTrustID,
        race: parent.fields.race,
        ethnicity: parent.fields.ethnicity,
        gender: parent.fields.gender,
    });

    const onAddClient = async (values, parent) => {
        const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
            role: 'sfp_client',
            activeInFamily: true,

            // if user already exists, use that username
            username: values.existingUser ? null : values.username,
            user: values.existingUser ? values.username : null,

            first_name: values.firstName,
            last_name: values.lastName,
            group: familyGroupId,
            DOB: values.DOB,
            lastFour: values.lastFour,
            phone: values.phone,
            email: values.username,
            relationshipToChildren: values.relationshipToChildren,
            otherRelationship: values.otherRelationship,
            pawnId: parent && parent ? parent.pawn.pawnId : null,
            pawnHash: parent && parent ? parent.pawn.pawnHash : null,
            sameCTID: values.sameCTID,
            childrensTrustID: values.childrensTrustID,
            race: values.race,
            ethnicity: values.ethnicity,
            gender: values.gender,
            genderOther: values.genderOther,
        });

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        closeModal();
        notification('Caregiver added.');
    };

    return (
        <div style={{ marginTop: '1em' }}>
            <div className="alert alert-warning" role="alert">
                <IconQuestion spaceRight />
                We found multiple caregivers with similar info already rostered in {childrensTrust}.
            </div>
            {mcv.length > 0 || tripleP.length > 0 ? (
                <p style={{ marginTop: '0.5em' }}>
                    Use{' '}
                    <button type="button" className="btn btn-primary">
                        <IconUserPlus spaceRight />
                        Roster to SFP
                    </button>{' '}
                    if you&apos;d like to add this existing {childrensTrust} Parent to SFP.
                </p>
            ) : null}
            <ol style={{ paddingLeft: '1em' }}>
                {sfp.map(parent => {
                    return (
                        <li style={{ marginBottom: '1em' }} key={parent.pawn.pawnId}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '33%' }}>
                                    <h3 style={{ color: '#00addc' }}>
                                        {`${parent.user.firstName} ${parent.user.lastName}`}
                                    </h3>
                                    <div>
                                        {childrensTrust} ID:{' '}
                                        <strong>{parent.fields.childrensTrustID}</strong>
                                    </div>
                                    <div>
                                        Date of Birth: <strong>{parent.dobFormatted}</strong>
                                    </div>
                                </div>
                                <div style={{ width: '33%' }}>
                                    <strong style={{ color: '#489052' }}>{parent.program}</strong>
                                    <div style={{ marginTop: '0.5em' }}>
                                        {styleCaregiverStatus(
                                            parent.ancestorGroups.sfp_family.fields.familyStatus
                                        )}
                                        <div style={{ marginTop: '1em' }}>
                                            Family Name:{' '}
                                            <strong>
                                                {parent.ancestorGroups.sfp_family.group.label}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {parent.ancestorGroups.sfp_family.fields.familyStatus ===
                                    'Active' ? (
                                        <Tooltip title="SFP Caregivers whose family has an Active status cannot be added again.">
                                            <button
                                                type="button"
                                                disabled
                                                className="btn btn-primary disabled"
                                            >
                                                <IconUserPlus spaceRight />
                                                Roster to SFP
                                            </button>
                                        </Tooltip>
                                    ) : (
                                        <Modal
                                            trigger={
                                                <button type="button" className="btn btn-primary">
                                                    <IconUserPlus spaceRight />
                                                    Roster to SFP
                                                </button>
                                            }
                                            title="Add Caregiver"
                                            headerStyle={{
                                                backgroundColor: '#f37021',
                                                color: '#fff',
                                            }}
                                            afterClose={() => closeModal()}
                                        >
                                            <AddClientForm
                                                onSubmit={onAddClient}
                                                initialValues={getInitialValues(parent)}
                                            />
                                        </Modal>
                                    )}
                                </div>
                            </div>
                        </li>
                    );
                })}
                {mcv.map(parent => {
                    return (
                        <li style={{ marginBottom: '1em' }} key={parent.pawn.pawnId}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '33%' }}>
                                    <h3 style={{ color: '#00addc' }}>
                                        {`${parent.fields.firstName} ${parent.fields.lastName}`}
                                    </h3>
                                    <div>
                                        {childrensTrust} ID:{' '}
                                        <strong>{parent.fields.childrensTrustID}</strong>
                                    </div>
                                    <div>
                                        Date of Birth: <strong>{parent.dobFormatted}</strong>
                                    </div>
                                </div>
                                <div style={{ width: '33%' }}>
                                    <strong style={{ color: '#c40075' }}>{parent.program}</strong>
                                    <div style={{ marginTop: '0.5em' }}>
                                        {styleCaregiverStatus(parent.fields.caregiverStatus)}
                                        <div style={{ marginTop: '1em' }}>
                                            Site:{' '}
                                            <strong>
                                                {parent.ancestorGroups.site.group.label}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <>
                                    <Tooltip title="Roster to SFP">
                                        <Modal
                                            trigger={
                                                <button type="button" className="btn btn-primary">
                                                    <IconUserPlus spaceRight />
                                                    Roster to SFP
                                                </button>
                                            }
                                            title="Add Caregiver"
                                            headerStyle={{
                                                backgroundColor: '#f37021',
                                                color: '#fff',
                                            }}
                                            afterClose={() => closeModal()}
                                        >
                                            <AddClientForm
                                                onSubmit={onAddClient}
                                                initialValues={getInitialValues(parent)}
                                                sameCTID
                                            />
                                        </Modal>
                                    </Tooltip>
                                </>
                            </div>
                        </li>
                    );
                })}
                {tripleP.map(parent => {
                    return (
                        <li style={{ marginBottom: '1em' }} key={parent.pawn.pawnId}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '33%' }}>
                                    <h3 style={{ color: '#00addc' }}>
                                        {parent.fields.firstName} {parent.fields.lastName}
                                    </h3>
                                    <div>
                                        {childrensTrust} ID:{' '}
                                        <strong>{parent.fields.childrensTrustID}</strong>
                                    </div>
                                    <div>
                                        Date of Birth: <strong>{parent.dobFormatted}</strong>
                                    </div>
                                </div>
                                <div style={{ width: '33%' }}>
                                    <strong style={{ color: '#d7df23' }}>{parent.program}</strong>
                                    <div style={{ marginTop: '1em' }}>
                                        SDO:{' '}
                                        <strong>
                                            {parent.ancestorGroups.triplep_site.group.label}
                                        </strong>
                                    </div>
                                </div>
                                <>
                                    <Tooltip title="Roster to SFP">
                                        <Modal
                                            trigger={
                                                <button type="button" className="btn btn-primary">
                                                    <IconUserPlus spaceRight />
                                                    Roster to SFP
                                                </button>
                                            }
                                            title="Add Caregiver"
                                            headerStyle={{
                                                backgroundColor: '#f37021',
                                                color: '#fff',
                                            }}
                                            afterClose={() => closeModal()}
                                        >
                                            <AddClientForm
                                                onSubmit={onAddClient}
                                                initialValues={getInitialValues(parent)}
                                                sameCTID
                                            />
                                        </Modal>
                                    </Tooltip>
                                </>
                            </div>
                        </li>
                    );
                })}
            </ol>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '66%', color: '#909fa2' }}>
                    Caregiver not listed above? Add them as a new client in {childrensTrust}.
                </div>
                <Modal
                    trigger={
                        <button type="button" className="btn btn-primary">
                            <IconPlus spaceRight />
                            Add Caregiver
                        </button>
                    }
                    title="Add Caregiver"
                    headerStyle={{
                        backgroundColor: '#f37021',
                        color: '#fff',
                    }}
                    afterClose={() => closeModal()}
                >
                    <AddClientForm
                        onSubmit={onAddClient}
                        initialValues={{
                            firstName: formValues.firstName,
                            lastName: formValues.lastName,
                            username: formValues.username,
                            DOB: formValues.DOB,
                        }}
                    />
                </Modal>
            </div>
        </div>
    );
};

ExistingClient.propTypes = {
    sites: PropTypes.array,
    homeVisitors: PropTypes.array,
    mcv: PropTypes.array,
    tripleP: PropTypes.array,
    sfp: PropTypes.array,
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    formValues: PropTypes.object,
    familyGroupId: PropTypes.number,
};

export default ExistingClient;
