import React from 'react';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
// gets the score from the last flow response taken

export default settings => {
    return ({ value, row }) => {
        console.log('I got here');
        console.log('row', row);

        let counties = [];

        value.forEach(item => {
            const val = _get(item, settings.valuePath);

            if (val) {
                counties.push(val);
            }
        });
        const uniqueCounties = _uniq(counties);

        const string = uniqueCounties.join(', ');
        return (
            <div key={row} className="text-center">
                {`${string}`}
            </div>
        );
    };
};
