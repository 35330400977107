import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import FilteredTable from './FilteredTable';
import cardQuery from '../../cardQuery.graphql';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const grpL4 = Appdefs.app.tripleP.grpL4;

const DetailTable = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [session1s, setSession1s] = useState([]);
    const [session2s, setSession2s] = useState([]);
    const [session3s, setSession3s] = useState([]);
    const [session4s, setSession4s] = useState([]);
    const [session5s, setSession5s] = useState([]);
    const [session6s, setSession6s] = useState([]);
    const [session7s, setSession7s] = useState([]);
    const [session8s, setSession8s] = useState([]);

    const { title, description, show, colors } = props.settings;

    const { groupIds, reportingFrom, reportingTo } = props.filters;

    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;

    const selectedIds = groupIds ? groupIds.map(Number) : previewData.groupIds;

    const assignmentId = grpL4.assignmentId;

    useEffect(() => {
        setIsLoading(true);
        getCards(selectedIds).then(response => {
            const allFids = response.data.flows.assignmentProgressList;

            const filtered1s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_1')
            );
            const sess1deploymentIds = filtered1s.map(session => {
                return [session.deployment.deploymentId];
            });
            setSession1s(sess1deploymentIds.flat());

            const filtered2s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_2')
            );
            const sess2deploymentIds = filtered2s.map(session => {
                return [session.deployment.deploymentId];
            });
            setSession2s(sess2deploymentIds.flat());

            const filtered3s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_3')
            );
            const sess3deploymentIds = filtered3s.map(session => {
                return [session.deployment.deploymentId];
            });
            setSession3s(sess3deploymentIds.flat());

            const filtered4s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_4')
            );
            const sess4deploymentIds = filtered4s.map(session => {
                return [session.deployment.deploymentId];
            });
            setSession4s(sess4deploymentIds.flat());

            const filtered5s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_5')
            );
            const sess5deploymentIds = filtered5s.map(session => {
                return [session.deployment.deploymentId];
            });
            setSession5s(sess5deploymentIds.flat());

            const filtered6s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_6')
            );
            const sess6deploymentIds = filtered6s.map(session => {
                return [session.deployment.deploymentId];
            });
            setSession6s(sess6deploymentIds.flat());

            const filtered7s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_7')
            );
            const sess7deploymentIds = filtered7s.map(session => {
                return [session.deployment.deploymentId];
            });
            setSession7s(sess7deploymentIds.flat());

            const filtered8s = allFids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_8')
            );
            const sess8deploymentIds = filtered8s.map(session => {
                return [session.deployment.deploymentId];
            });
            setSession8s(sess8deploymentIds.flat());
        });

        setIsLoading(false);
    }, [groupIds, reportingFrom, reportingTo]);

    const getCards = ids => {
        return apollo.query({
            query: cardQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: ids,
                assignmentId,
                reportingFrom,
                reportingTo,
            },
        });
    };

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <strong
                        style={
                            colors
                                ? {
                                      color: colors.primary,
                                      fontSize: '1.5em',
                                      marginBottom: '1em',
                                  }
                                : { fontSize: '1.5em', marginBottom: '1em' }
                        }
                    >
                        {title}
                    </strong>
                    <div className={style.description}>{description}</div>
                    <Loader loading={isLoading}>
                        <FilteredTable
                            groupId={selectedIds}
                            assignmentId={assignmentId}
                            session1s={session1s.flat()}
                            session2s={session2s.flat()}
                            session3s={session3s.flat()}
                            session4s={session4s.flat()}
                            session5s={session5s.flat()}
                            session6s={session6s.flat()}
                            session7s={session7s.flat()}
                            session8s={session8s.flat()}
                        />
                    </Loader>
                </div>
            )}
        </>
    );
};

DetailTable.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

DetailTable.defaultProps = {
    filters: {},
};

export default DetailTable;
