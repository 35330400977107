import React from 'react';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';

import style from '../style.css';
import IconUser from 'cccisd-icons/user3';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;

const BusinessCard = props => {
    const loginAs = async user => {
        const response = await axios.post(Boilerplate.route('api.nexus.user.changeTo'), user);
        if (response.data.status === 'success') {
            window.location.href = Boilerplate.url(Fortress.settings.config.after_login_url);
        }
    };

    const { label, pawnData, color, appName } = props;

    return (
        <div className={style.businessCard} style={{ backgroundColor: color }}>
            <div className={style.container} style={{ color }}>
                <div className={style.icon}>
                    <IconUser />
                    <div style={{ fontSize: '16px' }}>{appName}</div>
                </div>
                <div className={style.credentials}>
                    {label}
                    <div style={{ marginTop: '15%' }}>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                                loginAs({
                                    pawn: pawnData.pawnId,
                                    pawnHash: pawnData.pawnHash,
                                })
                            }
                        >
                            Select
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

BusinessCard.propTypes = {
    label: PropTypes.string,
    pawnData: PropTypes.object,
    color: PropTypes.string,
    appName: PropTypes.string,
};

export default BusinessCard;
