import React from 'react';
import PropTypes from 'prop-types';
import { Html } from 'cccisd-wysiwyg';

const Component = props => {
    const { title, footnotes, colors, show } = props.settings;

    return (
        <>
            {show && (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <strong
                        style={{
                            borderBottom: colors ? `1px solid ${colors.primary}` : null,
                            color: colors ? colors.primary : null,
                        }}
                    >
                        <Html content={title} />
                    </strong>
                    <Html content={footnotes} />
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

export default Component;
