import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import Chart from './Chart';

import summaryChartQuery from './summaryChart.graphql';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const indL4 = Appdefs.app.tripleP.indL4;

const SummaryChart = props => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const { title, description, show, colors } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;

    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;

    const selectedIds = groupIds ? groupIds.map(Number) : previewData.groupIds;

    const assignmentId = indL4.assignmentId;

    const requiredContentHandle = indL4.requiredContentHandle;

    const completedContentHandle = indL4.completedContentHandle;

    useEffect(() => {
        (async () => {
            setLoading(true);
            getLevelData(
                selectedIds,
                assignmentId,
                reportingFrom,
                reportingTo,
                requiredContentHandle,
                completedContentHandle
            ).then(async response => {
                const results = response.data.groups;
                setData(results);
            });
            setLoading(false);
        })();
    }, [selectedIds, reportingFrom, reportingTo]);

    const getLevelData = ids => {
        return apollo.query({
            query: summaryChartQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: ids,
                assignmentId,
                reportingFrom,
                reportingTo,
                requiredContentHandle,
                completedContentHandle,
            },
        });
    };

    const rowsRendered =
        data &&
        data.triplep_siteList.map(site => {
            return {
                id: site.group.groupId,
                sdo: site.label,
                'Number of Clients Finishing Required Content': site.childRoles.required.toString(),
                'Number of Clients Completing Service': site.childRoles.completed.toString(),
            };
        });

    const nivoFakeData = [
        {
            id: 1,
            sdo: 'Black River United Way',
            'Number of Clients Finishing Required Content': 5,
            'Number of Clients Completing Service': 10,
        },
    ];

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <div className={style.title}>
                        <strong
                            style={
                                colors
                                    ? {
                                          color: colors.primary,
                                          fontSize: '1.5em',
                                      }
                                    : { fontSize: '1.5em' }
                            }
                        >
                            {level ? level : 'Level 4 Standard'} {title}
                        </strong>
                        <div>{description}</div>
                    </div>
                    <Loader loading={loading}>
                        <div style={{ height: '500px', width: '100%' }}>
                            <Chart data={data ? rowsRendered : nivoFakeData} />
                        </div>
                    </Loader>
                </div>
            )}
        </>
    );
};

SummaryChart.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

SummaryChart.defaultProps = {
    filters: {},
};

export default SummaryChart;
