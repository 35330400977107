import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import childrenTableQuery from './childrenTable.graphql';
import levelsQuery from '../../../levels.graphql';

import TableAndChart from '../..';
import style from '../../style.css';
import { getChildrenTotal, initialLoadMessage } from '../../../../../../reportHelpers.js';

const Appdefs = window.cccisd.appDefs;
const grpL4 = Appdefs.app.tripleP.grpL4;

const Children = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [rowList, setRowList] = useState([]);

    const { description, show, colors, title } = props.settings;

    const { groupIds, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? Number(groupIds) : previewData.groupIds;

    useEffect(() => {
        setIsLoading(true);

        getSiteANDgrpL4().then(async response => {
            const site = await response.data.groups.triplep_site;
            const grpL4Fids = await response.data.flows.assignmentProgressList;

            const allIds = grpL4Fids.map(card => {
                return [card.deployment.deploymentId];
            });
            const allSessions = allIds.flat();

            getTable({
                allSessions: allSessions.length > 0 ? allSessions : 0,
            }).then(async res => {
                const grpL2Total = res.data.flows.grpL2.totalChildren;
                const grpL3Total = res.data.flows.grpL3.totalChildren;

                const indL3 = res.data.flows.indL3;
                const indL3Total = getChildrenTotal(indL3);

                const indL4 = res.data.flows.indL4;
                const indL4Total = getChildrenTotal(indL4);

                const grpL4Total = res.data.groups.siteTotal.totalChildren;

                await getRows({
                    site,
                    grpL2Total: grpL2Total !== null ? grpL2Total : 'No Data',
                    grpL3Total: grpL3Total !== null ? grpL3Total : 'No Data',
                    indL3Total,
                    indL4Total,
                    grpL4Total,
                });
            });
            setIsLoading(false);
        });
    }, [selectedIds, reportingFrom, reportingTo]);

    const getSiteANDgrpL4 = async () => {
        const response = await apollo.query({
            query: levelsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                reportingFrom,
                reportingTo,
                grpL4assignmentId: grpL4.assignmentId,
            },
        });
        return response;
    };

    const getTable = async ({ allSessions }) => {
        const response = await apollo.query({
            query: childrenTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                reportingFrom,
                reportingTo,
                allSessions,
                grpL4assignmentId: grpL4.assignmentId,
            },
        });
        return response;
    };

    const columns = [
        {
            name: 'label',
            label: 'Children Impacted',
        },
        {
            name: 'value',
            label: 'Total',
            class: 'text-center',
            setStyle: () => {
                return { width: '25%' };
            },
        },
    ];

    const getRows = ({ site, grpL2Total, grpL3Total, indL3Total, indL4Total, grpL4Total }) => {
        if (site.length < 1 || site.group.groupId === null) {
            return null;
        }

        const siteServices = {
            grpL2: site.fields.grpL2,
            grpL3: site.fields.grpL3,
            indL3: site.fields.indL3,
            indL4: site.fields.indL4,
            grpL4: site.fields.grpL4,
        };

        const rows = [
            {
                id: 'grpL2',
                label: 'Level 2 Seminar',
                value: grpL2Total,
            },
            {
                id: 'grpL3',
                label: 'Level 3 Discussion',
                value: grpL3Total,
            },
            {
                id: 'indL3',
                label: 'Level 3 Primary Care',
                value: indL3Total,
            },
            {
                id: 'indL4',
                label: 'Level 4 Standard',
                value: indL4Total,
            },
            {
                id: 'grpL4',
                label: 'Level 4 Group',
                value: grpL4Total,
            },
        ].filter(row => {
            if (siteServices[row.id] === true) {
                return true;
            }
            return false;
        });
        setRowList(rows);
    };

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <strong
                        style={{
                            borderBottom: colors ? `1px solid ${colors.primary}` : null,
                            color: colors ? colors.primary : null,
                        }}
                    >
                        {title}
                    </strong>
                    <div className={style.description}>{description}</div>
                    {selectedIds === 0 ? (
                        initialLoadMessage
                    ) : (
                        <Loader loading={isLoading} removeChildren>
                            <TableAndChart
                                columns={columns}
                                rows={rowList}
                                tableName="Children Served"
                                chartData={rowList}
                            />
                        </Loader>
                    )}
                </div>
            )}
        </>
    );
};

Children.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Children.defaultProps = {
    filters: {},
};

export default Children;
