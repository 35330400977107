import Footnotes from './widgets/Footnotes';
import attendanceCallout from './widgets/AttendanceCallout';
import fidelityCallout from './widgets/FidelityCallout';
import reachSummaryTable from './widgets/reachSummaryTable';
import reachSummaryVisualize from './widgets/reachSummaryVisualize';
import referralChart from './widgets/referralChart';
import logComments from './widgets/logComments';

export default [
    attendanceCallout,
    fidelityCallout,
    referralChart,
    reachSummaryTable,
    logComments,
    reachSummaryVisualize,
    Footnotes,
];
