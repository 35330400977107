import React from 'react';
import _get from 'lodash/get';

export default settings => {
    return ({ row }) => {
        const column = settings.columnName;
        const byModel = _get(row, 'childRoles.clientSummary.byModel');

        if (byModel === null) {
            return null;
        }

        const showHFAtotals = () => {
            switch (column) {
                case 'familyFunctioning':
                    if (
                        row['childRoles.hfa.familyFunctioning_before'] === null ||
                        row['childRoles.hfa.familyFunctioning_now'] === null
                    ) {
                        return null;
                    }
                    return (
                        <div>
                            Before{' '}
                            <strong>
                                {row['childRoles.hfa.familyFunctioning_before'].toFixed(2)}
                            </strong>{' '}
                            Now{' '}
                            <strong>
                                {' '}
                                {row['childRoles.hfa.familyFunctioning_now'].toFixed(2)}
                            </strong>
                        </div>
                    );
                case 'nurturing':
                    if (
                        row['childRoles.hfa.nurturing_before'] === null ||
                        row['childRoles.hfa.nurturing_now'] === null
                    ) {
                        return null;
                    }
                    return (
                        <div>
                            Before{' '}
                            <strong>{row['childRoles.hfa.nurturing_before'].toFixed(2)}</strong> Now{' '}
                            <strong>{row['childRoles.hfa.nurturing_now'].toFixed(2)}</strong>
                        </div>
                    );
                case 'support':
                    if (
                        row['childRoles.hfa.support_before'] === null ||
                        row['childRoles.hfa.support_now'] === null
                    ) {
                        return null;
                    }
                    return (
                        <div>
                            Before{' '}
                            <strong>{row['childRoles.hfa.support_before'].toFixed(2)}</strong> Now{' '}
                            <strong>{row['childRoles.hfa.support_now'].toFixed(2)}</strong>
                        </div>
                    );
                case 'positiveParenting':
                    if (
                        row['childRoles.hfa.positiveParenting_before'] === null ||
                        row['childRoles.hfa.positiveParenting_now'] === null
                    ) {
                        return null;
                    }
                    // return row['childRoles.hfa.positiveParenting_before'];
                    return (
                        <div>
                            Before{' '}
                            <strong>
                                {row['childRoles.hfa.positiveParenting_before'].toFixed(2)}
                            </strong>{' '}
                            Now{' '}
                            <strong>
                                {row['childRoles.hfa.positiveParenting_now'].toFixed(2)}
                            </strong>
                        </div>
                    );
                default:
                    return null;
            }
        };

        const showNFPtotals = () => {
            switch (column) {
                case 'familyFunctioning':
                    if (
                        row['childRoles.nfp.familyFunctioning_before'] === null ||
                        row['childRoles.nfp.familyFunctioning_now'] === null
                    ) {
                        return null;
                    }
                    return (
                        <div>
                            Before{' '}
                            <strong>
                                {row['childRoles.nfp.familyFunctioning_before'].toFixed(2)}
                            </strong>{' '}
                            Now{' '}
                            <strong>
                                {' '}
                                {row['childRoles.nfp.familyFunctioning_now'].toFixed(2)}
                            </strong>
                        </div>
                    );
                case 'nurturing':
                    if (
                        row['childRoles.nfp.nurturing_before'] === null ||
                        row['childRoles.nfp.nurturing_now'] === null
                    ) {
                        return null;
                    }
                    return (
                        <div>
                            Before{' '}
                            <strong>{row['childRoles.nfp.nurturing_before'].toFixed(2)}</strong> Now{' '}
                            <strong>{row['childRoles.nfp.nurturing_now'].toFixed(2)}</strong>
                        </div>
                    );
                case 'support':
                    if (
                        row['childRoles.nfp.support_before'] === null ||
                        row['childRoles.nfp.support_now'] === null
                    ) {
                        return null;
                    }
                    return (
                        <div>
                            Before{' '}
                            <strong>{row['childRoles.nfp.support_before'].toFixed(2)}</strong> Now{' '}
                            <strong>{row['childRoles.nfp.support_now'].toFixed(2)}</strong>
                        </div>
                    );
                case 'positiveParenting':
                    if (
                        row['childRoles.nfp.positiveParenting_before'] === null ||
                        row['childRoles.nfp.positiveParenting_now'] === null
                    ) {
                        return null;
                    }
                    return (
                        <div>
                            Before{' '}
                            <strong>
                                {row['childRoles.nfp.positiveParenting_before'].toFixed(2)}
                            </strong>{' '}
                            Now{' '}
                            <strong>
                                {row['childRoles.nfp.positiveParenting_now'].toFixed(2)}
                            </strong>
                        </div>
                    );
                default:
                    return null;
            }
        };

        const showPATtotals = () => {
            switch (column) {
                case 'familyFunctioning':
                    if (
                        row['childRoles.pat.familyFunctioning_before'] === null ||
                        row['childRoles.pat.familyFunctioning_now'] === null
                    ) {
                        return null;
                    }
                    return (
                        <div>
                            Before{' '}
                            <strong>
                                {row['childRoles.pat.familyFunctioning_before'].toFixed(2)}
                            </strong>{' '}
                            Now{' '}
                            <strong>
                                {' '}
                                {row['childRoles.pat.familyFunctioning_now'].toFixed(2)}
                            </strong>
                        </div>
                    );
                case 'nurturing':
                    if (
                        row['childRoles.pat.nurturing_before'] === null ||
                        row['childRoles.pat.nurturing_now'] === null
                    ) {
                        return null;
                    }
                    return (
                        <div>
                            Before{' '}
                            <strong>{row['childRoles.pat.nurturing_before'].toFixed(2)}</strong> Now{' '}
                            <strong>{row['childRoles.pat.nurturing_now'].toFixed(2)}</strong>
                        </div>
                    );
                case 'support':
                    if (
                        row['childRoles.pat.support_before'] === null ||
                        row['childRoles.pat.support_now'] === null
                    ) {
                        return null;
                    }
                    return (
                        <div>
                            Before{' '}
                            <strong>{row['childRoles.pat.support_before'].toFixed(2)}</strong> Now{' '}
                            <strong>{row['childRoles.pat.support_now'].toFixed(2)}</strong>
                        </div>
                    );
                case 'positiveParenting':
                    if (
                        row['childRoles.pat.positiveParenting_before'] === null ||
                        row['childRoles.pat.positiveParenting_now'] === null
                    ) {
                        return null;
                    }
                    return (
                        <div>
                            Before{' '}
                            <strong>
                                {row['childRoles.pat.positiveParenting_before'].toFixed(2)}
                            </strong>{' '}
                            Now{' '}
                            <strong>
                                {row['childRoles.pat.positiveParenting_now'].toFixed(2)}
                            </strong>
                        </div>
                    );
                default:
                    return null;
            }
        };

        return (
            <div
                style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                }}
            >
                {row['fields.HFAmodel'] && byModel.length > 0 ? showHFAtotals() : null}
                {row['fields.NFPmodel'] && byModel.length > 0 ? showNFPtotals() : null}
                {row['fields.PATmodel'] && byModel.length > 0 ? showPATtotals() : null}
            </div>
        );
    };
};
