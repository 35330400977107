import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const MyResponsiveBar = props => {
    return (
        <ResponsiveBar
            data={props.data}
            groupMode="grouped"
            layout="horizontal"
            keys={['S1', 'S2', 'S3', 'S4', 'S5', 'S6', 'S7', 'S8', 'S9', 'S10']}
            indexBy="sdo"
            margin={{ top: 20, right: 20, bottom: 75, left: 200 }}
            colors={[
                '#a14208',
                '#c14f0a',
                '#e15c0c',
                '#f26d1d',
                '#f4823d',
                '#f6975d',
                '#f8ab7d',
                '#f9c09e',
                '#fbd5be',
                '#fdeade',
            ]}
            axisBottom={{
                tickSize: 0,
                tickPadding: 3,
                tickRotation: 0,
            }}
            label={d => `${d.value}`}
            axisLeft={{
                tickSize: 0,
                tickPadding: 3,
                tickRotation: -25,
            }}
            labelTextColor="#ffffff"
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 70,
                    itemsSpacing: 10,
                    itemWidth: 45,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 10,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    );
};

export default MyResponsiveBar;
