import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import _get from 'lodash/get';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import style from './style.css';

import flowInfoQuery from './flowInfo.graphql';

export default class QuestPreviewList extends React.Component {
    previewFlowHandles = ['SummitEval', 'ProtectiveFactors', 'ROLES', 'ACE', 'TrainingEval'];

    state = {
        isLoading: true,
        previewFlows: [],
    };

    /* /////////////////////////////////////////////////////////////////////////
    // REACT LIFECYCLE METHODS ////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    componentDidMount() {
        this.setState({
            isLoading: false,
        });
        // this.loadFlowInfo();
    }

    /* /////////////////////////////////////////////////////////////////////////
    // CLASS METHODS //////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    loadFlowInfo = async () => {
        let result = await apollo.query({
            query: flowInfoQuery,
            variables: { flowHandles: this.previewFlowHandles },
            fetchPolicy: 'network-only',
        });

        this.setState({
            isLoading: false,
            previewFlows: _get(result, 'data.flows.flowList', []),
        });
    };

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    render() {
        const { isLoading, previewFlows } = this.state;

        if (isLoading) {
            return <Loader loading />;
        }

        let previewList = [];
        previewFlows.forEach(flowObj => {
            previewList.push(
                <li key={flowObj.flowId}>
                    <Link to={`preview?frame=appDefault&id=${flowObj.flowId}`} target="_blank">
                        {flowObj.name}
                    </Link>
                </li>
            );
        }, this);

        return (
            <div className={style.wrapper}>
                <div>
                    <h2>Quests</h2>
                    <p>There are no Quests to preview.</p>
                    <ul>{previewList}</ul>
                </div>
            </div>
        );
    }
}
