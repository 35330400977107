import Callout from './widgets/Callout';
import Parents from './widgets/TableAndChart/Parents';
import Children from './widgets/TableAndChart/Children';
import referralChart from './widgets/referralChart';
import Race from './widgets/TableAndChart/Race';
import Language from './widgets/TableAndChart/Language';
import Age from './widgets/TableAndChart/Age';
import Footnotes from './widgets/Footnotes';

export default [Callout, Parents, Children, Age, Race, Language, Footnotes, referralChart];
