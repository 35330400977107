import React from 'react';
import { format, addDays } from 'date-fns';

export default settings => {
    return ({ row }) => {
        const renderDate = () => {
            if (row[`childRoles.metricspawn.${settings.visit}.devTags.VisitDate`]) {
                return (
                    <>
                        {format(
                            row[`childRoles.metricspawn.${settings.visit}.devTags.VisitDate`],
                            'MM/DD/YYYY'
                        )}
                    </>
                );
            }
            if (row[`childRoles.metricspawn.preImplementation.devTags.${settings.plannedVisit}`]) {
                return (
                    <>
                        <i>Planned </i>
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {format(
                                row[
                                    `childRoles.metricspawn.preImplementation.devTags.${settings.plannedVisit}`
                                ],
                                'MM/DD/YYYY'
                            )}
                        </div>
                    </>
                );
            }
            const graduated = row['childRoles.metricspawn.graduation.devTags.VisitDate'];
            const plannedGradDate =
                row['childRoles.metricspawn.preImplementation.devTags.PlannedGraduation'];

            if (graduated && settings.visit === 'booster90') {
                const unformattedBooster = addDays(graduated, 90);
                const tentative90DayBooster = format(unformattedBooster, 'MM/DD/YYYY');
                return (
                    <>
                        <i>Planned </i>
                        <div style={{ whiteSpace: 'nowrap' }}>{tentative90DayBooster}</div>
                    </>
                );
            }
            if (settings.visit === 'booster90' && plannedGradDate && !graduated) {
                const unformattedBooster = addDays(plannedGradDate, 90);
                const tentative90DayBooster = format(unformattedBooster, 'MM/DD/YYYY');
                return (
                    <>
                        <i>Planned </i>
                        <div style={{ whiteSpace: 'nowrap' }}>{tentative90DayBooster}</div>
                    </>
                );
            }
            return null;
        };

        return <div>{renderDate()}</div>;
    };
};
