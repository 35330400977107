import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';

import Callout from './Callout';
import style from './style.css';
import calloutQuery from './callout.graphql';

const Appdefs = window.cccisd.appDefs;
const grpL2assignmentId = Appdefs.app.tripleP.grpL2assignmentId;
const grpL3assignmentId = Appdefs.app.tripleP.grpL3assignmentId;

const Component = props => {
    const [fidelity, setFidelity] = useState(null);
    const [modification, setModification] = useState(null);
    const [adaptation, setAdaptation] = useState(null);

    const { show } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;
    const fakeData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? groupIds.map(Number) : fakeData.groupIds;
    const assignmentId = level === 'Level 2 Seminar' ? grpL2assignmentId : grpL3assignmentId;
    const sessionDate =
        level === 'Level 2 Seminar' ? 'devTags.L2SeminarDate' : 'devTags.L3DiscussionDate';

    useEffect(() => {
        getLevelData(selectedIds, assignmentId, reportingFrom, reportingTo, sessionDate).then(
            async response => {
                const results = response.data.flows.completedFids;
                const calloutsData = props.convertDataStructure(selectedIds, results);
                setFidelity(calloutsData.fidelity);
                setModification(calloutsData.modification);
                setAdaptation(calloutsData.adaptation);
            }
        );
    }, [selectedIds, assignmentId, reportingFrom, reportingTo]);

    const getLevelData = (ids, levelId, from, to, devTag) => {
        return apollo.query({
            query: calloutQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: ids,
                assignmentId: levelId,
                reportingFrom: from,
                reportingTo: to,
                sessionDate: devTag,
            },
        });
    };
    return (
        <>
            {show && (
                <div className={style.callouts}>
                    <>
                        <Callout
                            handle="fidelity"
                            settings={{
                                ...props.settings.fidelity,
                                colors: props.settings.colors,
                            }}
                            data={fidelity}
                        />
                        <Callout
                            handle="modification"
                            settings={{
                                ...props.settings.modification,
                                colors: props.settings.colors,
                            }}
                            data={modification}
                        />
                        <Callout
                            handle="adaptation"
                            settings={{
                                ...props.settings.adaptation,
                                colors: props.settings.colors,
                            }}
                            data={adaptation === null ? 'No Data' : adaptation}
                        />
                    </>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: (groupId, results) => {
        if (!groupId) {
            return {
                fidelity: results.totalFidelity,
                modification: results.totalModification,
                adaptation: results.totalAdaptation,
            };
        }

        return {
            fidelity: results.totalFidelity,
            modification: results.totalModification,
            adaptation: results.totalAdaptation,
        };
    },
};

export default Component;
