import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Table from 'cccisd-graphql-table';
import CccisdToggle from 'cccisd-toggle';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import Renders from 'cccisd-table-renders';

import clientsQuery from './clients.graphql';
import providerClientsQuery from './providerClients.graphql';
import CTCheck from '../CTCheck';
import style from '../style.css';
import AddClientForm from '../CTCheck/AddClientForm';

import IconPlus from 'cccisd-icons/plus-circle2';
import IconPencil from 'cccisd-icons/pencil';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const ClientTable = ({ familyGroupId, familyName, isProvider, providerPawnId }) => {
    const tableRef = useRef();

    const updateStatus = async row => {
        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', {
                pawn: row['pawn.pawnId'],
            }),
            {
                activeInFamily: !row['fields.activeInFamily'],
                pawnHash: row['pawn.pawnHash'],
                first_name: row['user.firstName'],
                last_name: row['user.lastName'],
                DOB: row['fields.DOB'],
                sameCTID: true,
                childrensTrustID: row['fields.childrensTrustID'],
            }
        );

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        tableRef.current.loadData();
        notification('Caregiver edited.');
    };

    const getInitialValues = row => ({
        firstName: row['user.firstName'],
        lastName: row['user.lastName'],
        pawnHash: row['pawn.pawnHash'],
        group: familyGroupId,
        DOB: row['fields.DOB'],
        race: row['fields.race'],
        ethnicity: row['fields.ethnicity'],
        username: row['user.username'],
        lastFour: row['fields.lastFour'],
        phone: row['user.phone'],
        relationshipToChildren: row['fields.relationshipToChildren'],
        otherRelationship: row['fields.otherRelationship'],
        childrensTrustID: row['fields.childrensTrustID'],
        familyName,
        gender: row['fields.gender'],
        genderOther: row['fields.genderOther'],
    });

    const onEditClient = async (pawnId, values) => {
        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', {
                pawn: pawnId,
            }),
            {
                role: 'sfp_client',
                first_name: values.firstName,
                last_name: values.lastName,
                pawnHash: values.pawnHash,
                DOB: values.DOB,
                lastFour: values.lastFour,
                phone: values.phone,
                email: values.username,
                sameCTID: true,
                childrensTrustID: values.childrensTrustID,
                relationshipToChildren: values.relationshipToChildren,
                otherRelationship: values.otherRelationship,
                familyName,
                race: values.race,
                ethnicity: values.ethnicity,
                gender: values.gender,
                genderOther: values.genderOther,
            }
        );

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        tableRef.current.loadData();
        notification('Caregiver edited.');
    };

    const columns = [
        {
            name: 'fields.activeInFamily',
            label: 'Participating',
            hideInCsv: true,
            sort: true,
            render: ({ row }) => {
                return (
                    <div className={style.actionButton}>
                        <Tooltip title="By turning ‘on’ the Participating toggle button, the associated Caregiver or Youth is considered an active participant in the SFP program and will be reflected in the Session Data tab, the Session Planning Dashboards, and in reports that reflect participation in SFP programs">
                            <CccisdToggle
                                value={row['fields.activeInFamily']}
                                onChange={() => updateStatus(row)}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            name: 'edit',
            label: 'Edit',
            hideInCsv: true,
            render: ({ row }) => {
                return (
                    <div className={style.actionButton}>
                        <Tooltip title="Edit">
                            <Modal
                                trigger={
                                    <button type="button" className="btn-xs btn-success">
                                        <IconPencil />
                                    </button>
                                }
                                title={
                                    row['fields.childrensTrustID']
                                        ? `Edit ${row['fields.childrensTrustID']}`
                                        : `Edit Caregiver`
                                }
                                headerStyle={{
                                    backgroundColor: '#f37021',
                                    color: '#fff',
                                }}
                            >
                                <AddClientForm
                                    initialValues={getInitialValues(row)}
                                    onSubmit={onEditClient.bind(this, row['pawn.pawnId'])}
                                />
                            </Modal>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            name: 'fields.activeInFamily',
            label: 'Participating in Family',
            hideInTable: true,
        },
        { name: 'user.firstName', label: 'First Name', filter: true, sort: true },
        { name: 'user.lastName', label: 'Last Name', filter: true, sort: true },
        { name: 'user.username', label: 'Username', filter: true, sort: true },
        {
            name: 'fields.childrensTrustID',
            label: 'CTID',
            filter: true,
            sort: true,
        },
        {
            name: 'fields.lastFour',
            label: 'SSN (Last Four)',
            hideInTable: true,
        },
        {
            name: 'user.phone',
            label: 'Phone Number',
            hideInTable: true,
        },
        {
            name: 'fields.race',
            label: 'Race',
            hideInTable: true,
        },
        {
            name: 'fields.ethnicity',
            label: 'Ethnicity',
            hideInTable: true,
        },
        {
            name: 'fields.gender',
            label: 'Gender',
            hideInTable: true,
        },
        {
            name: 'fields.genderOther',
            label: 'Other Gender Specified',
            hideInTable: true,
        },
        {
            name: 'fields.relationshipToChildren',
            label: 'Relationship to Child(ren)',
            hideInTable: true,
        },
        {
            name: 'fields.otherRelationship',
            label: 'Other Relationship to Child(ren)',
            hideInTable: true,
        },
        {
            name: 'user.lastLogin',
            label: 'Last Login',
            filter: true,
            sort: true,
            setStyle: () => {
                return { width: '15%' };
            },
            filterSettings: { type: 'date' },
            render: ({ row, value }) => {
                if (row['fields.activeInFamily'] !== true) {
                    return null;
                }
                return Renders.html.LastLogin({ row })({ value, row });
            },
        },
    ];

    return (
        <>
            <div style={{ marginTop: '1em', marginBottom: '1em' }}>
                <Tooltip title={`Add Caregiver to ${familyName} Family`}>
                    <Modal
                        trigger={
                            <button
                                type="button"
                                className={classnames(style.button, `btn btn-primary`)}
                            >
                                <IconPlus spaceRight />
                                Add Caregiver
                            </button>
                        }
                        title="Add Caregiver"
                        headerStyle={{
                            backgroundColor: '#f37021',
                            color: '#fff',
                        }}
                        formAutoFocus
                        afterClose={() => tableRef.current.loadData()}
                    >
                        <CTCheck familyGroupId={familyGroupId} familyName={familyName} />
                    </Modal>
                </Tooltip>
            </div>
            <Table
                columns={columns}
                query={isProvider ? providerClientsQuery : clientsQuery}
                rowKey="pawn.pawnId"
                graphqlVariables={{
                    groupId: familyGroupId,
                    pawnId: providerPawnId,
                }}
                orderBy="fields.activeInFamily"
                isAscentOrder={false}
                ref={tableRef}
                csvFilename={`Caregivers in ${familyName} Family ID-${familyGroupId}.csv`}
                noRecordsMessage="No Caregivers are rostered to this family yet."
            />
        </>
    );
};

ClientTable.propTypes = {
    familyGroupId: PropTypes.number,
    familyName: PropTypes.string,
    isProvider: PropTypes.bool,
    providerPawnId: PropTypes.number,
};

export default ClientTable;
