import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import pawnIdsAndDeploymentIdsQuery from './pawnIdsAndDeploymentIds.graphql';
import raceTableQuery from './raceTable.graphql';

import style from '../../style.css';
import { initialLoadMessage } from '../../../../../../reportHelpers.js';
import TableAndChart from '../..';

const Appdefs = window.cccisd.appDefs;
const grpL4 = Appdefs.app.tripleP.grpL4;

const Race = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [tableRows, setTableRows] = useState([]);

    const { description, show, colors, title } = props.settings;

    const { groupIds, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? Number(groupIds) : previewData.groupIds;

    useEffect(() => {
        setIsLoading(true);

        getPawnIdsAndDeploymentIds().then(async response => {
            const grpLevels = await response.data.flows;
            const indLevels = await response.data.roles;

            const pawnIdsGrpL2 = grpLevels.grpL2.map(sem => {
                return [...sem.additionalInfo.pawnIds];
            });

            const grpL2PawnIds = pawnIdsGrpL2.flat();

            const pawnIdsGrpL3 = grpLevels.grpL3.map(sem => {
                return [...sem.additionalInfo.pawnIds];
            });

            const grpL3PawnIds = pawnIdsGrpL3.flat();

            const indL3PawnIds = [];
            indLevels.indL3.map(car => indL3PawnIds.push(car.pawn.pawnId));

            const indL4PawnIds = [];
            indLevels.indL4.map(car => indL4PawnIds.push(car.pawn.pawnId));

            const allPawnIds = [...new Set(grpL2PawnIds, grpL3PawnIds, indL3PawnIds, indL4PawnIds)];

            const grpL4Deployments = await response.data.flows.grpL4;

            const grpL4DeploymentIds = grpL4Deployments.map(card => {
                return [card.deployment.deploymentId];
            });

            const allIds = grpL4DeploymentIds.flat();

            getTable({
                allSessions: allIds.length > 0 ? allIds : 0,
                pawnIds: allPawnIds.length > 0 ? allPawnIds : 0,
            }).then(async res => {
                const allLevels = await res.data.roles.triplep_parentSummary.races;

                if (allLevels !== null) {
                    const buildTable = allLevels.map(race => {
                        if (race.value === null) {
                            return {
                                id: 'No Answer',
                                race: 'No Answer',
                                total: race.valueCount,
                                label: 'No Answer',
                                value: race.valueCount,
                            };
                        }
                        if (race.value === 'American Indian/Alaska Native') {
                            return {
                                id: race.value,
                                race: race.value,
                                total: race.valueCount,
                                label: 'American Indian',
                                value: race.valueCount,
                            };
                        }
                        return {
                            id: race.value,
                            race: race.value,
                            total: race.valueCount,
                            label: race.value,
                            value: race.valueCount,
                        };
                    });

                    if (buildTable.length > 0 && buildTable[0].id === 'No Answer') {
                        buildTable.push(buildTable.shift());
                    }

                    setTableRows(buildTable);

                    setIsLoading(false);
                }

                setIsLoading(false);
            });
        });
    }, [selectedIds, reportingFrom, reportingTo]);

    const getPawnIdsAndDeploymentIds = async () => {
        const response = await apollo.query({
            query: pawnIdsAndDeploymentIdsQuery,
            fetchPolicy: 'network-only',
            variables: {
                reportingFrom,
                reportingTo,
                groupId: selectedIds,
                grpL4assignmentId: grpL4.assignmentId,
            },
        });
        return response;
    };

    const getTable = async ({ allSessions, pawnIds }) => {
        const response = await apollo.query({
            query: raceTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnIds,
                grpL4assignmentId: grpL4.assignmentId,
                allSessions,
            },
        });
        return response;
    };

    const columns = [
        {
            name: 'race',
            label: 'Caregiver Race',
        },
        {
            name: 'total',
            label: 'Total',
            class: 'text-center',
            setStyle: () => {
                return { width: '25%' };
            },
        },
    ];

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <strong
                        style={{
                            borderBottom: colors ? `1px solid ${colors.primary}` : null,
                            color: colors ? colors.primary : null,
                        }}
                    >
                        {title}
                    </strong>
                    <div className={style.description}>{description}</div>
                    {selectedIds === 0 ? (
                        initialLoadMessage
                    ) : (
                        <Loader loading={isLoading} removeChildren>
                            <TableAndChart columns={columns} rows={tableRows} tableName="Race" chartData={tableRows} />
                        </Loader>
                    )}
                </div>
            )}
        </>
    );
};

Race.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Race.defaultProps = {
    filters: {},
};

export default Race;
