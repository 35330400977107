import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import levelsQuery from '../../levels.graphql';
import calloutQuery from './callout.graphql';

import Callout from './Callout';
import style from './style.css';
import { getCaregiverTotal, getChildrenTotal } from '../../../../../reportHelpers.js';

const Appdefs = window.cccisd.appDefs;
const grpL4 = Appdefs.app.tripleP.grpL4;

const Component = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [services, setServices] = useState(0);
    const [caregivers, setCaregivers] = useState(0);
    const [children, setChildren] = useState(0);

    const { show } = props.settings;

    const { groupIds, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? Number(groupIds) : previewData.groupIds;

    useEffect(() => {
        setIsLoading(true);

        getSiteANDgrpL4().then(async response => {
            const grpL4Fids = await response.data.flows.assignmentProgressList;

            const filtered2s = grpL4Fids.filter(fid =>
                fid.deployment.label.includes('Level 4_Group_Session_2')
            );
            const sess2deploymentIds = filtered2s.map(session => {
                return [session.deployment.deploymentId];
            });
            const grpL4session2s = sess2deploymentIds.flat();

            const allIds = grpL4Fids.map(card => {
                return [card.deployment.deploymentId];
            });
            const allSessions = allIds.flat();

            getTable({
                allSessions: allSessions.length > 0 ? allSessions : 0,
                grpL4session2s: grpL4session2s.length > 0 ? grpL4session2s : 0,
            }).then(async res => {
                const grpL2 = res.data.flows.grpL2;
                const grpL2Total = getCaregiverTotal(grpL2);
                const grpL2Children = res.data.flows.grpL2children.totalChildren;

                const grpL3 = res.data.flows.grpL3;
                const grpL3Total = getCaregiverTotal(grpL3);
                const grpL3Children = res.data.flows.grpL3children.totalChildren;

                const cardLevels = res.data.groups.triplep_site.childRoles;

                const indL3children = res.data.flows.indL3children;
                const ind3totalChildren = getChildrenTotal(indL3children);

                const indL4children = res.data.flows.indL4children;
                const ind4totalChildren = getChildrenTotal(indL4children);

                const grpL4children = res.data.groups.siteTotal.totalChildren;

                await getServicesTotals({ grpL2Total, grpL3Total, cardLevels });
                await getCaregiverTotals({ grpL2Total, grpL3Total, cardLevels });
                await getChildrenTotals({
                    grpL2Children,
                    grpL3Children,
                    ind3totalChildren,
                    ind4totalChildren,
                    grpL4children,
                });
            });
            setIsLoading(false);
        });
    }, [selectedIds, reportingFrom, reportingTo]);

    const getSiteANDgrpL4 = async () => {
        const response = await apollo.query({
            query: levelsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                reportingFrom,
                reportingTo,
                grpL4assignmentId: grpL4.assignmentId,
            },
        });
        return response;
    };

    const getTable = async ({ allSessions, grpL4session2s }) => {
        const response = await apollo.query({
            query: calloutQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                reportingFrom,
                reportingTo,
                allSessions,
                grpL4assignmentId: grpL4.assignmentId,
                grpL4session2s,
            },
        });
        return response;
    };

    const getServicesTotals = ({ grpL2Total, grpL3Total, cardLevels }) => {
        const totalSevices =
            grpL2Total +
            grpL3Total +
            cardLevels.indL3required +
            cardLevels.indL4required +
            cardLevels.grpL4required;
        setServices(totalSevices);
    };

    const getCaregiverTotals = ({ grpL2Total, grpL3Total, cardLevels }) => {
        const totalCaregivers =
            grpL2Total + grpL3Total + cardLevels.indL3 + cardLevels.indL4 + cardLevels.grpL4;

        setCaregivers(totalCaregivers);
    };

    const getChildrenTotals = ({
        grpL2Children,
        grpL3Children,
        ind3totalChildren,
        ind4totalChildren,
        grpL4children,
    }) => {
        const totalChildren =
            grpL2Children + grpL3Children + ind3totalChildren + ind4totalChildren + grpL4children;
        setChildren(totalChildren);
    };

    return (
        <Loader loading={isLoading} removeChildren>
            <div style={{ marginTop: '4em', marginBottom: '4em' }}>
                {show && (
                    <div className={style.callouts}>
                        <Callout
                            handle="services"
                            settings={{
                                ...props.settings.services,
                                colors: props.settings.colors,
                            }}
                            data={services !== 0 ? services : 'No Data'}
                        />
                        <Callout
                            handle="caregivers"
                            settings={{
                                ...props.settings.caregivers,
                                colors: props.settings.colors,
                            }}
                            data={caregivers !== 0 ? caregivers : 'No Data'}
                        />
                        <Callout
                            handle="children"
                            settings={{
                                ...props.settings.children,
                                colors: props.settings.colors,
                            }}
                            data={children !== 0 ? children : 'No Data'}
                        />
                    </div>
                )}
            </div>
        </Loader>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
