import React from 'react';
import PropTypes from 'prop-types';
import ShowValue from './ShowValue';
import style from './style.css';

const Callout = props => {
    const { title, description, colors } = props.settings;
    const { data } = props;

    return (
        <div
            className={style.wrapper}
            style={
                colors
                    ? {
                          border: `1px solid ${props.settings.colors.primary}`,
                      }
                    : {}
            }
        >
            <div
                className={style.title}
                style={
                    colors
                        ? {
                              backgroundColor: props.settings.colors.primary,
                          }
                        : {}
                }
            >
                {title}
            </div>
            <div className={style.description} style={description ? { padding: '0.5em' } : {}}>
                {description}
            </div>
            <div className={style.component}>
                <ShowValue data={data} colors={colors} />
            </div>
        </div>
    );
};

Callout.propTypes = {
    settings: PropTypes.object,
    handle: PropTypes.string,
    data: PropTypes.object,
};

Callout.defaultProps = {};

export default Callout;
