import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import feedbackIdsQuery from '../../feedbackIds.graphql';
import calloutQuery from './callout.graphql';
import cardLevelCalloutQuery from './cardLevelCallout.graphql';
import siteNamesQuery from '../../siteNames.graphql';
import grpL4FeedbackIdsQuery from '../../grpL4feedbackIds.graphql';
import grpL4CalloutQuery from './grpL4Callout.graphql';

import Callout from './Callout';
import style from './style.css';
import { renderPercentages } from '../../../../../reportHelpers';

const Appdefs = window.cccisd.appDefs;
const grpL2assignmentId = Appdefs.app.tripleP.grpL2assignmentId;
const grpL3assignmentId = Appdefs.app.tripleP.grpL3assignmentId;
const grpL4 = Appdefs.app.tripleP.grpL4;

const Component = props => {
    const [respondents, setRespondents] = useState(100);
    const [responseRate, setResponseRate] = useState(100);
    const [avgSession, setAvgSession] = useState(4);

    const [isLoading, setIsLoading] = useState(true);

    const { totalRespondents, groupLevels, cardLevels } = props.settings;

    const { groupIds, level, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? groupIds.map(Number) : previewData.groupIds;

    useEffect(() => {
        setIsLoading(true);

        if (level === 'Level 2 Seminar' || level === 'Level 3 Discussion') {
            getFeedbackIds({
                assignmentId: getAssignmentId(level),
                sessionDate: getLevelString(level),
            }).then(async response => {
                const allSeminars = response.data.flows.assignmentProgressList;
                const feedbackId = allSeminars.map(sem => {
                    return [sem.additionalInfo.feedbackDeploymentId];
                });
                const totalParents = response.data.flows.parents.totalParents;

                getCallout({ feedbackIds: feedbackId.length > 0 ? feedbackId.flat() : 0 }).then(
                    async res => {
                        const results = res.data.flows.assignmentProgressSummary.totalTakers;
                        const percentage = renderPercentages(results, totalParents);
                        setRespondents(results);
                        setResponseRate(percentage);
                    }
                );
                setIsLoading(false);
            });
        }

        if (level === 'Level 3 Primary Care' || level === 'Level 4 Standard') {
            getSiteNames().then(async response => {
                const indL3 = response.data.flows.indL3;
                const indL4 = response.data.flows.indL4;
                const siteList = response.data.groups.triplep_siteList;
                const sites = siteList.map(site => {
                    return [site.group.label];
                });

                getCardLevelCallout({
                    feedbackIds:
                        level === 'Level 3 Primary Care'
                            ? indL3.assignment.deployment.deploymentId
                            : indL4.assignment.deployment.deploymentId,
                    assignmentId:
                        level === 'Level 3 Primary Care'
                            ? indL3.assignment.assignmentId
                            : indL4.assignment.assignmentId,
                    siteNames: sites.flat(),
                    siteLevelString: getLevelString(level),
                }).then(async res => {
                    const results = res.data.flows.assignmentProgressSummary.totalTakers;
                    const average =
                        res.data.flows.assignmentProgressSummary.sessionsCompletedAverage;

                    setRespondents(results);
                    setAvgSession(average);
                });
                setIsLoading(false);
            });
        }

        if (level === 'Level 4 Group') {
            grpL4FeedbackIds({
                assignmentId: getAssignmentId(level),
            }).then(async response => {
                const allSeminars = response.data.flows.assignmentProgressList;
                let feedbackId = allSeminars.map(sem => {
                    return [sem.additionalInfo.feedbackDeploymentId];
                });

                grpL4Callout({
                    feedbackIds: feedbackId.length > 0 ? feedbackId.flat() : 0,
                    assignmentId: getAssignmentId(level),
                }).then(async res => {
                    const results = res.data.flows.assignmentProgressSummary.totalTakers;
                    const average =
                        res.data.flows.assignmentProgressSummary.sessionsCompletedAverage;

                    setRespondents(results);
                    setAvgSession(average);
                });
                setIsLoading(false);
            });
        }
    }, [groupIds, level, reportingFrom, reportingTo]);

    const getAssignmentId = levelFilter => {
        switch (levelFilter) {
            case 'Level 2 Seminar':
                return grpL2assignmentId;
            case 'Level 3 Discussion':
                return grpL3assignmentId;
            case 'Level 4 Group':
                return grpL4.parentFeedbackAssignmentId;
            default:
                return grpL2assignmentId;
        }
    };

    const getLevelString = levelFilter => {
        switch (levelFilter) {
            case 'Level 2 Seminar':
                return 'devTags.L2SeminarDate';
            case 'Level 3 Discussion':
                return 'devTags.L3DiscussionDate';
            case 'Level 3 Primary Care':
                return 'variables.114_app_variable_sdo';
            case 'Level 4 Standard':
                return 'variables.126_app_variable_sdo';
            default:
                return 'devTags.L2SeminarDate';
        }
    };

    const getFeedbackIds = ({ assignmentId, sessionDate }) => {
        const response = apollo.query({
            query: feedbackIdsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                assignmentId,
                reportingFrom,
                reportingTo,
                sessionDate,
            },
        });

        return response;
    };

    const grpL4FeedbackIds = ({ assignmentId }) => {
        const response = apollo.query({
            query: grpL4FeedbackIdsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                assignmentId,
            },
        });

        return response;
    };

    const getSiteNames = () => {
        const response = apollo.query({
            query: siteNamesQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
            },
        });

        return response;
    };

    const getCallout = async ({ feedbackIds, assignmentId }) => {
        const response = await apollo.query({
            query: calloutQuery,
            fetchPolicy: 'network-only',
            variables: {
                feedbackIds,
                assignmentId,
            },
        });
        return response;
    };

    const getCardLevelCallout = async ({
        feedbackIds,
        assignmentId,
        siteNames,
        siteLevelString,
    }) => {
        const response = await apollo.query({
            query: cardLevelCalloutQuery,
            fetchPolicy: 'network-only',
            variables: {
                feedbackIds,
                assignmentId,
                reportingFrom,
                reportingTo,
                siteNames,
                siteLevelString,
            },
        });

        return response;
    };

    const grpL4Callout = async ({ feedbackIds, assignmentId, siteNames }) => {
        const response = await apollo.query({
            query: grpL4CalloutQuery,
            fetchPolicy: 'network-only',
            variables: {
                feedbackIds,
                assignmentId,
                reportingFrom,
                reportingTo,
                siteNames,
            },
        });

        return response;
    };

    return (
        <Loader loading={isLoading} removeChildren>
            <div className={style.callouts}>
                {totalRespondents.show && (
                    <Callout
                        settings={{
                            ...props.settings.totalRespondents,
                            colors: props.settings.colors,
                        }}
                        data={respondents > 0 ? respondents : 'No Data'}
                    />
                )}
                {groupLevels.show &&
                (level === 'Level 2 Seminar' || level === 'Level 3 Discussion') ? (
                    <Callout
                        settings={{
                            ...props.settings.groupLevels,
                            colors: props.settings.colors,
                        }}
                        data={responseRate > 0 ? `${responseRate}%` : 'No Data'}
                    />
                ) : null}
                {cardLevels.show &&
                (level === 'Level 3 Primary Care' ||
                    level === 'Level 4 Standard' ||
                    level === 'Level 4 Group') ? (
                    <Callout
                        settings={{
                            ...props.settings.cardLevels,
                            colors: props.settings.colors,
                        }}
                        data={avgSession !== null ? Math.round(avgSession * 10) / 10 : 'No Data'}
                    />
                ) : null}
            </div>
        </Loader>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
