import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import _findKey from 'lodash/findKey';

import ageTableQuery from './ageTable.graphql';

import style from '../../style.css';
import { initialLoadMessage } from '../../../../../../reportHelpers';
import TableAndChart from '../..';

const Age = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState({});

    const { description, show, colors, title } = props.settings;

    const { groupIds, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? Number(groupIds) : previewData.groupIds;

    useEffect(() => {
        setIsLoading(true);

        getAgeTable().then(async response => {
            const results = response.data.flows.assignmentProgressSummary.ages;

            const buckets = {};
            const categories = [
                ['1-11'],
                ['11-21'],
                ['21-31'],
                ['31-41'],
                ['41-51'],
                ['51-61'],
                ['61-71'],
                ['71-81'],
                ['81-91'],
                ['91-101'],
                ['101-110'],
            ];
            let namedRow = 0;

            if (results !== null) {
                for (const [ageCategory] of categories) {
                    buckets[`${namedRow++}`] = results.filter(d => d.value === ageCategory);
                }

                setTableData(buckets);
            }
            setIsLoading(false);
        });
    }, [selectedIds, reportingFrom, reportingTo]);

    const getAgeTable = async () => {
        const response = await apollo.query({
            query: ageTableQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                reportingFrom,
                reportingTo,
                deploymentId: 90,
            },
        });
        return response;
    };

    const columns = [
        {
            name: 'label',
            label: 'Caregiver Age',
        },
        {
            name: 'value',
            label: 'Total',
            class: 'text-center',
            setStyle: () => {
                return { width: '25%' };
            },
        },
    ];

    const rows = () => {
        if (tableData === {}) {
            return;
        }
        return [
            {
                id: 0,
                label: '20 years old or younger',
                value: under20(),
            },
            {
                id: 1,
                label: '21-30 years old',
                value: getRowTotal('21-31'),
            },
            {
                id: 2,
                label: '31-40 years old',
                value: getRowTotal('31-41'),
            },
            {
                id: 3,
                label: '41-50 years old',
                value: getRowTotal('41-51'),
            },
            {
                id: 4,
                label: '51-60 years old',
                value: getRowTotal('51-61'),
            },
            {
                id: 5,
                label: '61-70 years old',
                value: getRowTotal('61-71'),
            },
            {
                id: 6,
                label: '71 years and over',
                value: over70(),
            },
        ];
    };

    const getBucketKey = nestedValue => {
        return _findKey(tableData, d => d[0].value === nestedValue);
    };

    const getTotal = value => {
        if (tableData[value] === undefined || tableData[value].length < 0) {
            return 0;
        }

        return tableData[value][0].valueCount;
    };

    const getRowTotal = bucketCategory => {
        let bucketKey = getBucketKey(bucketCategory);

        return getTotal(bucketKey);
    };

    const under20 = () => {
        let oneToTen = getBucketKey('1-11');
        let elevenToTwenty = getBucketKey('11-21');

        return getTotal(oneToTen) + getTotal(elevenToTwenty);
    };

    const over70 = () => {
        let seventies = getBucketKey('71-81');
        let eighties = getBucketKey('81-91');
        let nineties = getBucketKey('91-101');
        let hundreds = getBucketKey('101-110');

        return getTotal(seventies) + getTotal(eighties) + getTotal(nineties) + getTotal(hundreds);
    };

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <strong
                        style={{
                            borderBottom: colors ? `1px solid ${colors.primary}` : null,
                            color: colors ? colors.primary : null,
                        }}
                    >
                        {title}
                    </strong>
                    <div className={style.description}>{description}</div>
                    {selectedIds === 0 ? (
                        initialLoadMessage
                    ) : (
                        <Loader loading={isLoading} removeChildren>
                            <TableAndChart columns={columns} rows={rows()} tableName="Age" chartData={rows()} />
                        </Loader>
                    )}
                </div>
            )}
        </>
    );
};

Age.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Age.defaultProps = {
    filters: {},
};

export default Age;
