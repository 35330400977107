import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import Tooltip from 'cccisd-tooltip';
import _flattenDeep from 'lodash/flattenDeep';

import useQuery from '../../../parentReportUseQuery.js';
import getGroupsQuery from './getSites.graphql';
import style from './style.css';
import { useradminData } from './fakeData.js';

import IconSelect from 'cccisd-icons/stack-check';
import IconCheck from 'cccisd-icons/checkbox-checked';
import IconUncheck from 'cccisd-icons/checkbox-unchecked2';
import IconHalfcheck from 'cccisd-icons/checkbox-partial2';
import IconExpandClose from 'cccisd-icons/arrow-right';
import IconExpandOpen from 'cccisd-icons/arrow-down';
import IconParentClose from 'cccisd-icons/folder2';
import IconParentOpen from 'cccisd-icons/folder-open';
import IconLeaf from 'cccisd-icons/location';
import IconWarning from 'cccisd-icons/warning';

const ParentReportSelectGroups = ({ title, modalHeader, form, field, filters, reportHandle }) => {
    const [expanded, setExpanded] = useState([]);

    const [queryData, loading] = useQuery({
        query: getGroupsQuery,
        variables: filters,
    });

    useEffect(() => {
        reportHandle === 'parentDemographics' ? _getDemographicNodes() : _getNodes();
    }, [loading, reportHandle]);

    const _setCheckAll = () => {
        const allGroups = _flattenDeep(
            _getUserData().map(org => [
                org.id,
                ...org.groupingUnits.map(gu => [gu.id, ...gu.sites.map(site => site.id)]),
            ])
        );

        form.setFieldValue('groupIds', allGroups);
    };

    const _setUncheckAll = () => {
        form.setFieldValue('groupIds', []);
    };

    const only1sdo = (
        <div className="alert alert-danger" role="alert">
            <IconWarning spaceRight />
            Please select only one SDO for this report.
        </div>
    );

    const handleOnCheck = selectedGroups => {
        const groupIdNumbers = selectedGroups.map(id => Number(id));

        reportHandle === 'parentDemographics' && selectedGroups.length > 1
            ? form.setFieldError('groupIds', only1sdo)
            : form.setFieldValue('groupIds', groupIdNumbers);
    };

    const convertDataStructure = myData => {
        let convertedData = [];
        if (loading === false) {
            convertedData = myData.groups.triplep_organizationList.map(org => {
                if (org.group.groupId) {
                    return {
                        id: org.group.groupId,
                        name: org.group.label,
                        groupingUnits: org.childGroups.triplep_groupingUnitList.map(gu => {
                            if (org.childGroups.triplep_groupingUnitList.length > 0) {
                                return {
                                    id: gu.group.groupId,
                                    name: gu.group.label,
                                    sites: gu.childGroups.triplep_siteList.map(site => {
                                        if (gu.childGroups.triplep_siteList.length > 0) {
                                            return {
                                                id: site.group.groupId,
                                                name: site.group.label,
                                            };
                                        }
                                        return [];
                                    }),
                                };
                            }
                            return null;
                        }),
                    };
                }
                return null;
            });
        } else {
            convertedData = useradminData;
        }
        return convertedData;
    };

    const _getUserData = () => {
        let data = convertDataStructure(queryData);
        return data;
    };

    const _getDemographicUserData = () => {
        let data = convertDataStructure(queryData);

        return data;
    };

    const _renderSiteNode = site => {
        return (
            <span className={style.leaf}>
                <div className={style.groupName}>{site.name}</div>
            </span>
        );
    };

    const _getNodes = () => {
        let nodes;
        nodes = _getUserData().map(org => ({
            value: org.id,
            label: _renderSiteNode(org),
            className: style.node,
            children: org.groupingUnits.map(gu => ({
                value: gu.id,
                label: _renderSiteNode(gu),
                className: style.node,
                children: gu.sites
                    ? gu.sites.map(site => ({
                          value: site.id,
                          label: _renderSiteNode(site),
                          className: style.node,
                      }))
                    : [],
            })),
        }));

        return nodes;
    };

    const _getDemographicNodes = () => {
        let nodes;

        nodes = _getDemographicUserData().map(org => ({
            value: org.id,
            label: _renderSiteNode(org),
            className: style.node,
            children: org.groupingUnits.map(gu => ({
                value: gu.id,
                label: _renderSiteNode(gu),
                className: style.node,
                children: gu.sites
                    ? gu.sites.map(site => ({
                          value: site.id,
                          label: _renderSiteNode(site),
                          className: style.node,
                      }))
                    : [],
            })),
        }));

        return nodes;
    };

    return (
        <Modal
            trigger={
                <button type="button" className="btn btn-warning" onClick={() => {}}>
                    <IconSelect spaceRight />
                    {title}
                </button>
            }
            title={title}
            headerStyle={modalHeader}
            render={({ closeModal }) =>
                reportHandle === 'parentDemographics' ? (
                    <>
                        {form.errors && form.errors.groupIds ? form.errors.groupIds : null}
                        <div className={style.flex}>
                            <CheckboxTree
                                nodes={_getDemographicNodes()}
                                checked={field.value}
                                expanded={expanded}
                                onCheck={checked => handleOnCheck(checked)}
                                onExpand={expandedNodes => setExpanded(expandedNodes)}
                                icons={{
                                    check: <IconCheck />,
                                    uncheck: <IconUncheck />,
                                    halfCheck: <IconHalfcheck />,
                                    expandClose: <IconExpandClose />,
                                    expandOpen: <IconExpandOpen />,
                                    expandAll: <IconExpandOpen />,
                                    collapseAll: <IconExpandClose />,
                                    parentClose: <IconParentClose />,
                                    parentOpen: <IconParentOpen />,
                                    leaf: <IconLeaf />,
                                }}
                            />
                        </div>
                        <div className={style.buttonWrapper}>
                            {form.errors && form.errors.groupIds ? (
                                <Tooltip title="Please select only one SDO for this report.">
                                    <button
                                        type="button"
                                        className={`btn btn-primary disabled ${style.button}`}
                                        disabled
                                    >
                                        Next Filter
                                    </button>
                                </Tooltip>
                            ) : (
                                <button
                                    type="button"
                                    className={`btn btn-primary ${style.button}`}
                                    onClick={closeModal}
                                >
                                    Next Filter
                                </button>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={style.flex}>
                            <CheckboxTree
                                nodes={_getNodes()}
                                checked={field.value}
                                expanded={expanded}
                                onCheck={checked => handleOnCheck(checked)}
                                onExpand={expandedNodes => setExpanded(expandedNodes)}
                                icons={{
                                    check: <IconCheck />,
                                    uncheck: <IconUncheck />,
                                    halfCheck: <IconHalfcheck />,
                                    expandClose: <IconExpandClose />,
                                    expandOpen: <IconExpandOpen />,
                                    expandAll: <IconExpandOpen />,
                                    collapseAll: <IconExpandClose />,
                                    parentClose: <IconParentClose />,
                                    parentOpen: <IconParentOpen />,
                                    leaf: <IconLeaf />,
                                }}
                            />

                            <div>
                                <button
                                    type="button"
                                    className={`btn btn-warning ${style.button}`}
                                    onClick={() => _setCheckAll()}
                                >
                                    <IconCheck spaceRight />
                                    Select All
                                </button>
                                <div style={{ marginTop: '1em' }}>
                                    <button
                                        type="button"
                                        className={`btn btn-info ${style.button}`}
                                        onClick={() => _setUncheckAll()}
                                    >
                                        <IconUncheck spaceRight />
                                        Unselect All
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={style.buttonWrapper}>
                            <button
                                type="button"
                                className={`btn btn-primary ${style.button}`}
                                onClick={closeModal}
                            >
                                Next Filter
                            </button>
                        </div>
                    </>
                )
            }
        />
    );
};

ParentReportSelectGroups.propTypes = {
    data: PropTypes.array,
    userType: PropTypes.string,
    field: PropTypes.object,
    form: PropTypes.object,
    filters: PropTypes.object,
    reportHandle: PropTypes.string,
    title: PropTypes.string,
    modalHeader: PropTypes.object,
};

ParentReportSelectGroups.defaultProps = {};

export default ParentReportSelectGroups;
