import React, { useState, useEffect } from 'react';
import Loader from 'cccisd-loader';
import style from './style.css';
import BusinessCard from './BusinessCard';
import { client } from 'cccisd-apollo';
import query from './roles.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;

const RolePicker = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const userId = Fortress.user.acting.user.id;

    useEffect(() => {
        (async () => {
            setLoading(true);
            const response = await client.query({
                query,
                variables: {
                    userId,
                },
                fetchPolicy: 'network-only',
            });
            setData(response.data.roles);
            setLoading(false);
        })();
    }, [userId]);

    if (!data) {
        return <Loader loading />;
    }

    const renderCards = (arr, program, hex) => {
        return (
            <div className={style.roles}>
                {arr.map(role => {
                    return (
                        <BusinessCard
                            color={hex}
                            key={role.pawn.pawnId}
                            appName={program}
                            label={role.pawn.label}
                            pawnData={{
                                pawnId: role.pawn.pawnId,
                                pawnHash: role.pawn.pawnHash,
                            }}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <Loader loading={loading}>
            <div>
                {Fortress.auth()}
                <div style={{ margin: '20px 0 20px 0' }}>
                    <h1>Please select which account you would like to use.</h1>
                    <div className={style.colorBar} />
                    <div className={style.byProgram}>
                        {renderCards(data.surveyBuilder, 'Survey Builder', '#c40075')}
                        {renderCards(data.miechv, 'MIECHV', '#f37021')}
                    </div>
                </div>
            </div>
        </Loader>
    );
};

export default RolePicker;
