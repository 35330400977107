import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-graphql-table';

import summaryTableQuery from './summaryTable.graphql';

const SummaryFilteredTable = props => {
    const { groupId, assignmentId, session2s, session8s } = props;

    const columns = [
        {
            name: 'ancestorGroups.triplep_groupingUnit.group.label',
            label: 'BBO',
            setStyle: () => {
                return { width: '25%' };
            },
            rowSpanBy: 'ancestorGroups.triplep_groupingUnit.group.label',
        },
        {
            name: 'group.label',
            label: 'SDO',
            setStyle: () => {
                return { width: '25%' };
            },
        },
        {
            name: 'childRoles.required',
            label: 'Number of Clients Finishing Required Content (total)',
            className: 'text-center',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
        },
        {
            name: 'childRoles.completed',
            label: 'Number of Clients Completing Service (total)',
            className: 'text-center',
            setStyle: () => {
                return { verticalAlign: 'middle' };
            },
        },
    ];
    return (
        <Table
            columns={columns}
            query={summaryTableQuery}
            graphqlVariables={{
                groupId,
                assignmentId,
                session2s: session2s.flat().length > 0 ? session2s : 0,
                session8s: session8s.flat().length > 0 ? session8s : 0,
            }}
            noRecordsMessage="No data collected at selected filters."
            rowKey="group.groupId"
            hideShowingRowsSummary
            perPage={25}
        />
    );
};

SummaryFilteredTable.propTypes = {
    groupId: PropTypes.array,
    assignmentId: PropTypes.number,
    session2s: PropTypes.array,
    session8s: PropTypes.array,
};

export default SummaryFilteredTable;
