import React from 'react';
import PropTypes from 'prop-types';
import { Html } from 'cccisd-wysiwyg';
import style from '../../TableAndChart/style.css';

const Component = props => {
    const { title, footnotes, colors } = props.settings;

    return (
        <div className={style.wrapper}>
            <strong
                style={{
                    borderBottom: colors ? `1px solid ${colors.primary}` : null,
                    color: colors ? colors.primary : null,
                }}
            >
                <Html content={title} />
            </strong>
            <Html content={footnotes} />
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

export default Component;
