import React from 'react';
import { addDays, format } from 'date-fns';

export default () => {
    return ({ row }) => {
        const renderBoosterDate = () => {
            const gradDate = row['pawn.grad.devTags.VisitDate'];
            const unformattedBooster = addDays(gradDate, 90);
            const tentative90DayBooster = format(unformattedBooster, 'YYYY-MM-DD');
            return tentative90DayBooster;
        };

        const getPlannedDates = () => {
            if (row['deployment.label'] === 'Site Visit 2') {
                return row['pawn.assignmentProgress.devTags.PlannedVisit2'];
            }
            if (row['deployment.label'] === 'Site Visit 3') {
                return row['pawn.assignmentProgress.devTags.PlannedVisit3'];
            }
            if (row['deployment.label'] === 'Site Visit 4') {
                return row['pawn.assignmentProgress.devTags.PlannedVisit4'];
            }
            if (row['deployment.label'] === 'Graduation') {
                return row['pawn.assignmentProgress.devTags.PlannedGraduation'];
            }
            if (row['deployment.label'] === '90 Day Booster') {
                return renderBoosterDate();
            }
        };

        return <div>{getPlannedDates()}</div>;
    };
};
