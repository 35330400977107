import React from 'react';
import format from 'date-fns/format';
import Tooltip from 'cccisd-tooltip';
import IconPencil from 'cccisd-icons/pencil';
import IconPlus from 'cccisd-icons/plus-circle';
import IconPlay from 'cccisd-icons/play3';
import IconFlag from 'cccisd-icons/flag';
import IconCheck from 'cccisd-icons/checkmark5';
import IconCalendar from 'cccisd-icons/calendar';
import IconPause from 'cccisd-icons/pause';
import IconFlagMessage from 'cccisd-icons/flag2';
import IconBlocked from 'cccisd-icons/blocked';
import IconNotification from 'cccisd-icons/notification';
import IconWrench from 'cccisd-icons/wrench2';
import IconThumbsUp from 'cccisd-icons/thumbs-up3';
import IconHourglass from 'cccisd-icons/hour-glass2';

export const childrensTrust = "Children's Trust";

export const monthFirstFormat = date => {
    return format(new Date(date), 'MM/DD/YYYY');
};

export const plannedMessage = (
    <div style={{ color: '#296090', fontSize: '2em' }}>
        <Tooltip title="Planned Visit">
            <IconCalendar />
        </Tooltip>
    </div>
);

export const completedMessage = (
    <div style={{ color: '#449D44', fontSize: '2em' }}>
        <Tooltip title="Completed Visit">
            <IconCheck />
        </Tooltip>
    </div>
);

export const incompleteMessage = (
    <div style={{ color: '#32B0D5', fontSize: '2em' }}>
        <Tooltip title="In Progress Visit">
            <IconPause />
        </Tooltip>
    </div>
);

export const nAmessage = (
    <div style={{ color: 'gray', fontSize: '2em' }}>
        <Tooltip title="No Visit">
            <IconBlocked />
        </Tooltip>
    </div>
);

export const flaggedMessage = (
    <div style={{ color: '#C8302B', fontSize: '2em' }}>
        <Tooltip title="Flagged Visit">
            <IconFlagMessage />
        </Tooltip>
    </div>
);
export const incompleteEnrollment = (
    <div style={{ color: '#C8302B', fontSize: '2em' }}>
        <Tooltip title="Incomplete Enrollment">
            <IconFlagMessage />
        </Tooltip>
    </div>
);

export const overdueMessage = <td className="alert-alert-danger">Overdue</td>;

export const editButton = (
    <button type="button" className="btn btn-success" style={{ width: '7.75em' }}>
        <IconPencil spaceRight />
        Edit
    </button>
);

export const resumeButton = (
    <button type="button" className="btn btn-info" style={{ width: '7.75em' }}>
        <IconPlay spaceRight />
        Resume
    </button>
);

export const startButton = (
    <button type="button" className="btn btn-primary" style={{ width: '7.75em' }}>
        <IconPlus spaceRight />
        Start
    </button>
);

export const flaggedButton = (
    <button type="button" className="btn btn-danger" style={{ width: '7.75em' }}>
        <IconFlag spaceRight />
        Flagged
    </button>
);

export const overdueButton = (
    <button type="button" className="btn btn-warning" style={{ width: '7.75em' }}>
        <IconFlag spaceRight />
        Overdue
    </button>
);

export const incompleteButton = (
    <button type="button" className="btn btn-danger" style={{ width: '7.75em' }}>
        <IconFlag spaceRight />
        Incomplete
    </button>
);

export const disabledButton = (
    <button type="button" className="btn btn-primary disabled" style={{ width: '7.75em' }} disabled>
        <IconNotification spaceRight />
        Start
    </button>
);

export const disabledImportedButton = (
    <button type="button" className="btn btn-primary disabled" style={{ width: '7.75em' }} disabled>
        <IconNotification spaceRight />
        Imported
    </button>
);

export const renderButton = (progress, enrollment) => {
    if (progress.additionalInfo && progress.additionalInfo === 'imported') {
        return disabledImportedButton;
    }

    if (progress && progress.devTags && progress.devTags.Flagged === 'Yes') {
        return flaggedButton;
    }
    if (enrollment && enrollment.status !== 'Complete') return disabledButton;
    if (progress && progress.completed) {
        return editButton;
    }
    if (progress && progress.started && !progress.completed) {
        return resumeButton;
    }

    return startButton;
};

export const renderEnrollmentButton = progress => {
    if (progress.additionalInfo && progress.additionalInfo === 'imported') {
        return disabledImportedButton;
    }

    if (progress && progress.devTags && progress.devTags.Flagged === 'Yes') {
        return flaggedButton;
    }
    if (progress && progress.status !== 'Complete') return incompleteButton;
    if (progress && progress.completed) {
        return editButton;
    }
    if (progress && progress.started && !progress.completed) {
        return resumeButton;
    }

    return startButton;
};

export const renderMessage = progress => {
    if (progress.additionalInfo && progress.additionalInfo === 'imported') {
        return completedMessage;
    }

    if (progress && progress.devTags && progress.devTags.Flagged === 'Yes') {
        return flaggedMessage;
    }
    if (progress && progress.completed) {
        return completedMessage;
    }
    if (progress && progress.started && !progress.completed) {
        return incompleteMessage;
    }
    return plannedMessage;
};

export const renderEnrollmentMessage = progress => {
    if (progress.additionalInfo && progress.additionalInfo === 'imported') {
        return completedMessage;
    }

    if (progress && progress.devTags && progress.devTags.Flagged === 'Yes') {
        return flaggedMessage;
    }
    if (progress && progress.status !== 'Complete') return incompleteEnrollment;
    if (progress && progress.completed) {
        return completedMessage;
    }
    if (progress && progress.started && !progress.completed) {
        return incompleteMessage;
    }
    return plannedMessage;
};

export const incompleteData = (
    <Tooltip title="Please use the Manage tab to complete Site Data for Capacity and Staffing.">
        <IconBlocked spaceRight />
        Incomplete Data
    </Tooltip>
);

export const flaggedOnHeader = (
    <div>
        <span className="label label-danger" style={{ fontSize: '1em' }}>
            Flagged
        </span>
    </div>
);

export const renderSurveyStatus = (row, deploymentHandle) => {
    let timepoint = row.flagged.filter(
        surveys => surveys.deployment.deploymentHandle === deploymentHandle
    );

    return timepoint.status;
};

export const styleCaregiverStatus = status => {
    switch (status) {
        case 'Active':
            return (
                <span className="label label-success" style={{ fontSize: '1em' }}>
                    Active
                </span>
            );
        case 'Inactive':
            return (
                <span className="label label-default" style={{ fontSize: '1em' }}>
                    Inactive
                </span>
            );
        case 'Graduated':
            return (
                <span className="label label-info" style={{ fontSize: '1em' }}>
                    Graduated
                </span>
            );
        case 'Miscarriage':
            return (
                <span className="label label-warning" style={{ fontSize: '1em' }}>
                    Miscarriage
                </span>
            );
        case 'Healthy Steps Discharge':
            return (
                <span className="label label-primary" style={{ fontSize: '1em' }}>
                    Healthy Steps
                </span>
            );
        default:
            return <div />;
    }
};

export const stylePDSAstatus = row => {
    if (row.statusEval === 'Draft') {
        return (
            <div style={{ color: '#909fa2' }}>
                <IconPencil />
            </div>
        );
    }

    if (row['devTags.OrgAdminApprovePDSA'] === 'Approve' && row.completeEval === 'Complete') {
        return (
            <div style={{ fontSize: '22px', color: '#449D44' }}>
                <IconCheck />
            </div>
        );
    }
    if (row['devTags.OrgAdminApprovePDSA'] === 'Approve') {
        return (
            <span className="label label-success" style={{ fontSize: '1em' }}>
                <IconThumbsUp />
            </span>
        );
    }
    if (row['devTags.OrgAdminApprovePDSA'] === 'Reject' && row.rejectToPendingEval === 'Pending') {
        return (
            <span className="label label-warning" style={{ fontSize: '1em' }}>
                <IconHourglass />
            </span>
        );
    }
    if (row['devTags.OrgAdminApprovePDSA'] === 'Reject') {
        return (
            <span className="label label-danger" style={{ fontSize: '1em' }}>
                <IconWrench />
            </span>
        );
    }
    if (row.statusEval === 'Pending' || row['devTags.OrgAdminApprovePDSA'] === 'saveAndReturn') {
        return (
            <span className="label label-warning" style={{ fontSize: '1em' }}>
                <IconHourglass />
            </span>
        );
    }
    return row.statusEval;
};
