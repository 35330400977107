import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { ResponsivePie } from 'cccisd-nivo/pie';

import style from './style.css';

const TableAndChart = props => {
    return (
        <div className={style.parentChart}>
            <div className={style.table}>
                <Table
                    columns={props.columns}
                    data={props.rows}
                    noRecordsMessage="No data collected at selected filters."
                    showPerPageOptions={false}
                    hideShowingRowsSummary
                    isCsvDownload
                    csvButtonTitle=""
                    csvFilename={`Program Report ${props.tableName}.csv`}
                />
            </div>
            <div
                className={
                    props.tableName !== 'Primary Language' ? style.chart : style.languageChart
                }
            >
                <ResponsivePie
                    data={props.chartData}
                    colors={[
                        '#00ADDC',
                        '#F37021',
                        '#C40075',
                        '#D7DF23',
                        '#666666',
                        '#489052',
                        '#983895',
                        '#C9C9C9',
                    ]}
                    legends={[
                        {
                            anchor: 'right',
                            direction: 'column',
                            justify: false,
                            translateX: 40,
                            translateY: 0,
                            itemWidth: 40,
                            itemHeight: 20,
                            itemsSpacing: 5,
                            symbolSize: 15,
                            itemDirection: 'left-to-right',
                        },
                    ]}
                    arcLabel={d => `${d.value}`}
                    arcLabelsTextColor="#ffffff"
                    enableArcLinkLabels={false}
                    margin={{ top: 10, right: 150, bottom: 0, left: 0 }}
                />
            </div>
        </div>
    );
};

TableAndChart.propTypes = {
    columns: PropTypes.array,
    rows: PropTypes.array,
    tableName: PropTypes.string,
    chartData: PropTypes.array,
};

export default TableAndChart;
