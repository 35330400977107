import React from 'react';
import PropTypes from 'prop-types';
// import commentsQuery from '../../class.graphql';
import Comments from './Comments';

const Component = props => {
    const { show, title } = props.settings;
    const { convertDataStructure, filters } = props;

    return (
        <>
            {show && (
                <div style={{ marginBottom: '2em' }}>
                    <Comments
                        settings={{ title }}
                        // query={props.query ? props.query : commentsQuery}
                        convertDataStructure={convertDataStructure}
                        colors={props.settings.colors}
                        filters={filters}
                    />
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
