import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { ResponsivePie } from 'cccisd-nivo/pie';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';

import referralChartQuery from './referralChart.graphql';

import style from './style.css';
import { initialLoadMessage } from '../../../../../reportHelpers.js';

const ReferralChart = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const { description, show, colors, title } = props.settings;

    const { groupIds, reportingFrom, reportingTo } = props.filters;
    const previewData = props.isPreview
        ? {
              groupIds: [0],
          }
        : null;
    const selectedIds = groupIds ? Number(groupIds) : previewData.groupIds;

    useEffect(() => {
        setIsLoading(true);

        getChartData().then(async response => {
            const results = response.data.groups.triplep_site.childRoles;

            const rowsRendered = _map(results, (value, key) => {
                return {
                    id: _startCase(key),
                    label: `${_startCase(key)} (${value})`,
                    value,
                };
            });
            const cleanUpQuery = rowsRendered.filter(row => row.id !== 'Typename');

            setData(cleanUpQuery);

            setIsLoading(false);
        });
    }, [selectedIds, reportingFrom, reportingTo]);

    const getChartData = async () => {
        const response = await apollo.query({
            query: referralChartQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: selectedIds,
                reportingFrom,
                reportingTo,
                deploymentId: 90,
            },
        });
        return response;
    };

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <strong
                        style={{
                            borderBottom: colors ? `1px solid ${colors.primary}` : null,
                            color: colors ? colors.primary : null,
                        }}
                    >
                        {title}
                    </strong>
                    <div className={style.description}>{description}</div>
                    {selectedIds === 0 ? (
                        initialLoadMessage
                    ) : (
                        <Loader loading={isLoading} removeChildren>
                            <div style={{ height: '600px', width: '100%' }}>
                                <ResponsivePie
                                    data={data}
                                    arcLabelsTextColor="#ffffff"
                                    enableArcLinkLabels={false}
                                    margin={{ top: 0, right: 0, bottom: 0, left: 250 }}
                                    colors={[
                                        '#00ADDC',
                                        '#F37021',
                                        '#489052',
                                        '#C40075',
                                        '#666666',
                                        '#D7DF23',
                                        '#983895',
                                    ]}
                                    legends={[
                                        {
                                            anchor: 'top-left',
                                            direction: 'column',
                                            justify: false,
                                            translateX: -250,
                                            translateY: 0,
                                            itemWidth: 40,
                                            itemHeight: 20,
                                            itemsSpacing: 2,
                                            symbolSize: 20,
                                            itemDirection: 'left-to-right',
                                        },
                                    ]}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            size: 4,
                                            padding: 1,
                                            stagger: true,
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10,
                                        },
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'You Tube',
                                            },
                                            id: 'dots',
                                        },
                                        {
                                            match: {
                                                id: 'Other Social Media Paid',
                                            },
                                            id: 'dots',
                                        },
                                        {
                                            match: {
                                                id: 'Facebook Post',
                                            },
                                            id: 'dots',
                                        },
                                        {
                                            match: {
                                                id: 'Twitter Post',
                                            },
                                            id: 'dots',
                                        },
                                        {
                                            match: {
                                                id: 'Instagram',
                                            },
                                            id: 'dots',
                                        },
                                        {
                                            match: {
                                                id: 'Other Social Media',
                                            },
                                            id: 'dots',
                                        },
                                        {
                                            match: {
                                                id: 'Facebook Ad',
                                            },
                                            id: 'dots',
                                        },
                                        {
                                            match: {
                                                id: 'Community Practitioner',
                                            },
                                            id: 'lines',
                                        },
                                        {
                                            match: {
                                                id: 'Insurance Provider',
                                            },
                                            id: 'lines',
                                        },
                                        {
                                            match: {
                                                id: 'Medical Professional',
                                            },
                                            id: 'lines',
                                        },
                                        {
                                            match: {
                                                id: 'Education Professional',
                                            },
                                            id: 'lines',
                                        },
                                        {
                                            match: {
                                                id: 'Faith Based Org',
                                            },
                                            id: 'lines',
                                        },
                                        {
                                            match: {
                                                id: 'Participant',
                                            },
                                            id: 'lines',
                                        },
                                        {
                                            match: {
                                                id: 'Non Participant',
                                            },
                                            id: 'lines',
                                        },
                                    ]}
                                />
                            </div>
                        </Loader>
                    )}
                </div>
            )}
        </>
    );
};

ReferralChart.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

ReferralChart.defaultProps = {
    filters: {},
};

export default ReferralChart;
