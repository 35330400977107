import React from 'react';
import { Field, CccisdToggle, CccisdInput } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    chart: {
        verticalScaleMin: 0,
        verticalScaleMax: 5,
    },
    knowledgeShow: true,
    knowledgeTitle: 'The facilitator was knowledgeable.',
    knowledgeDescription: '',
    engagementShow: true,
    engagementTitle: 'The facilitator was engaging.',
    engagementDescription: '',
    adviceShow: true,
    adviceTitle: 'I plan on using the advice received.',
    adviceDescription: '',
    servicesShow: true,
    servicesTitle: 'I would like to receive additional TP services.',
    servicesDescription: '',
    comfortShow: true,
    comfortTitle: 'I feel comfortable asking for parenting help.',
    comfortDescription: '',
    recommendShow: true,
    recommendTitle: 'I would recommend this seminar to other parents.',
    recommendDescription: '',
    recommendCommunityShow: true,
    recommendCommunityTitle: 'I would recommend this to other parents in my community.',
    recommendCommunityDescription: '',
    needsShow: true,
    needsTitle: 'I feel that the services received met my needs.',
    needsDescription: '',
};

const Component = props => {
    return (
        <div>
            <div>
                <Field
                    name="knowledgeShow"
                    component={CccisdToggle}
                    label="Show Knowledge Chart?"
                />
                <Field name="knowledgeTitle" component={CccisdInput} label="Knowledge Title" />
                <Field
                    name="knowledgeDescription"
                    component={CccisdInput}
                    label="Knowledge Description"
                />
            </div>
            <hr />
            <div>
                <Field
                    name="engagementShow"
                    component={CccisdToggle}
                    label="Show Engagement Chart?"
                />
                <Field name="engagementTitle" component={CccisdInput} label="Engagement Title" />
                <Field
                    name="engagementDescription"
                    component={CccisdInput}
                    label="Engagement Description"
                />
            </div>
            <hr />
            <div>
                <Field name="adviceShow" component={CccisdToggle} label="Show Advice Chart?" />
                <Field name="adviceTitle" component={CccisdInput} label="Advice Title" />
                <Field
                    name="adviceDescription"
                    component={CccisdInput}
                    label="Advice Description"
                />
            </div>
            <hr />
            <div>
                <Field name="servicesShow" component={CccisdToggle} label="Show Services Chart?" />
                <Field name="servicesTitle" component={CccisdInput} label="Services Title" />
                <Field
                    name="servicesDescription"
                    component={CccisdInput}
                    label="Services Description"
                />
            </div>
            <hr />
            <div>
                <Field name="comfortShow" component={CccisdToggle} label="Show Comfort Chart?" />
                <Field name="comfortTitle" component={CccisdInput} label="Comfort Title" />
                <Field
                    name="comfortDescription"
                    component={CccisdInput}
                    label="Comfort Description"
                />
            </div>
            <hr />
            <div>
                <Field
                    name="recommendShow"
                    component={CccisdToggle}
                    label="Show Recommend Chart?"
                />
                <Field name="recommendTitle" component={CccisdInput} label="Recommend Title" />
                <Field
                    name="recommendDescription"
                    component={CccisdInput}
                    label="Recommend Description"
                />
            </div>
            <hr />
            <Field
                name="recommendCommunityShow"
                component={CccisdToggle}
                label="Show Recommend Community Chart?"
            />
            <Field
                name="recommendCommunityTitle"
                component={CccisdInput}
                label="Recommend Community Title"
            />
            <Field
                name="recommendCommunityDescription"
                component={CccisdInput}
                label="Recommend Community Description"
            />
            <hr />
            <div>
                <div>
                    <Field name="needsShow" component={CccisdToggle} label="Show Needs Chart?" />
                    <Field name="needsTitle" component={CccisdInput} label="Needs Title" />
                    <Field
                        name="needsDescription"
                        component={CccisdInput}
                        label="Needs Description"
                    />
                </div>
            </div>
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
