import React from 'react';
import PropTypes from 'prop-types';

import { Formik, Form, Field, CccisdInput, CccisdDatepicker, CccisdSelect } from 'cccisd-formik';
import ClickButton from 'cccisd-click-button';

import RequiredFieldLabel from '../../../../../RequiredFieldLabel';

const fields = window.cccisd && window.cccisd.appDefs.pawn.fields;
const enumFields = fields.filter(field => field.data_type === 'enum');
const enumValues = {};
for (const field of enumFields) {
    enumValues[field.handle] = field.values.map(item => ({
        label: item.name,
        value: item.value,
    }));
}

const AddClientForm = ({ closeModal, onSubmit, initialValues }) => {
    const onAddorEdit = async values => {
        onSubmit(values);
        closeModal();
    };

    const validate = values => {
        const errors = {};

        if (!values.username) {
            errors.username = 'Username is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
            errors.username = 'Invalid email address';
        }
        if (!values.firstName) {
            errors.firstName = 'First Name is required.';
        }
        if (!values.lastName) {
            errors.lastName = 'Last Name is required.';
        }
        if (!values.DOB) {
            errors.DOB = 'Date of Birth is required.';
        }
        if (!values.race) {
            errors.race = 'Race is required.';
        }
        if (!values.ethnicity) {
            errors.ethnicity = 'Ethnicity is required.';
        }
        if (!values.gender) {
            errors.gender = 'Gender is required.';
        }
        if (values.gender === 'Other' && !values.genderOther) {
            errors.genderOther = 'Please specify gender.';
        }
        if (!values.lastFour) {
            errors.lastFour = 'The Last Four digits of the SSN are required.';
        } else if (!/^[0-9]{1,45}$/.test(values.lastFour)) {
            errors.lastFour = 'Must be a whole number.';
        }
        if (values.phone && values.phone) {
            if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(values.phone)) {
                errors.phone = 'Must be a valid phone number.';
            }
        }

        return errors;
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onAddorEdit} validate={validate}>
            {({ values, isSubmitting, handleSubmit }) => (
                <Form>
                    <Field
                        name="username"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>Username:</RequiredFieldLabel>}
                        disabled={
                            initialValues && initialValues.username ? initialValues.username : null
                        }
                    />
                    <div className="row">
                        <div className="col-xs-4">
                            <Field
                                name="firstName"
                                component={CccisdInput}
                                label={
                                    <RequiredFieldLabel isRequired>First Name:</RequiredFieldLabel>
                                }
                            />
                        </div>
                        <div className="col-xs-4">
                            <Field
                                name="lastName"
                                component={CccisdInput}
                                label={
                                    <RequiredFieldLabel isRequired>Last Name:</RequiredFieldLabel>
                                }
                            />
                        </div>
                        <div className="col-xs-4">
                            <Field
                                name="DOB"
                                component={CccisdDatepicker}
                                label={
                                    <RequiredFieldLabel isRequired>
                                        Date of Birth:
                                    </RequiredFieldLabel>
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6">
                            <Field
                                name="race"
                                component={CccisdSelect}
                                label={<RequiredFieldLabel isRequired>Race:</RequiredFieldLabel>}
                                options={[
                                    { value: '', label: '-- Please select a race --' },
                                    ...enumValues.race,
                                ]}
                            />
                        </div>
                        <div className="col-xs-6">
                            <Field
                                name="ethnicity"
                                component={CccisdSelect}
                                label={
                                    <RequiredFieldLabel isRequired>Ethnicity:</RequiredFieldLabel>
                                }
                                options={[
                                    { value: '', label: '-- Please select an ethnicity --' },
                                    ...enumValues.ethnicity,
                                ]}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6">
                            <Field
                                name="gender"
                                component={CccisdSelect}
                                label={<RequiredFieldLabel isRequired>Gender:</RequiredFieldLabel>}
                                options={[
                                    { value: '', label: '-- Please select a gender --' },
                                    ...enumValues.gender,
                                ]}
                            />
                        </div>
                        {values.gender === 'Other' ? (
                            <div className="col-xs-6">
                                <Field
                                    name="genderOther"
                                    component={CccisdInput}
                                    label={
                                        <RequiredFieldLabel isRequired>
                                            Other Gender:
                                        </RequiredFieldLabel>
                                    }
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="row">
                        <div className="col-xs-6">
                            <Field
                                name="lastFour"
                                component={CccisdInput}
                                label={
                                    <RequiredFieldLabel isRequired>
                                        Last 4 of SSN:
                                    </RequiredFieldLabel>
                                }
                            />
                        </div>
                        <div className="col-xs-6">
                            <Field name="phone" component={CccisdInput} label="Phone:" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6">
                            <Field
                                name="relationshipToChildren"
                                component={CccisdSelect}
                                label="Relationship to Child(ren) in the Household:"
                                options={[
                                    { value: '', label: '-- Select Relationship --' },
                                    ...enumValues.relationshipToChildren,
                                ]}
                            />
                        </div>
                        {values.relationshipToChildren === 'Other' ? (
                            <div className="col-xs-6">
                                <Field
                                    name="otherRelationship"
                                    component={CccisdInput}
                                    label="Other Relationship to Child(ren)in the Household:"
                                />
                            </div>
                        ) : null}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ClickButton
                            title="Submit"
                            className="btn btn-primary"
                            isLoading={isSubmitting}
                            onClick={handleSubmit}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

AddClientForm.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
};

export default AddClientForm;
