import React from 'react';
import { Field, CccisdWysiwyg, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    title: 'Footnotes',
    footnotes: '',
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Footnotes?" />
            <Field name="title" component={CccisdWysiwyg} label="Title" />
            <Field name="footnotes" component={CccisdWysiwyg} label="Footnotes" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
