import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'children',
    label: 'Children Table',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
