import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import _get from 'lodash/get';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import style from './style.css';

import flowInfoQuery from './flowInfo.graphql';

export default class MiechvPreviewList extends React.Component {
    previewMiechvCaregiver = [
        'survey22',
        'survey24',
        'survey26',
        'survey28',
        'survey32',
        'survey34',
        'survey36',
    ];

    previewMiechvChild = [
        'survey23',
        'survey25',
        'survey27',
        'survey30',
        'survey31',
        'survey33',
        'survey35',
        'survey37',
    ];

    previewMiechvOutcomes = ['survey20'];

    previewMiechvAdditional = ['survey38', 'survey39'];

    state = {
        isLoading: true,
        miechvCaregiverFlows: [],
        miechvChildFlows: [],
        miechvOutcomeFlows: [],
        miechvAdditionalFlows: [],
    };

    /* /////////////////////////////////////////////////////////////////////////
    // REACT LIFECYCLE METHODS ////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    componentDidMount() {
        this.loadFlowInfo();
    }

    /* /////////////////////////////////////////////////////////////////////////
    // CLASS METHODS //////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    loadFlowInfo = async () => {
        let result = await apollo.query({
            query: flowInfoQuery,
            variables: {
                miechvCaregiver: this.previewMiechvCaregiver,
                miechvChild: this.previewMiechvChild,
                miechvOutcomes: this.previewMiechvOutcomes,
                miechvAdditional: this.previewMiechvAdditional,
            },
            fetchPolicy: 'network-only',
        });

        this.setState({
            isLoading: false,
            miechvCaregiverFlows: _get(result, 'data.flows.miechvCaregiver', []),
            miechvChildFlows: _get(result, 'data.flows.miechvChild', []),
            miechvOutcomeFlows: _get(result, 'data.flows.miechvOutcomes', []),
            miechvAdditionalFlows: _get(result, 'data.flows.miechvAdditional', []),
        });
    };

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    render() {
        const {
            isLoading,
            miechvCaregiverFlows,
            miechvChildFlows,
            miechvOutcomeFlows,
            miechvAdditionalFlows,
        } = this.state;

        if (isLoading) {
            return <Loader loading />;
        }

        let miechvCaregiverList = [];
        miechvCaregiverFlows.forEach(flowObj => {
            miechvCaregiverList.push(
                <li key={flowObj.flowId} className={style.flowItem}>
                    <Link to={`preview?frame=appDefault&id=${flowObj.flowId}`} target="_blank">
                        {flowObj.name}
                    </Link>
                </li>
            );
        }, this);

        let miechvChildList = [];
        miechvChildFlows.forEach(flowObj => {
            miechvChildList.push(
                <li key={flowObj.flowId} className={style.flowItem}>
                    <Link to={`preview?frame=appDefault&id=${flowObj.flowId}`} target="_blank">
                        {flowObj.name}
                    </Link>
                </li>
            );
        }, this);

        let miechvOutcomeList = [];
        miechvOutcomeFlows.forEach(flowObj => {
            miechvOutcomeList.push(
                <li key={flowObj.flowId} className={style.flowItem}>
                    <Link to={`preview?frame=appDefault&id=${flowObj.flowId}`} target="_blank">
                        {flowObj.name}
                    </Link>
                </li>
            );
        }, this);

        let miechvAdditionalList = [];
        miechvAdditionalFlows.forEach(flowObj => {
            miechvAdditionalList.push(
                <li key={flowObj.flowId} className={style.flowItem}>
                    <Link to={`preview?frame=appDefault&id=${flowObj.flowId}`} target="_blank">
                        {flowObj.name}
                    </Link>
                </li>
            );
        }, this);

        return (
            <div>
                <div className={style.row}>
                    <div className={style.column}>
                        <h4>MIECHV Caregiver Time Point Data</h4>
                        <ul>{miechvCaregiverList}</ul>
                    </div>

                    <div className={style.column}>
                        <h4>MIECHV Child Time Point Data</h4>
                        <ul>{miechvChildList}</ul>
                    </div>
                </div>

                <div className={style.row}>
                    <div className={style.column}>
                        <h4>MIECHV Outcome Survey</h4>
                        <ul>{miechvOutcomeList}</ul>
                    </div>

                    <div className={style.column}>
                        <h4>MIECHV Additional Surveys</h4>
                        <ul>{miechvAdditionalList}</ul>
                    </div>
                </div>
            </div>
        );
    }
}
