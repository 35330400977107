import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, CccisdInput, CccisdSelect, CccisdDatepicker } from 'cccisd-formik';
import classnames from 'classnames';
import { client as apollo } from 'cccisd-apollo';
import { format } from 'date-fns';
import Tooltip from 'cccisd-tooltip';

import RequiredFieldLabel from '../../../RequiredFieldLabel';
import groupsQuery from './groups.graphql';
import providerGroupsQuery from './providerGroups.graphql';
import familyIdQuery from './familyId.graphql';

import ClientTable from './ClientTable';
import ParticipantTable from './ParticipantTable';
import style from './style.css';

import IconPlus from 'cccisd-icons/plus-circle2';
import IconPencil from 'cccisd-icons/pencil';
import IconCancel from 'cccisd-icons/cancel-circle';

const fields = window.cccisd && window.cccisd.appDefs.pawn.fields;
const enumFields = fields.filter(field => field.data_type === 'enum');
const enumValues = {};
for (const field of enumFields) {
    enumValues[field.handle] = field.values.map(item => ({
        label: item.name,
        value: item.value,
    }));
}
const Fortress = window.cccisd && window.cccisd.fortress;
const userRole = Fortress.user.acting.role.handle;

const FamilyForm = ({ onSubmit, closeModal, initialValues, operation }) => {
    const [groups, setGroups] = useState([]);
    const [familyId, setFamilyId] = useState(null);

    const isProvider = userRole === 'sfp_provider';
    const pawnId = Fortress.user.acting.id;

    useEffect(() => {
        apollo
            .query({
                query: isProvider ? providerGroupsQuery : groupsQuery,
                variables: { pawnId },
                fetchPolicy: 'network-only',
            })
            .then(async res => {
                const arr = res.data.groups.sfp_groupList;
                await setGroups(arr);
            });
    }, [initialValues]);

    function ClientsAndParticipants() {
        return (
            <>
                <hr />
                <h4>
                    <strong>Caregivers</strong>
                </h4>
                <ClientTable
                    familyName={operation === 'edit' ? initialValues.label : 'New'}
                    familyGroupId={operation === 'edit' ? initialValues.familyGroupId : familyId}
                    isProvider={isProvider}
                    providerPawnId={isProvider ? pawnId : null}
                />
                <h4 style={{ marginTop: '1em' }}>
                    <strong>Youth</strong>
                </h4>
                <ParticipantTable
                    familyName={operation === 'edit' ? initialValues.label : 'New'}
                    familyGroupId={operation === 'edit' ? initialValues.familyGroupId : familyId}
                    isProvider={isProvider}
                    providerPawnId={isProvider ? pawnId : null}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '1em',
                    }}
                >
                    <button
                        type="button"
                        className={classnames(style.button, `btn btn-warning`)}
                        onClick={() => closeModal()}
                    >
                        <IconCancel spaceRight />
                        Close
                    </button>
                </div>
            </>
        );
    }

    const groupOptions = items => {
        return items.map(({ group: { groupId, label } }) => {
            return { key: groupId, value: groupId, label };
        });
    };

    async function onHandleSubmit(values) {
        await onSubmit(values);

        operation === 'edit' ? closeModal() : getNewFamilyId(values);
    }

    /* Unfortunately, we are querying here to get the new family's `groupId`. 
    In resources/assets/js/vendor/app-definitions/tableButtons/AddFamilyForm/index.js,
    we save the familyId in state to pass to <ClientTable /> and <ParticipantTable />.
    But for some reason the state isn't resetting. This is a sloppy fix,
    but a fix nonetheless. */
    const getNewFamilyId = async values => {
        const res = await apollo.query({
            query: familyIdQuery,
            variables: {
                familyLabel: values.label,
                cycleGroupId: +values.group,
            },
            fetchPolicy: 'network-only',
        });

        const newFamilyId = res.data.groups.sfp_family.group.groupId;

        setFamilyId(newFamilyId);
    };

    const validate = values => {
        const errors = {};
        if (!values.label) {
            errors.label = 'Family Name is required.';
        }
        if (!values.enrollmentDate) {
            errors.enrollmentDate = 'Enrollment Date is required.';
        }
        if (values.zipCode && values.zipCode) {
            if (!/^\d{5}(-\d{4})?$/.test(values.zipCode)) {
                errors.zipCode = 'Must be standard 5 digit Zip Code XXXXX, or XXXXX-XXXX ZIP+4.';
            }
        }
        if (!values.county) {
            errors.county = 'County of Residence is required.';
        }
        if (!values.referralSource) {
            errors.referralSource = 'Referral Source is required.';
        }
        if (!values.group) {
            errors.group = 'Cycle Number is required.';
        }
        if (!values.familyStatus) {
            errors.familyStatus = 'Family Status is required.';
        }
        if (values.familyStatus !== 'Active' && !values.statusChangeDate) {
            errors.statusChangeDate = 'Status Change Date is required.';
        }
        if (values.statusChangeDate > format(new Date(), 'YYYY-MM-DD')) {
            errors.statusChangeDate = 'Status Change Date cannot be in the future.';
        }

        return errors;
    };

    return (
        <Formik onSubmit={e => onHandleSubmit(e)} validate={e => validate(e)} initialValues={initialValues}>
            {({ handleSubmit, values, dirty }) => (
                <Form>
                    <div className="row">
                        <div className="col-xs-8">
                            <Field
                                name="label"
                                component={CccisdInput}
                                label={<RequiredFieldLabel isRequired>Family Name:</RequiredFieldLabel>}
                            />
                        </div>
                        <div className="col-xs-4">
                            <Field
                                name="enrollmentDate"
                                component={CccisdDatepicker}
                                label={<RequiredFieldLabel isRequired>Referral Date:</RequiredFieldLabel>}
                                placeholder="MM/DD/YYYY"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">
                            <Field name="zipCode" component={CccisdInput} label="Zip Code of Residence:" />
                        </div>
                        <div className="col-xs-4">
                            <Field
                                name="county"
                                component={CccisdSelect}
                                label={<RequiredFieldLabel isRequired>County of Residence:</RequiredFieldLabel>}
                                options={[{ value: '', label: '-- County --' }, ...enumValues.county]}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">
                            <Field
                                name="referralSource"
                                component={CccisdSelect}
                                label={<RequiredFieldLabel isRequired>Referral Source:</RequiredFieldLabel>}
                                options={[
                                    {
                                        value: '',
                                        label: '-- Referral Source --',
                                    },
                                    ...enumValues.referralSource,
                                ]}
                            />
                        </div>
                        {values.referralSource === 'School Based' ||
                        values.referralSource === 'Other Community Organization' ||
                        values.referralSource === 'Other' ? (
                            <div className="col-xs-4">
                                <Field name="referralName" component={CccisdInput} label="Referral Name:" />
                            </div>
                        ) : null}

                        {values.referralSource === 'DSS' ||
                        values.referralSource === 'School Based' ||
                        values.referralSource === 'Other Community Organization' ? (
                            <div className="col-xs-4">
                                <Field
                                    name="referralCounty"
                                    component={CccisdSelect}
                                    label="Referral County:"
                                    options={[
                                        { value: '', label: '-- Referral County --' },
                                        ...enumValues.referralCounty,
                                    ]}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="row">
                        <div className="col-xs-4">
                            <Field
                                name="group"
                                component={CccisdSelect}
                                label={<RequiredFieldLabel isRequired>Cycle Number:</RequiredFieldLabel>}
                                options={[
                                    {
                                        value: '',
                                        label: '-- Cycle Number --',
                                    },
                                    ...groupOptions(groups),
                                ]}
                            />
                        </div>
                        {!isProvider ? (
                            <>
                                <div className="col-xs-4">
                                    <Field
                                        name="familyStatus"
                                        component={CccisdSelect}
                                        label={<RequiredFieldLabel isRequired>Status:</RequiredFieldLabel>}
                                        options={[
                                            {
                                                value: '',
                                                label: '-- Status --',
                                            },
                                            ...enumValues.familyStatus,
                                        ]}
                                    />
                                </div>
                                {values.familyStatus !== 'Active' ? (
                                    <div className="col-xs-4">
                                        <Field
                                            name="statusChangeDate"
                                            component={CccisdDatepicker}
                                            label={
                                                <RequiredFieldLabel isRequired>Status Change Date:</RequiredFieldLabel>
                                            }
                                        />
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '1em',
                        }}
                    >
                        {operation === 'create' ? (
                            <button
                                type="button"
                                className={classnames(style.button, `btn btn-primary`)}
                                onClick={() => handleSubmit()}
                            >
                                <IconPlus spaceRight />
                                Add Family
                            </button>
                        ) : (
                            <Tooltip title="Changes to Family rostering fields must be saved using this button.">
                                <button
                                    type="button"
                                    className={classnames(style.button, `btn ${dirty ? 'btn-warning' : 'btn-success'}`)}
                                    onClick={() => handleSubmit()}
                                >
                                    <IconPencil spaceRight />
                                    Edit Family
                                </button>
                            </Tooltip>
                        )}
                    </div>
                    {operation === 'edit' && <ClientsAndParticipants />}
                    {operation === 'create' && familyId && <ClientsAndParticipants />}
                </Form>
            )}
        </Formik>
    );
};

FamilyForm.propTypes = {
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func,
    operation: PropTypes.string,
    initialValues: PropTypes.object,
};

export default FamilyForm;
